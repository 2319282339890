import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionISFPLX125G20KMSMFBIDI.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import ISFPLX125G20KMSMFBIDIAB from "../../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KMSMFBIDI/ISFPLX125G20KMSMFBIDIAB.jpg";

export function TitleSectionTransceiverISFPLXGMSMFBIDI() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Transceptor nivel Industrial 1000Base-LX MiniGBIC Hasta 20KM, SMF, BIDI, A:1310/1550, B:1550/1310, DDM" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="ISFP-LX-1.25G-20KM-SMF-BIDI  A&B" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={ISFPLX125G20KMSMFBIDIAB}
            alt="Transceptor nivel Industrial 1000Base-LX MiniGBIC SMF, BIDI"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            Los AirLive ISFP-LX-1.25G-20KM-SMF-BIDI A&B son transceptores de
            fibra BIDI SFP industrial de modo único de bajo consumo, alto
            rendimiento y rentables para aplicaciones de comunicación de datos
            óptica serie de hasta 1,25 Gbps y 20 km en entornos industriales. El
            módulo de baja fluctuación y alta sensibilidad está diseñado para
            fibra dual monomodo y funciona a una longitud de onda nominal de
            1310nm / 1550nm, la temperatura de funcionamiento más alta de -40 °
            C ~ + 85 ° C hace que el módulo sea ideal para áreas industriales y
            de mayor temperatura, el módulo es conectable en caliente.
          </p>
          &nbsp;
          <p>
            El transceptor incorpora funciones de control TX Disabled, TX-Fault
            y monitor RX_LOS. Es un transceptor de conector LC diseñado para su
            uso en aplicaciones Gigabit Ethernet y para proporcionar un enlace
            compatible con IEEE802.3z y un enlace compatible con la seguridad
            láser de clase I. Las aplicaciones adecuadas son tales como redes de
            área de almacenamiento de alta velocidad, conexión cruzada de
            clústeres de computadoras, tuberías de datos de alta velocidad
            personalizadas y más.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import Interfaces from "../../../../../../../assets/imagesproduct/SwitchL3/Interfaces.jpg";
import ComparisonSwitchl2l3 from "../../../../../../../assets/imagesproduct/SwitchL3/ComparisonSwitchl2l3.jpg";

export function InterfaceManagement({ content }: { content: string | undefined}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Gestión de la interfaz de enrutamiento IP VLAN L3" />
          <p>
            El {content} proporciona 3 capas de interfaz VLAN, que se utiliza
            para comunicarse con dispositivos de capa de red. La interfaz VLAN
            es una interfaz de capa de red, que se puede configurar con la
            dirección IP. Antes de crear la interfaz VLAN, primero se debe crear
            la VLAN correspondiente. Con la ayuda de la interfaz VLAN, los
            switches pueden comunicarse con otros dispositivos de capa de red.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img 
            className="w-100" 
            src={Interfaces} 
            alt="Interfaces" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Comparación de un Switch capa 2 vs el switch capa 3" />
          <p>
            La capa 2 y la capa 3 difieren principalmente en la función de
            enrutamiento. Un conmutador de capa 2 funciona solo con direcciones
            MAC y no le importa la dirección IP ni ningún elemento de capas
            superiores. El switch de capa 3, o switch multicapa, puede hacer
            todo el trabajo de un switch de capa 2 y también enrutamiento
            estático y enrutamiento dinámico adicionales. Eso significa que un
            switch de capa 3 tiene tanto la tabla de direcciones MAC como la
            tabla de enrutamiento IP y maneja la comunicación intra-VLAN y el
            enrutamiento de paquetes entre diferentes VLAN. También hay un
            interruptor de capa 2+ (capa 3 Lite) que agrega solo enrutamiento
            estático. Además de los paquetes de enrutamiento, los switches de
            capa 3 también incluyen funciones que requieren comprender la
            información de la dirección IP de los datos que ingresan al switch,
            como etiquetar el tráfico VLAN en función de la dirección IP en
            lugar de configurar manualmente un puerto. Los interruptores de capa
            3 aumentan en potencia y seguridad según se demanda.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={ComparisonSwitchl2l3}
            alt="Comparison of a Switch l2 and l3"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH41160BT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH41160BT } from "./SpecificationsLeftPOEGSH41160BT";
import { SpecificationsRightPOEGSH41160BT } from "./SpecificationsRightPOEGSH41160BT";

export function FeatureProductPOEGSH41160BT() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Serie AirLive POE-GSH411-BT" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH41160BT content={"60w"} />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH41160BT />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

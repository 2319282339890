import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftL310XGF12() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware Interfaz del dispositivo:",
      detail: "12 x 10G ranuras SFP+",
    },
    {
      detail: "1 x puerto de consola RJ45 ",
    },
  ];
  const Standard = [
    {
      title: "Estándar:",
      detail: "IEEE 802.3:Protocolo MAC Ethernet ",
    },
    {
      detail: "IEEE 802.3i:10BASE-T Ethernet ",
    },
    {
      detail: "IEEE 802.3u:100BASE-TX Fast Ethernet ",
    },
    {
      detail: "IEEE 802.3ab:1000BASE-T Gigabit Ethernet",
    },
    {
      detail: "IEEE 802.3z:1000BASE-X Gigabit Ethernet (fibra óptica) ",
    },
    {
      detail: "IEEE 802.3ae: 10G Ethernet (fibra óptica)",
    },
    {
      detail: "IEEE 802.3az:Ethernet de eficiencia energética",
    },
    {
      detail:
        "IEEE 802.3ad:Método estándar para realizar la agregación de vínculos ",
    },
    {
      detail: "IEEE 802.3x:Control de flujo",
    },
    {
      detail: "IEEE 802.1ab: LLDP/LLDP-MED (Link Layer Discovery Protocol)",
    },
    {
      detail:
        "IEEE 802.1p:Priorización de tráfico del protocolo QoS/CoS de capa LAN (función de filtrado de multidifusión)",
    },
    {
      detail: "IEEE 802.1q:Operación del puente VLAN",
    },
    {
      detail:
        "IEEE 802.1x:Protocolo de autenticación y control de acceso cliente/servidor ",
    },
    {
      detail: "IEEE 802.1d:STP",
    },
    {
      detail: "IEEE 802.1s:MSTP",
    },
    {
      detail: "IEEE 802.1w:RSTP",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail:
        "PWR (indicador de encendido), SYS (luces del sistema), enlace (luz de enlace), Act(luz de datos).",
    },
  ];
  const SurgeProtection = [
    {
      title: "Protección contra sobretensiones de iluminación:",
      detail: "Sobretensión 6KV, ESD 8KV.",
    },
  ];
  const Mechanic = [
    {
      title: "Mecánico:",
      detail: "Metal sólido 19 1U montable en rack, IP30",
    },
  ];
  const PowerInput = [
    {
      title: "Alimentación Entrada de energía:",
      detail: "Entrada de energía: AC 100 ~ 240V 50 ~ 60Hz",
    },
    {
      detail:
        "Consumo de energía: 38W (sin fibra o RJ-45 conectado), 96W (uso completo).",
    },
  ];
  const SwitchArchitecture = [
    {
      title: "Arquitectura del switch | Rendimiento de conmutación:",
      detail: "Ancho de banda: 240Gbps",
    },
    {
      detail: "Velocidad de reenvío de paquetes: 178.56Mpps",
    },
    {
      detail: "DDR SDRAM: 128MB",
    },
    {
      detail: "Memoria flash: 16MB",
    },
    {
      detail: "Caché del paquete: 16Mbit",
    },
    {
      detail: "Dirección MAC: 32K",
    },
    {
      detail: "Trama Jumbo: 12Kbytes",
    },
    {
      detail: "VLAN:4096",
    },
    {
      detail: "MTBF: 100000 hora ",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

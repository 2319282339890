import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightMC10GSFP() {
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de Operación: -0°C to +50°C",
    },
    {
      detail: "Temperature de almacenamiento: -0 °C a 70°C",
    },
    {
      detail: "Humedad: 5% ~ 95%, no condensada",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Dimensiones:",
      detail: "9.9 (Largo) x 7 (Ancho) x 5.2 (Alto) cm",
    },
    {
      detail: "Tamaño del Paquete: 21 (L) x 11.8 (Ancho) x 5.2 (Alto)",
    },
    {
      detail: "Peso del Paquete: Neto: 0.290 KGS; Bruto: 0.350 KGS",
    },
    {
      detail: "Contenido del paquete: Un modulo 1 x MC-10GSFP+",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 50 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensiones: 55 x 42 x 36 cm(L*W*H)",
    },
    {
      detail: "Peso Neto: 17Kgs",
    },
    {
      detail: "Peso Bruto: 17.5Kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo: AirLive MC-10GSFP+",
    },
    {
      detail: "Nombre: 10G SFP+ Multi Giga Media Converter, Auto-fallback, Single or Multi Mode FX",
    },
  ];
  return (
    <Container>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { Products } from "../Products";
import { SidebarProducts } from "../../SidebarProducts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./Layer2ManagedSwitches.module.scss";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { ProductCard } from "../../../../../ProductCard";
import POEGSH2404M400 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH2404M400/POEGSH2404M400.jpg";
import POEGSH1602M200 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH1602M200/POEGSH1602M200.jpg";
import POEGSH4804M600 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH4804M600/POEGSH4804M600.jpg";
import POEGSH402BT from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH402BT/POEGSH402BT.jpg";
import POEGSH41160BT from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH41160BT/POEGSH41160BT.jpg";
import POEGSH411120BT from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH411120BT/POEGSH411120BT.jpg";
import POEGSH42060 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH42060/POEGSH42060.jpg";
import POEGSH800120BT from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH800120BT/POEGSH800120BT.jpg";
import POEGSH802120 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH802120/POEGSH802120.jpg";
import POEGSH820120 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH820120/POEGSH820120.jpg";
import POEGSH822120 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH822120/POEGSH822120.jpg";
import POEGSH1622200 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH1622200/POEGSH1622200.jpg";
import POEGSH2422300 from "../../../../../../assets/imagesproduct/SwitchL2/POEGSH2422300/POEGSH2422300.jpg";
import Live2TX501 from "../../../../../../assets/imagesproduct/SwitchL2/Live2TX501/Live2TX501.jpg";
import Live2TX801 from "../../../../../../assets/imagesproduct/SwitchL2/Live2TX801/Live2TX801.jpg";
import Live10TX800 from "../../../../../../assets/imagesproduct/SwitchL2/Live10TX800/Live10TX800.jpg";
import SNMPGSH802M from "../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH802M/SNMPGSH802M.jpg";
import SNMPGSH2404M from "../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH2404M/SNMPGSH2404M.jpg";
import Live8GTII from "../../../../../../assets/imagesproduct/SwitchL2/Live8GTII/Live8GTII.jpg";
import Live5GTII from "../../../../../../assets/imagesproduct/SwitchL2/Live5GTII/Live5GTII.jpg";
import Live8GT from "../../../../../../assets/imagesproduct/SwitchL2/Live8GT/Live8GT.jpg";
import Live16GTMini from "../../../../../../assets/imagesproduct/SwitchL2/Live16GTMini/Live16GTMini.jpg";
import Live24GT from "../../../../../../assets/imagesproduct/SwitchL2/Live24GT/Live24GT.jpg";
import Live26G from "../../../../../../assets/imagesproduct/SwitchL2/Live26G/Live26G.jpg";

export function Layer2ManagedSwitches() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const switchL2 = [
    {
      img: { src: POEGSH1602M200, alt: "switch POE-GSH1602M-200" },
      title: "switch POE-GSH1602M-200",
      description:
        "Switch Administrable Gigabit PoE+ 200W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH1602M200",
      },
    },
    {
      img: { src: POEGSH2404M400, alt: "switch POE-GSH2404M-400" },
      title: "switch POE-GSH2404M-400",
      description:
        "Switch Gigabit Gestionado PoE+ de 400 W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH2404M400",
      },
    },
    {
      img: { src: POEGSH4804M600, alt: "switch POE-GSH4804M-600" },
      title: "switch POE-GSH4804M-600",
      description:
        "Switch Gigabit Gestionado PoE+ de 400 W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH4804M600",
      },
    },
  ];
  const switchL2NonManageablewithPoE = [
    {
      img: { src: POEGSH402BT, alt: "switch POE-GSH402BT" },
      title: "switch POE-GSH402BT",
      description:
        "Switch Gigabit POE de 6 puertos, 802.3at/bt, 60W/120W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH402BT",
      },
    },
    {
      img: { src: POEGSH41160BT, alt: "switch POE-GSH411-60BT" },
      title: "switch POE-GSH411-60BT",
      description:
        "Switch Gigabit POE de 6 puertos, 802.3at/bt, 60W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH41160BT",
      },
    },
    {
      img: { src: POEGSH411120BT, alt: "switch POE-GSH411-120 BT" },
      title: "switch POE-GSH411-120 BT",
      description:
        "Switch Gigabit POE de 6 puertos, 802.3at/bt 120W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH411120BT",
      },
    },
    {
      img: { src: POEGSH42060, alt: "switch POE-GSH420-60" },
      title: "switch POE-GSH420-60",
      description:
        "Switch Gigabit POE de 6 puertos, 802.3at/bt 120W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH42060",
      },
    },
    {
      img: { src: POEGSH800120BT, alt: "switch POE-GSH800-120-BT" },
      title: "switch POE-GSH800-120-BT",
      description:
        "Switch 8 puertos Gigabit POE 8 Puertos Gigabit incl 8x30W PoE ",
      button: {
        name: "VER MÁS",
        path: "../POEGSH800120BT",
      },
    },
    {
      img: { src: POEGSH802120, alt: "switch POE-GSH802-120" },
      title: "switch POE-GSH802-120",
      description:
        "Switch 8 puertos Gigabit POE 8 Puertos Gigabit incl 8x30W PoE ",
      button: {
        name: "VER MÁS",
        path: "../POEGSH802120",
      },
    },
    {
      img: { src: POEGSH820120, alt: "switch POE-GSH820-120" },
      title: "switch POE-GSH820-120",
      description:
        "Switch Gigabit PoE de 10 puertos, 802.3af/at, 120W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH820120",
      },
    },
    {
      img: { src: POEGSH822120, alt: "switch POE-GSH822-120" },
      title: "switch POE-GSH822-120",
      description:
        "Switch Gigabit POE de 12 puertos, 802.3af/at, 120W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH822120",
      },
    },
    {
      img: { src: POEGSH1622200, alt: "switch POE-GSH1622-200" },
      title: "switch POE-GSH1622-200",
      description:
        "Switch Gigabit POE de 20 puertos, 802.3af/at, 200W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH1622200",
      },
    },
    {
      img: { src: POEGSH2422300, alt: "switch POE-GSH2422-300" },
      title: "switch POE-GSH2422-300",
      description:
        "Switch Gigabit POE de 28 puertos, 802.3af/at, 300W",
      button: {
        name: "VER MÁS",
        path: "../POEGSH2422300",
      },
    },
  ];
  const MultigigabitLayer2Switches  = [
    {
      img: { src: Live2TX501, alt: "switch Live-2TX501" },
      title: "switch Live-2TX501",
      description:
        "Switch Multi Gigabit 2.5Gbps Base-T, plug & play",
      button: {
        name: "VER MÁS",
        path: "../Live2TX501",
      },
    },
    {
      img: { src: Live2TX801, alt: "switch Live-2TX801" },
      title: "switch Live-2TX801",
      description:
        "Switch Multi Gigabit 2.5Gbps Base-T, plug and play 9 Puertos",
      button: {
        name: "VER MÁS",
        path: "../Live2TX801",
      },
    },
    {
      img: { src: Live10TX800, alt: "switch Live-10TX800" },
      title: "switch Live-10TX800",
      description:
        "Switch Gigabit Gestionado PoE+ de 400 W",
      button: {
        name: "VER MÁS",
        path: "../Live10TX800",
      },
    },
  ];
  const ManageableSNMPNoPoE  = [
    {
      img: { src: SNMPGSH802M, alt: "switch SNMP-GSH802M" },
      title: "switch SNMP-GSH802M",
      description:
        "Switch Multi Gigabit 2.5Gbps Base-T, plug & play",
      button: {
        name: "VER MÁS",
        path: "../SNMPGSH802M",
      },
    },
    {
      img: { src: SNMPGSH2404M, alt: "switch SNMP-GSH2404M" },
      title: "switch SNMP-GSH2404M",
      description:
        "Switch Multi Gigabit 2.5Gbps Base-T, plug and play 9 Puertos",
      button: {
        name: "VER MÁS",
        path: "../SNMPGSH2404M",
      },
    },
  ];
  const SwitchSOHO  = [
    {
      img: { src: Live8GTII, alt: "switch Live-8GT-II" },
      title: "switch Live-8GT-II",
      description:
        "Switch SOHO de 8 puertos Gigabit, Plug and Play",
      button: {
        name: "VER MÁS",
        path: "../Live8GTII",
      },
    },
    {
      img: { src: Live5GTII, alt: "switch Live-5GT-II" },
      title: "switch Live-5GT-II",
      description:
        "Switch Gigabit de 5 puertos SOHO, Plug and Play",
      button: {
        name: "VER MÁS",
        path: "../Live5GTII",
      },
    },
    {
      img: { src: Live8GT, alt: "switch Live-8GT" },
      title: "switch Live-8GT",
      description:
        "Switch SOHO  Gigabit de 8 puertos Plug and Play",
      button: {
        name: "VER MÁS",
        path: "../Live8GT",
      },
    },
    {
      img: { src: Live16GTMini, alt: "switch Live-16GT Mini" },
      title: "switch Live-16GT Mini",
      description:
        "Switch SOHO de 16 puertos Gigabit Plug and Play",
      button: {
        name: "VER MÁS",
        path: "../Live16GTMini",
      },
    },
    {
      img: { src: Live24GT, alt: "switch Live-24GT" },
      title: "switch Live-24GT",
      description:
        "Switch SOHO de 24 puertos Gigabit Plug and Play",
      button: {
        name: "VER MÁS",
        path: "../Live24GT",
      },
    },
    {
      img: { src: Live26G, alt: "switch Live-26G" },
      title: "switch Live-26G",
      description:
        "Switch SOHO de 26 puertos Gigabit Plug and Play",
      button: {
        name: "VER MÁS",
        path: "../Live26G",
      },
    },
  ];
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <Products />
        <Row>
          <Col className="mb-5">
            <HomeSubtitle title="Switches Multigibabit Administrables Capa 2:" />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches Capa 2 administrables con PoE" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {switchL2.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches Capa 2 No administrables con PoE" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {switchL2NonManageablewithPoE.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches Capa 2 Multigigabit 10G & 2.5G" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {MultigigabitLayer2Switches.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switches Capa 2 Administrables SNMP- No PoE" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {ManageableSNMPNoPoE.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Switch SOHO" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {SwitchSOHO.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

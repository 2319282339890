import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftLive10TX800() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "8 x 100/1000M/2.5G/5G/10G RJ45 Para todos los 8 puertos" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3 10Base-TX" 
        },
        {
            detail: "IEEE802.3u 100Base-TX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3bz 2.5/5G Base-T" 
        },
        {
            detail: "IEEE 802.3ae 10G Ethernet" 
        },
        {
            detail: "IEEE 802.3az Energy Efficient Ethernet" 
        },
        {
            detail: "IEEE802.3x Flow Control and Back Pressure" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC 47 CFR Part 15 Subpart B (Class A)" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-5 (Surge)" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (encendido), LED de Puerto verde (5/10G Link), LED de puerto amarillo (100/1000M Link)" 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Carcasa de metal sólido" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones y ESD:",
            detail: "Modo común 6KV, ESD: Aire 8KV, Contacto 6KV" 
        },
    ];
    const InputPower = [
        {
            title:"Alimentación:",
            detail: "Voltaje de entrada: AC100-240 V, 50/60Hz" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "almacenar y reenviar" 
        },
        {
            detail: "Ancho de banda: 160Gbps" 
        },
        {
            detail: "Velocidad de reenvío de paquetes; 119.04Mpps" 
        },
        {
            detail: "Memoria RAM: 128Mb" 
        },
        {
            detail: "Memoria Flash: 16Mb" 
        },
        {
            detail: "Caché de memoria: 12Mbit" 
        },
        {
            detail: "VLAN: 4K" 
        },
        {
            detail: "Dirección MAC: 16K" 
        },
        {
            detail: "Jumbo Frame: 12Kbytes" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InputPower.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH42060.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH42060 } from "./TitleSectionL2POEGSH42060";
import { CharacteristicsPOEGSH42060 } from "./CharacteristicsPOEGSH42060";
import { VLANExtendModePOEGSH42060 } from "./VLANExtendModePOEGSH42060";
import { FeatureProductPOEGSH42060 } from "./FeatureProductPOEGSH42060";

export function POEGSH42060() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH42060 />
           <CharacteristicsPOEGSH42060 />
           <VLANExtendModePOEGSH42060 />
           <FeatureProductPOEGSH42060 />
        </Container>
    </div>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsWLAN128GM.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { TechnicalspecificationsLeftWLAN128GM } from "./TechnicalspecificationsLeftWLAN128GM";
import { TechnicalspecificationsRightWLAN128GM } from "./TechnicalspecificationsRightWLAN128GM";

export function TechnicalspecificationsWLAN128GM() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="WLAN-128GM  Enterprise Gateway & Wireless Controller" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <TechnicalspecificationsLeftWLAN128GM />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <TechnicalspecificationsRightWLAN128GM />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

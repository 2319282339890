import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./DataDisclosureSection.module.scss";

export function DataDisclosureSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Divulgación de datos" />
        </Col>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>Datos de seguimiento y cookies</div>
          <p>
            <strong>
              AirLive puede divulgar sus Datos personales de buena fe cuando
              considere que esta acción es necesaria para lo siguiente:
            </strong>
          </p>
          <ul>
            <li>
              <p>Para cumplir con una obligación legal</p>
            </li>
            <li>
              <p>
                Para proteger y defender los derechos o la propiedad de AirLive
              </p>
            </li>
            <li>
              <p>
                Para prevenir o investigar posibles irregularidades en relación
                con el Servicio
              </p>
            </li>
            <li>
              <p>
                Para proteger la seguridad personal de los usuarios del Servicio
                o del público
              </p>
            </li>
            <li>
              <p>Para protegerse contra la responsabilidad legal</p>
            </li>
          </ul>
        </Col>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>Seguridad de datos</div>
          <p>
            La seguridad de sus datos es importante para nosotros, pero recuerde
            que ningún método de transmisión a través de Internet o método de
            almacenamiento electrónico es 100% seguro. Si bien nos esforzamos
            por utilizar medios comercialmente aceptables para proteger sus
            Datos personales, no podemos garantizar su seguridad absoluta.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>Proveedores de servicio</div>
          <p>
            Podemos emplear empresas e individuos de terceros para facilitar
            nuestro Servicio ("Proveedores de servicios"), para proporcionar el
            Servicio en nuestro nombre, para realizar servicios relacionados con
            el Servicio o para ayudarnos a analizar cómo se utiliza nuestro
            Servicio.
          </p>
          <p>
            Estos terceros tienen acceso a sus Datos personales solo para
            realizar estas tareas en nuestro nombre y están obligados a no
            divulgarlos ni utilizarlos para ningún otro propósito.
          </p>
        </Col>
        <Col xs={12}>
          <div className={styles.subTitle}>Analítica</div>
          <p>
            Podemos utilizar Proveedores de Servicios de terceros para
            monitorear y analizar el uso de nuestro Servicio.
          </p>
          <ul>
            <li>
              <p>
                <strong>Google Analytics</strong>
              </p>
            </li>
          </ul>
          <p>
            Google Analytics es un servicio de análisis web ofrecido por Google
            que rastrea e informa el tráfico del sitio web. Google utiliza los
            datos recopilados para rastrear y monitorear el uso de nuestro
            Servicio. Estos datos se comparten con otros servicios de Google.
            Google puede utilizar los datos recopilados para contextualizar y
            personalizar los anuncios de su propia red publicitaria.
          </p>
          <p>
            Puede optar por no permitir que su actividad en el Servicio esté
            disponible para Google Analytics instalando el complemento del
            navegador de exclusión voluntaria de Google Analytics. El
            complemento evita que el JavaScript de Google Analytics (ga.js,
            analytics.js y dc.js) comparta información con Google Analytics
            sobre la actividad de las visitas.
          </p>
        </Col>
        <Col xs={12}>
          <p>
            Para obtener más información sobre las prácticas de privacidad de
            Google, visite la página web de Términos y privacidad de Google:
            <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

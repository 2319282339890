import { Col, Container, Row } from "react-bootstrap";
import styles from "./Specifications2404400.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftXGS4804850 } from "../../../SwitchL3POEXGS4804850BT/FeatureProduct/Specifications/SpecificationsLeftXGS4804850";
import { SpecificationsRightXGS4804850 } from "../../../SwitchL3POEXGS4804850BT/FeatureProduct/Specifications/SpecificationsRightXGS4804850";

export function Specifications2404400() {
  return (
    <Container>
      <Row>
        <Col xs={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={6} className="mb-3">
          <HomeSubtitle title="AirLive Switch Layer 3" />
        </Col>
      </Row>
      <Row>
        <Col xs={5} className={styles.LeftList}>
          <SpecificationsLeftXGS4804850 />
        </Col>
        <Col xs={5} className={styles.RightList}>
          <SpecificationsRightXGS4804850 content={"L3-XGS2404"} nameProduct={"L3 Managed Gigabit switch, 24-Port including 4x 10G SFP+ Fiber ports."} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

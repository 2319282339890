import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import { ProductCard } from "../../../../ProductCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AirCloud from "../../../../../assets/images/AirCloud.jpg";
import PlataformGPON from "../../../../../assets/imagesproduct/AirLiveGPON/PlataformGPON.jpg";
import switchL3POEXGS4804850BT from "../../../../../assets/imagesproduct/SwitchL3/switchL3POEXGS4804850BT/switchL3POEXGS4804850BT.jpg";
import AWOD30AXi from "../../../../../assets/imagesproduct/AircloudProducts/AWOD30AXi/AWOD30AXi.jpg";

export function ProductSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const products = [
    {
      img: { src: AirCloud, alt: "AIRCLOUD" },
      title: "AIRCLOUD",
      description: "Plataforma de Control de dispositivos inalámbricos",
      button: {
        name: "VER MÁS",
        path: "../../../Products/AirCloudPlatform",
      },
    },
    {
      img: { src: PlataformGPON, alt: "PlataformAirLiveGPON" },
      title: "Plataforma AirLive GPON",
      description: "Conoce la Plataforma GPON AirLive",
      button: {
        name: "VER MÁS",
        path: "../../../Products/PlataformAirLiveGPON",
      },
    },
    {
      img: { src: AWOD30AXi, alt: "AWOD-30AXi" },
      title: "AWOD-30AXi",
      description: "Acecss Point Wi-Fi 6 AX3000 Dual Omni",
      button: {
        name: "VER MÁS",
        path: "../../../Products/AWOD30AXi",
      },
    },
    {
      img: { src: switchL3POEXGS4804850BT, alt: "switch L3POE-XGS4804-850 BT" },
      title: "switch L3POE-XGS4804-850 BT",
      description:
        "Switch L3 850W Administrable de 48 puertos Gigabit PoE+",
      button: {
        name: "VER MÁS",
        path: "../../../Products/SwitchL3POEXGS4804850BT",
      },
    },
  ];

  return (
    <Container >
      <Container>
          <Row>
            <Col xs={12} md className="mb-3">
              <HomeSubtitle title="Productos Destacados" />
            </Col>
          </Row>
      </Container>
          <Row className="justify-content-center">
            <Col xs={12} md className="mb-5">
              <Carousel
                responsive={responsive}
              >
                {products.map((products) => (
                  <ProductCard
                    img={products.img}
                    title={products.title}
                    description={products.description}
                    buttonCard={products.button}
                  />
                ))}
              </Carousel>
            </Col>
          </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import RMONStatisticsSupportPic from "../../../../../../../../assets/imagesproduct/SwitchL2/RMONStatisticsSupportPic.jpg";
import SPFPort from "../../../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH802M/SPFPort.jpg";

export function RMONSupport() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte de estadísticas RMON" />
          <p>
            El soporte de estadísticas RMON (Remote Monitoring) es una
            característica en equipos de red que permite el monitoreo remoto y
            la administración del rendimiento de la red. Proporciona información
            sobre el tráfico de red, como el recuento de paquetes y bytes, y
            permite a los administradores de red supervisar el rendimiento de la
            red y solucionar problemas de red desde una ubicación remota. Las
            estadísticas de RMON ayudan a proporcionar visibilidad detallada de
            la red y son una herramienta importante para la gestión y
            optimización de la red.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img className="w-100" src={RMONStatisticsSupportPic} alt="image" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Fibra para conexión a piso cruzado o larga distancia" />
          <p>
            La tecnología de fibra óptica se utiliza para la comunicación a
            larga distancia debido a su alta capacidad de ancho de banda e
            inmunidad a las interferencias electromagnéticas. Los cables de
            fibra óptica están hechos de fibras delgadas de vidrio o plástico
            que transportan señales de luz a largas distancias, proporcionando
            una transmisión de datos rápida y confiable. Esto hace que la fibra
            óptica sea ideal para aplicaciones donde se necesitan transmitir
            grandes cantidades de datos a largas distancias, como en
            telecomunicaciones de larga distancia, redes de centros de datos
            conexiones entre pisos.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img className="w-100" src={SPFPort} alt="image" />
        </Col>
      </Row>
    </Container>
  );
}

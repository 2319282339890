import { Button, Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./AboutUsSection.module.scss";

export function AboutUsSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-5">
          <HomeSubtitle title="Nosotros" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>GLOBAL ACCESS WLL C.A.</div>
          <p className="mb-3">
            GLOBAL ACCESS WLL es una empresa conformada por un grupo de
            Inversionistas y profesionales en el área de Telecomunicaciones e
            Informática, con un vasto espectro de conocimientos y experiencia en
            disciplinas técnicas así como en la actividad de mercadeo y atención
            al cliente.
          </p>
          <p className="mb-4">
            Nuestra labor se fundamenta en una cartera de servicios y soluciones
            basadas en productos de vanguardia tecnológica fabricados por
            corporaciones de renombre internacional.
          </p>
          <Button href="/AboutUs" className={styles.button} size="lg">
            Ver más
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

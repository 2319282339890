import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH2422300.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH2422300 } from "./TitleSectionL2POEGSH2422300";
import { CharacteristicsPOEGSH2422300 } from "./CharacteristicsPOEGSH2422300";
import { EfficientPoETechnology } from "../EfficientPoETechnology";
import { FeatureProductPOEGSH2422300 } from "./FeatureProductPOEGSH2422300";
import PortsPoePOEGSH2422300 from "../../../../../../../assets/imagesproduct/SwitchL2/POEGSH2422300/PortsPoePOEGSH2422300.jpg";
import FiberOpticPOE from "../../../../../../../assets/imagesproduct/SwitchL2/FiberOpticPOE.jpg";

export function POEGSH2422300() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH2422300 />
           <CharacteristicsPOEGSH2422300 />
           <EfficientPoETechnology content={"POE-GSH2422-300"} image={PortsPoePOEGSH2422300} image2={FiberOpticPOE} numberports={"1 - 16"} numberports2={"9 y 16"} TextPoeWatchdog={undefined} />
           <FeatureProductPOEGSH2422300 />
        </Container>
    </div>
  );
}

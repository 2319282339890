import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleAWOD30AXiSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopAWOD30AXi from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD30AXi/TopAWOD30AXi.jpg";
import AWOD30AXiDetails from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD30AXi/AWOD30AXiDetails.jpg";

export function TitleAWOD30AXiSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Acecss Point Wi-Fi 6 AX3000 Dual Omni para exteriores" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AWOD-30AXi" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={TopAWOD30AXi}
            alt="Acecss Point Wi-Fi 6 AX3000"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={6} className="mb-5">
          <SubTitleProduct content="High Performance Dual Band Outdoor AP/Repeater with Omni Antenna." />
          <p>
            El AWOD-30AXi es un AP/repetidor para exteriores de 3000 Mbps de
            alto rendimiento construido con los más altos estándares de calidad.
            Cuenta con una antena Omni MU-MIMO de 8dBi combinado con una
            potencia de 27dBm para garantizar una recepción óptima. Soporta
            alimentación entrante PoE 802.at IEEE de 48 V por el puerto WAN,
            para permitir una larga distancia de cableado. Soporta también
            Roaming imperceptible para cambiar de un AP a otro.
          </p>
          &nbsp;
          <SubTitleProduct content="Diseño profesional para exteriores + protección interna contra rayos, adecuado para múltiples entornos hostiles" />
          <p>
            Diseñado con una carcasa de plástico ABS para exteriores y
            componentes profesionales de protección contra rayos, proporcione
            IP67 a prueba de agua, a prueba de polvo, adecuado para múltiples
            entornos hostiles. Incluso puede operar eficientemente en ambientes
            extremadamente cálidos y fríos (-30 °C ~ + 70 °C).
          </p>
        </Col>
        <Col xs={6} md={6} className="mb-5">
          <img
            className="w-50"
            src={AWOD30AXiDetails}
            alt="Access Point Wi-Fi "
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TableSection.module.scss";
import { HomeSubtitle } from "../../../../HomeSubtitle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export function TableSection() {
  const columns = [
    { title: "Descargar" },
    { title: "Dispositivos" },
    { title: "Sistema operativo" },
  ];
  const data = [
    {
      Descargar: [{ path: "https://www.airlive.com/data/download/file/1.zip", label: "USB-AX18" }],
      Dispositivos: "Wireless AX1800 USB Adapter Driver",
      Sistemaoperativo: "Windows/Mac OS/Linux",
    },
    {
      Descargar: [{ path: "https://www.airlive.com/data/download/file/AirLive%20USB-25G%20Drivers.rar", label: "USB-25G" }],
      Dispositivos: " 2.5Gbps USB3.1 Ethernet Adapter Driver",
      Sistemaoperativo: "Windows/Mac OS/Linux",
    },
    {
      Descargar: [{ path: "https://www.airlive.com/data/download/file/USB-N15%20driver.rar", label: "USB-N15" }],
      Dispositivos: "150Mbps Nano Wireless USB Adapter Driver",
      Sistemaoperativo: "Windows/Mac OS/Linux",
    },
    {
      Descargar: [{ path: "https://www.airlive.com/data/download/file/USB-N15A%20driver.rar", label: "USB-N15A" }],
      Dispositivos: "2.4Ghz USB 2.0 Wireless Dongle External Antenna Adapter",
      Sistemaoperativo: "Windows/Mac OS/Linux",
    },
  ];
  return (
    <Container className="mb-5">
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Descargue los controladores AirLive para su dispositivo." />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.table}>
          <table>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    {row.Descargar.map((downloadLink, linkIndex) => (
                      <div key={linkIndex} className="d-flex align-items-center justify-content-center">
                        <a href={downloadLink.path} className={styles.btn_link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDownload} className={styles.icon} />{downloadLink.label}</a>
                      </div>
                    ))}
                  </td>
                  <td>{row.Dispositivos}</td>
                  <td>{row.Sistemaoperativo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
}

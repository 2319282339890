import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalLeftPOEGSH2404M400() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "24 x 30W PoE 10/100/1000 RJ45" 
        },
        {
            detail: "4 x puertos combinados Gigabit TP/SFP" 
        },
        {
            detail: "28 puertos totalmente" 
        },
        {
            detail: "1 x puerto de consola RJ45" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE 802.3i:10BASE-T Ethernet " 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "Control de flujo IEEE802.3x y contrapresión" 
        },
        {
            detail: "IEEE802.3ad Método estándar para realizar vínculos, agregación" 
        },
        {
            detail: "Árbol de expansión IEEE802.1d (STP) " 
        },
        {
            detail: "IEEE802.1w Rapid Spanning Tree (RSTP)" 
        },
        {
            detail: "Árbol de expansión múltiple IEEE802.1s (MSTP)" 
        },
        {
            detail: "Tráfico de clase de servicio (QoS / CoS) IEEE802.1p" 
        },
        {
            detail: "Priorización (función de filtrado de multidifusión)" 
        },
        {
            detail: "Operación del puente VLAN IEEE802.1Q" 
        },
        {
            detail: "IEEE802.1x Control de acceso cliente/servidor y Autenticación" 
        },
        {
            detail: "IEEE802.3at/por PoE" 
        },
        {
            detail: "IEEE802.3az Ethernet de eficiencia energética" 
        },
        {
            detail: "Capa de enlace de dispositivo IEEE802.1ab" 
        },
        {
            detail: "LLDP/LLDP-MED, Protocolo de descubrimiento " 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), SYS (sistema), 1-24 PoE &  ACT (POE)" 
        },
        {
            detail: "1-24 Link & ACT (Link & ACT), 25-28 Link (Link), 25-28 Ley (Act)" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones de iluminación:",
            detail: "6KV (modo general), 4KV (modo diferencial), ESD15KV" 
        },
    ];
    const Mechanic = [
        {
            title:"Mecánico:",
            detail: "Metal sólido de 19 1U montable en rack, sin ventilador" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación Entrada de energía:",
            detail: "AC100 ~ 240VAC, 400W" 
        },
        {
            detail: "Protocolo: IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 24" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 30 vatios" 
        },
        {
            detail: "Potencia de salida PoE / Switch PoE: máx. 370 vatios" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento de conmutación:",
            detail: "Almacena y reenvia" 
        },
        {
            detail: "Capacidad del switch: Back-plan de hasta 56 Gbps, Capacidad de conmutar paquetes; 41,66 Mpps" 
        },
        {
            detail: "DDR SDRAM: 128 MB" 
        },
        {
            detail: "Memoria flash: 16MB" 
        },
        {
            detail: "Dirección MAC: 8K" 
        },
        {
            detail: "Trama Jumbo: 9.6Kbytes" 
        },
        {
            detail: "Búfer de paquetes: 4Mb" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}


import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive10TX800.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive10TX800 } from "./SpecificationsLeftLive10TX800";
import { SpecificationsRightLive10TX800 } from "./SpecificationsRightLive10TX800/SpecificationsRightLive10TX800";

export function FeatureProductLive10TX800() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-10TX800" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive10TX800 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive10TX800 />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
        <Col xs={12} className={styles.minTitle}>
          Todas las marcas comerciales, logotipos y nombres de marcas son
          propiedad de sus respectivos dueños.
        </Col>
      </Row>
    </Container>
  );
}

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH1602M200.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH1602M200 } from "./TitleSectionL2POEGSH1602M200";
import { CharacteristicsPOEGSH1602M200 } from "./CharacteristicsPOEGSH1602M200";
import { TechnologyPoEManagement } from "../TechnologyPoEManagement";
import { VLANPolicy } from "../VLANPolicy";
import { RMONStatisticsSupport } from "../RMONStatisticsSupport";
import { FeatureProductPOEGSH1602M200 } from "./FeatureProductPOEGSH1602M200";
import Watchdog from "../../../../../../../assets/imagesproduct/SwitchL2/Watchdog.jpg";
import SurveillanceVLAN from "../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1602M200/SurveillanceVLAN.jpg";
import BuildSPF from "../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1602M200/BuildSPF.jpg";

export function POEGSH1602M200() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH1602M200 />
           <CharacteristicsPOEGSH1602M200 />
           <TechnologyPoEManagement content={"POE-GSH1602M-200"} image={Watchdog} image2={SurveillanceVLAN} />
           <VLANPolicy />
           <RMONStatisticsSupport image={BuildSPF} />
           <FeatureProductPOEGSH1602M200 />
        </Container>
    </div>
  );
}

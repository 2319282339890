import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live8GT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L8GT from "../../../../../../../../assets/imagesproduct/SwitchL2/Live8GT/L8GT.jpg";
import descriptionSwitchL2Live8GT from "../../../../../../../../assets/imagesproduct/SwitchL2/Live8GT/descriptionSwitchL2Live8GT.jpg";

export function TitleSectionL2Live8GT() {
  const Feature = [
    {
      detail: "8 puertos de alta velocidad Gigabit",
    },
    {
      detail: "Alarma de bucle",
    },
    {
      detail: "Prioridad de datos de QoS (puerto 1~2)",
    },
    {
      detail: "Modo VLAN: Seguridad mejorada",
    },
    {
      detail: "Modo extendido: hasta 250 m en modo extendido",
    },
    {
      detail: "Ajuste sencillo mediante interruptor DIP de hardware",
    },
    {
      detail: "Protección contra sobretensiones; Protección ESD",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch SOHO  Gigabit de 8 puertos, VLAN, QoS, Plug and Play" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-8GT" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L8GT}
            alt="Switch SOHO de 8 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Más que un simple switch Gigabit de 8 puertos Plug-and-Play, También es compatible con VLAN, QoS, extensión de cable adicional" />
          <p>
            Diseñado como un switch Gigabit no administrado plug-and-play con
            precio muy asequible. Además, proporciona funciones adicionales y
            útiles, como la seguridad de VLAN y la extensión de cable, que puede
            configurar fácilmente mediante su interruptor DIP. También es
            compatible con la prioridad de datos de QoS y tiene una compilación
            de alarma de bucle en su interior. Es fácil de administrar y
            mantener con su diseño sin ventilador y su pequeño tamaño, el
            producto se puede usar ampliamente en el hogar, la oficina SOHO o
            PYMES.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Características" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live8GT}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

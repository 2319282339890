import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSection10XGF12 } from "./TitleSection10XGF12";
import { FeatureProduct10XGF12 } from "./FeatureProduct10XGF12";
import styles from "./SwitchL310XGF12.module.scss";

export function SwitchL310XGF12() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSection10XGF12 />
           <FeatureProduct10XGF12 />
        </Container>
    </div>
  );
}

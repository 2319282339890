import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftXGS4804850() {
  const SecurityFeatures = [
    {
      title: "Características de seguridad:",
      detail: "Protección con contraseña",
    },
    {
      detail:
        "Soporte Restringir el acceso de los usuarios en función del número de puerto, la dirección IP y la dirección MAC",
    },
    {
      detail: "Soporte HTTPS, SSH V1 / V2",
    },
    {
      detail: "Soporte de enlace VLAN-IP-MAC-PORT",
    },
    {
      detail:
        "Soporte de detección ARP, protección de fuente IP, protección DoS",
    },
    {
      detail: "Soporte DHCP Snooping、Protección contra ataques DHCP",
    },
    {
      detail: "Soporte de certificación 802.1X",
    },
    {
      detail:
        "Soporte AAA (autenticación, autorización, contabilidad), soporte de protocolo RADIUS, TACSC +",
    },
    {
      detail: "Soporte de seguridad de puertos, aislamiento de puertos",
    },
  ];
  const AccessControl = [
    {
      title: "Control de acceso:",
      detail:
        "Soporte L2 (Capa 2) ~ L4 (Capa 4) Función de filtrado de paquetes",
    },
    {
      detail:
        "Soporte de duplicación de puertos, limitación de caudal, remarcado de QoS",
    },
  ];
  const QoS = [
    {
      title: "QoS:",
      detail: "Soporta colas de 8 puertos",
    },
    {
      detail:
        "Prioridad de puerto de soporte, prioridad 802.1P, prioridad DSCP",
    },
    {
      detail: "Soporte SP, algoritmo de programación de prioridad WRR",
    },
  ];
  const SpanningTree = [
    {
      title: "Árbol de expansión:",
      detail:
        "Admite el protocolo STP (IEEE 802.1d), RSTP (IEEE 802.1w) y MSTP (IEEE 802.1s)",
    },
    {
      detail: "Soporte Multi-instancia, interfaz Support Aggregate",
    },
    {
      detail: "Soporte de protección BPDU",
    },
  ];
  const multicast = [
    {
      title: "Multidifusión:",
      detail: "Soporte IGMP v1 / v2 / v3 Snooping",
    },
    {
      detail: "Soporte MLD v1 / v2 Snooping",
    },
    {
      detail: "Soporte  capa 2 multicast mecanismo de salida rápida, querier",
    },
    {
      detail: "Soporte de multidifusión estática IPv4 de capa 2",
    },
    {
      detail: "Soporte de multidifusión estática IPv6 de capa 2",
    },
    {
      detail: "Soporte IGMP v1 / v2 / v3 Layer 3 multicast",
    },
  ];
  const StormSuppression = [
    {
      title: "Supresión de tormentas:",
      detail: "Soporte de supresión de multidifusión",
    },
    {
      detail: "Soporte de supresión de tormentas de difusión",
    },
    {
      detail: "Soporte de supresión de unidifusión desconocida",
    },
  ];
  const RingProtection = [
    {
      title: "Protección de anillo:",
      detail: "Soporte de protección de anillo",
    },
  ];
  const LinkAggregation = [
    {
      title: "Agregación de enlaces:",
      detail: "Compatibilidad con la agregación estática",
    },
    {
      detail: "Soporte de agregación dinámica LACP",
    },
    {
      detail: "Soporte basado en IP, MAC, modos mixtos de equilibrio de carga",
    },
    {
      detail:
        "Soporte máximo 8 grupos de agregación (8 puertos por grupo de agregación)",
    },
  ];
  return (
    <Container>
      <ul>
        {SecurityFeatures.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {AccessControl.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {QoS.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SpanningTree.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {multicast.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StormSuppression.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <SubTitleProduct content="Puerto PoE: 48" />
      <ul>
        {RingProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LinkAggregation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import ApplicationsLayer3 from "../../../../../../../assets/imagesproduct/SwitchL3/ApplicationsLayer3.jpg";

export function ApplicationsLayer3Switches({ content }: { content: string | undefined}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Aplicaciones de los switches de capa 3" />
          <ul>
            <li>
              Un switch capa 3 es ampliamente utilizado en centros de datos y
              universidades, fábricas, empresas donde hay una configuración muy
              grande de redes informáticas. Debido a sus características como el
              enrutamiento estático y dinámico y su velocidad de conmutación
              rápida que un enrutador, se utiliza en la conectividad LAN para la
              interconexión de varias redes VLAN y LAN.
            </li>
            <li>
              El switch {content} es óptimo para disminuir la carga de los
              enrutadores sobrecargados. Esto se puede hacer configurando un
              switch de capa 3, cada uno con un enrutador principal en un
              escenario de red de área amplia para que el switch pueda
              administrar todo el enrutamiento VLAN a nivel local.
            </li>
            <li>
              El switch de capa 3 en combinación con varios switches de capa 2
              permite que más usuarios se conecten a la red sin necesidad de
              implementar un switch de capa 3 adicional y más ancho de banda.
              Por lo tanto, se implementa ampliamente en universidades e
              industrias de pequeña escala. En caso de que aumente el número de
              usuarios finales en una plataforma de red, sin ninguna mejora de
              la red, se puede acomodar fácilmente en el mismo escenario de
              ejecución.
            </li>
            <li>
              Un switch de capa 3 es lo suficientemente inteligente como para
              manejar y administrar el enrutamiento y el control del tráfico de
              servidores conectados localmente y dispositivos finales utilizando
              su alto ancho de banda.
            </li>
          </ul>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={ApplicationsLayer3}
            alt="Applications Layer3"
          />
        </Col>
      </Row>
    </Container>
  );
}

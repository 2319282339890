import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsSwitchIGM642BT125G() {
    const Feature = [
        {
          detail: "Todos y cada uno de los seis (6) puertos ofrece Gigabit de alta velocidad",
        },
        {
          detail: "Extiende aún más la red con 2 ranuras SFP que aceptan transceptores de fibra y van más allá de los límites de Ethernet",
        },
        {
          detail: "PoE de alta potencia, admite 802.3bt / at / af PoE ++ hasta 90W por puerto para garantizar la máxima potencia para el dispositivo conectado",
        },
        {
          detail: "Detecte y ofrezca energía automáticamente para los DP conectados",
        },
        {
          detail: "Admite la función de administración inteligente L2 SNMP y WEB que incluye DHCP Snooping, VLAN, IGMP, QoS, Spanning Tree, MAC Binding y Bandwidth Control",
        },
        {
          detail: "Soporte de gestión de PoE",
        },
        {
          detail: "Soporte de vigilancia PoE (watchdog) para detectar y reiniciar automáticamente PD bloqueado",
        },
        {
          detail: "Permite reiniciar la visualización del estado de operación, incluido el tráfico, la CPU, la memoria, el consumo de POE, el estado por puerto",
        },
        {
          detail: "Soporta administración Web-UI para una fácil gestión; Configuración CLI y script de comandos para la configuración avanzada; SNMP para la gestión de herramientas de red populares.",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}

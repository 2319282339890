import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH2404M400.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH2404M400 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH2404M400/GSH2404M400.jpg";
import descriptionSwitchL2POEGSH2404M400 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH2404M400/descriptionSwitchL2POEGSH2404M400.jpg";

export function TitleSectionL2POEGSH2404M400() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit Gestionado PoE+ de 400 W Gigabit PoE+ de 28 puertos, incluyendo 4 puertos Combo/SFP" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH2404M-400" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH2404M400}
            alt="Switch Gigabit Gestionado PoE+ de 400 W Gigabit PoE+ de 28 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Visión general" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch administrado SNMP y PoE con política VLAN avanzada para Video Conferencia | Video Vigilancia | Voz | Datos" />
          <p>
            El AirLive POE-GSH2404M-400 es un switch Gigabit PoE+ de 28 puertos
            gestionado por L2+ que incluye 24 puertos PoE y 4 puertos combinados
            Gigabit SFP/TP con alta potencia PoE de 400 W. Cada puerto PoE es
            compatible con 802.3at/af de hasta 30W, adaptativo automáticamente.
            Admite una función integral de administración SNMP, como IGMP EAPS
            Ring Network, VLAN Division, agregación de puertos, duplicación de
            puertos QoS, control de ancho de banda. Proporcionar la función de
            política de división de VLAN para establecer una mayor prioridad
            para videoconferencia, vigilancia, voz o datos que evita la pérdida
            o los retrasos del paquete. Además, el vigilante PoE puede detectar
            y reiniciar IPCAM o PD bloqueados, el mejor protector de dispositivo
            PoE 24/7. No se requiere manual, y puede proporcionar soluciones
            oportunas y confiables para la posterior operación y mantenimiento
            de los proyectos.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH2404M400}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightLive8GT() {
  const DIPSwitchControl = [
    {
      title: "Control del interruptor DIP:",
      detail:
        "VLAN: Modo de aislamiento de puertos. En este modo, los puertos (1 ~ 6) no pueden comunicarse entre sí y solo pueden comunicarse con el puerto UP-Link 7/8. Predeterminado: modo normal, todos los puertos pueden comunicarse entre sí, la distancia de transmisión está dentro de los 100 m, la velocidad de transmisión es adaptable de 10 M / 100 / 1000 M, soporte de bucle.",
    },
    {
      detail:
        "Modo Extendido: modo de extensión, la distancia de transmisión de datos se puede extender a 250 metros a una velocidad de transmisión de 10 Mbps Puerto 1 ~ 6 solamente. 1-6 puertos admiten negociación de reducción de velocidad de 100 M, 250 metros en modo 10 Mbps/s, puerto 7 y puerto 8 como puerto de enlace ascendente a 1000 Mbps. Nota: El modo VLAN y el modo Extender no se pueden utilizar al mismo tiempo.",
    },
  ];
  const PowerSupply = [
    {
      title: "Fuente de alimentación:",
      detail: "Fuente de alimentación: DC5V1A",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C ~ + 70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 13.7 x 7.7 x 2.5 cm(L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 17.5 x 12 x 6.3 cm(L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: NO: 0,3 kg; G.W: 0.5kg",
    },
    {
      detail: "Contenido del paquete: 1 x switch, 1 x cable de alimentación.",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 40 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensiones: 50,5 x 32 x 40 cm(L*W*H)",
    },
    {
      detail: "Peso de la caja: 21Kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo: Live-8GT: 8 conmutadores RJ45 de 10/100/1000 Mbps",
    },
    {
      detail: "Nombre: Conmutador Gigabit de 8 puertos, VLAN, QoS, Plug and Play",
    },
  ];
  return (
    <Container>
      <ul>
        {DIPSwitchControl.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerSupply.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

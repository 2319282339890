import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live26G.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L26G from "../../../../../../../../assets/imagesproduct/SwitchL2/Live26G/L26G.jpg";
import descriptionSwitchL2Live26G from "../../../../../../../../assets/imagesproduct/SwitchL2/Live26G/descriptionSwitchL2Live26G.jpg";

export function TitleSectionL2Live26G() {
  const Feature = [
    {
      detail: "24 puertos RJ-45 de alta velocidad Gigabit",
    },
    {
      detail: "2 puertos SFP de 1,25 G",
    },
    {
      detail: "Prioridad de datos de QoS (puerto 1~8)",
    },
    {
      detail: "Modo VLAN: Seguridad mejorada",
    },
    {
      detail: "Modo extendido: hasta 250 metros",
    },
    {
      detail: "Ajuste sencillo mediante interruptor DIP",
    },
    {
      detail: "Protección contra sobretensiones/ESD",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch SOHO de 26 puertos Gigabit, VLAN, QoS, Plug and Play Incluye  24 puertos Gigabit +  2x1.25G SFP" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-26G" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L26G}
            alt="Switch SOHO de 26 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Más que un switch Plug-and-Play de 26 Puertos Gigabit es un switch que soporta VLAN, calidad de servicio QoS y extensor de cable UTP" />
          <p>
            Es un switch no administrado plug-and-play con enlace ascendente SFP
            2x, cuyo precio es muy asequible. Además, proporciona funciones
            adicionales y útiles, como la seguridad de VLAN y la extensión de
            cable, que puede configurar fácilmente mediante su interruptor DIP.
            También soporta prioridad de datos de QoS para el puerto 1 ~ 8. Es
            fácil de utilizar y mantener con diseño sin ventilador y tamaño de
            19“. Recomendado ampliamente para el hogar, oficina SOHO o como
            switch de cliente para PYMES.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Características" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live26G}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

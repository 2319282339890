import { Container } from "react-bootstrap";
import styles from "./AirCloudTOP18AX.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleFeaturesSection } from "./TitleFeaturesSection";
import { SectionFunctionalities } from "./SectionFunctionalities";
import { AdvancedAdministration } from "./AdvancedAdministration";
import { SpecificationsTOP18AX } from "./SpecificationsTOP18AX";

export function AirCloudTOP18AX() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleFeaturesSection />
           <SectionFunctionalities />
           <AdvancedAdministration />
           <SpecificationsTOP18AX />
        </Container>
    </div>
  );
}

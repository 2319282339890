import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Characteristics10XGF12 } from "./Characteristics10XGF12";
import { Specifications10XGF12 } from "./Specifications10XGF12";
import { Technicalspecifications10XGF12 } from "./Technicalspecifications10XGF12";

export function FeatureProduct10XGF12() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Characteristics"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Characteristics" title="Características">
                        <Characteristics10XGF12 />
                    </Tab>
                    <Tab eventKey="Specifications" title="Especificaciones técnicas">
                        <Specifications10XGF12 />
                    </Tab>
                    <Tab eventKey="Technicalspecifications" title="Especificaciones técnicas">
                        <Technicalspecifications10XGF12 />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}

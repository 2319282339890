import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import Coverage24G from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD12ACi/Coverage2.4G.jpg";
import Coverage58G from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD12ACi/Coverage5.8G.jpg";

export function CoverageAngle() {
  const images = [
    {
      src: Coverage24G,
      alt: "Coverage Angle 2.4G",
      title: "2.4G",
    },
    {
      src: Coverage58G,
      alt: "Coverage Angle 5.8G",
      title: "5.8G",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Especificaciones del ángulo de cobertura de la antena omnidireccional" />
        </Col>
      </Row>
      <Row>
        {images.map((item) => (
          <>
            <Col xs={6}>
              <SubTitleProduct content={item.title} />
            </Col>
            <Col xs={6}>
              <img className="w-100" src={item.src} alt={item.alt} />
            </Col>
          </>
        ))}
      </Row>
    </Container>
  );
}

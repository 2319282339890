import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import AccessPoints from "../../../../../../../../assets/imagesproduct/AirCloud/AccessPoints.jpg";
import CaptivePortal from "../../../../../../../../assets/imagesproduct/AirCloud/CaptivePortal.jpg";

export function AccessPointsCaptivePortal() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Potente herramienta para desplegar Access Points a gran escala" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            La plataforma AirCloud puede administrar una serie de puntos de
            acceso, controladores de hardware y CPE para exteriores, así como
            más productos por venir. La solución se puede aplicar para ciudades
            inalámbricas, campus a gran escala, hoteles y centros comerciales.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={AccessPoints}
            alt="Large Scale deployment of Campus Application"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Portal cautivo en la nube con la seguridad de autenticación de acceso" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            AirCloud admite "Cloud Captive Portal" con una página de inicio de
            sesión basada en la web para autenticar a los usuarios y controlar
            el acceso a la red pública o al punto de acceso Wi-Fi.
          </p>
          &nbsp;
          <p>
            Cuando los usuarios intentan acceder a la red pública o al punto de
            acceso Wi-Fi, se les redirige a la página de inicio de sesión del
            portal cautivo en la nube, donde deben ingresar sus credenciales y
            aceptar los términos y condiciones de uso. Una vez autenticados, los
            usuarios pueden acceder a la red y, por lo general, se les dirige a
            una página de destino u otro destino específico.
          </p>
          &nbsp;
          <p>
            Los portales cautivos en la nube pueden ofrecer varios beneficios
            para las empresas u organizaciones que proporcionan acceso Wi-Fi
            público. Pueden ayudar a garantizar que solo los usuarios
            autorizados puedan acceder a la red, proporcionar estadísticas y
            análisis de uso de datos, e incluso ofrecer oportunidades
            publicitarias o promocionales a través de la página de destino.
            Además, los portales cautivos en la nube pueden permitir una fácil
            personalización y administración de la página de inicio de sesión,
            lo que simplifica la actualización de la marca, los términos de uso
            u otra información.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={CaptivePortal}
            alt="Cloud Captive Portal"
          />
        </Col>
      </Row>
    </Container>
  );
}

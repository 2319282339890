import { Col, Container, Row } from "react-bootstrap";
import styles from "./SliderSection.module.scss";
import Carousel from "react-bootstrap/Carousel";
import AirliveProductTelecom from "../../../../../assets/images/banners/banner_01.jpg";
import bannerAirliveStartups from "../../../../../assets/images/banners/banner_02.jpg";
import banner04 from "../../../../../assets/images/banners/banner_04.jpg";
import bannerModeloB2B from "../../../../../assets/images/banners/banner_06.jpg";

export function SliderSection() {
  const imageCarrousel = [
    {
      img: AirliveProductTelecom,
      alt: "Tecnología AirLive de Telecom 10G PON",
    },
    {
      img: bannerAirliveStartups,
      alt: "Propuesta de valor para startups",
    },
    {
      img: banner04,
      alt: "La Nuve AirLive AirCloud",
    },
    {
      img: bannerModeloB2B,
      alt: "Modelo B2B Alianza Comercial AirLive",
    },
  ];

  return (
    <Container>
      <Row>
        <Col xs={12} md className="mt-3 mb-5 h-100">
          <Carousel className={styles.carousel} variant="dark">
            {imageCarrousel.map((image) => (
              <Carousel.Item>
                <img className={styles.img} src={image.img} alt={image.alt} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightLive2TX501() {
  const TransmissionDistance = [
    {
      title: "Distancia de transmisión:",
      detail: "10Base-T: Cat3,4,5 UTP (≤250 metros) ",
    },
    {
      detail: "100Base-TX: Cat5 o posterior UTP (150 metros)",
    },
    {
      detail: "1000Base-TX: Cat5e o posterior UTP (150 metros)",
    },
    {
      detail: "2500Base-TX: Cat5e o UTP posterior (150 metros)",
    },
  ];
  const MediaFiber  = [
    {
      title: "Medios de fibra:",
      detail: "Multimodo Fiber: 50/125 、 62.5/125 、 100/140um",
    },
    {
      detail: "Monomodo Fiber: 8/125、8.7/125、9/125、10/125um",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C ~ + 70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 14.0 x 9.3 x  2.8 cm (L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 20.3 x 16.0 x 5.5 cm (L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: N.W: 0.33Kgs; G.W: 0.46Kgs",
    },
    {
      detail: "Contenido del paquete: 1 x switch, 1 x cable de alimentación.",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 40 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensiones: 58.0 x 33.5 x 44.5cm (L*W*H)",
    },
    {
      detail: "WOcho: 19.5Kgs (G.W)",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo:",
    },
    {
      detail: "Live-2TX501, 6-port 2.5Gbps Multi Gigabit Switch",
    },
    {
      detail: "incl 5x RJ45 and 1x SFP+, Plug and Play, 10/100",
    },
    {
      detail: "1000/2500Mbps, auto-negotiation",
    },
  ];
  return (
    <Container>
      <ul>
        {TransmissionDistance.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {MediaFiber.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftLive16GTMini() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "16 x RJ45 de 10/100/1000 Mbps" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802,3 veces" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "EMI: FCC Parte 15 CISPR (EN55032) clase A" 
        },
        {
            detail: "EMS: EN61000-4-2 (ESD), EN61000-4-4 (EFT), EN61000-4-5 (Sobretensión)" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), Bucle, Enlace, 1000M" 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Encapsulamiento de metal" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones y ESD:",
            detail: "4KV (sobretensión), 8KV (EDS)" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "almacenar y reenviar" 
        },
        {
            detail: "Capacidad del switch: Plan de seguimiento de hasta 32 Gbps" 
        },
        {
            detail: "Tasa de empaque a plazo; 23.8Mpps" 
        },
        {
            detail: "Dirección MAC: 8K" 
        },
        {
            detail: "Búfer de paquetes: 4Mb" 
        },
        {
            detail: "Fotograma jumbo: 4K" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

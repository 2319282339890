import { Container, Row, Col } from "react-bootstrap";
import styles from "./ControllerSpecifications.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";

export function ControllerSpecifications() {
  const LeftSpecifications = [
    {
      title: "Mantenimiento - Template - Add",
      detail: "Nombre de plantilla",
    },
    {
      detail: "WiFi 2G, WiFi 5G, Avanzado, Otros parámetros",
    },
    {
      title: "Autenticación - Portal - Antes de la Autenticación",
      detail: "Móvil, PC, Tipo de terminal, Nombre del título, Cartel",
    },
    {
      detail: "Imagen 1, Imagen 2, Imagen 3, Guía de direcciones",
    },
    {
      detail: "Color, Aplicar",
    },
    {
      title: "Proceso de  Autenticación",
      detail: "Móvil, PC, Guía de imágenes",
    },
    {
      title: "Despues de la Autenticación",
      detail: "Móvil, PC, Guía de imágenes",
    },
    {
      title: "Estrategia",
      detail: "Definir estrategia, Nombre de etrategia",
    },
    {
      title: "Validación de Autenticación",
      detail: "1-7 Días, Permanente",
    },
    {
      detail: "Lista Blanca, Lista Negra, IP, MAC",
    },
  ];
  const RightSpecifications = [
    {
      title: "Métodos de Autenticación",
      detail: "Configuración de Autenticación, No Autenticado",
    },
    {
      title: "Miembro",
      detail: "Configurar, Eliminar, Eliminar por lote, Add Member",
    },
    {
      detail: "Importar miembro, Descargar, Cuenta de Miembro",
    },
    {
      detail: "Contraseña de ingreso, Guardar",
    },
    {
      title: "Detalles de Autenticación",
      detail: "Exportar lista de autenticación, Detalles",
    },
    {
      title: "Cuenta - Configuración",
      detail: "Cuenta, Apodo, Móvil, Email, Administrador",
    },
    {
      detail: "Invitado, Estado, Proyecto asociado",
    },
    {
      title: "Crear Cuenta",
      detail: "Reinciar Contraseña, Eliminar Cuenta",
    },
    {
      title: "Idioma",
      detail: "Español",
    },
    {
      title: "AirLive AirCloud",
      detail: "Controaldor basado en la nube AirLive Cloud",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Controlador basado en la nube AirLive AirCloud" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftSpecifications.map((item) => (
              <li>
                <SubTitleProduct content={item.title} />
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightSpecifications.map((item) => (
              <li>
                <SubTitleProduct content={item.title} />
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

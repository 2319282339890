import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./SwitchL3XGS4804.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionXGS4804 } from "./TitleSectionXGS4804";
import { CharacteristicsXGS4804 } from "./CharacteristicsXGS4804";
import { FeatureProductXGS4804 } from "./FeatureProductXGS4804";
import { ApplicationsLayer3Switches } from "../ApplicationsLayer3Switches";
import { PerformanceandScalability } from "../PerformanceandScalability";
import { RouteManagement } from "../RouteManagement";
import { InterfaceManagement } from "../InterfaceManagement";

export function SwitchL3XGS4804() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionXGS4804 />
           <CharacteristicsXGS4804 />
           <ApplicationsLayer3Switches content="L3-XGS4804" />
           <PerformanceandScalability content="L3-XGS4804" />
           <RouteManagement />
           <InterfaceManagement content="L3-XGS4804" />
           <FeatureProductXGS4804 />
        </Container>
    </div>
  );
}

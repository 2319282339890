import { Container, Row, Col } from "react-bootstrap";
import styles from "./RoamingWifiWLAN128GM.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import SeamlessWirelessRoaming from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/SeamlessWirelessRoaming.jpg";
import MonitorCloudNotify from "../../../../../../../../assets/imagesproduct/AircloudProducts/WLAN128GM/MonitorCloudNotify.jpg";

export function RoamingWifiWLAN128GM() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Roaming wifi - Itinerancia inalámbrica automática" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            La itinerancia inalámbrica automática se refiere a la capacidad de
            un dispositivo inalámbrico para cambiar automáticamente entre
            diferentes puntos de acceso o redes inalámbricas a medida que el
            dispositivo se mueve dentro del alcance, sin intervención manual
          </p>
          &nbsp;
          <p>
            Esto permite que el dispositivo mantenga una conexión perfecta e
            ininterrumpida a Internet u otros recursos de red, mejorando la
            experiencia del usuario y la productividad. La itinerancia
            inalámbrica automática se usa comúnmente en redes Wi-Fi,
            particularmente en entornos empresariales.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={SeamlessWirelessRoaming}
            alt="Seamless Wireless Roaming"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Monitoreo de red en vivo y Notificación Cloud" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El monitor de estado de red en vivo es una herramienta que
            proporciona monitoreo y análisis en tiempo real del rendimiento de
            una red. Puede mostrar información sobre el tráfico de red, la
            intensidad de la señal, la conectividad del dispositivo y otras
            métricas relevantes, y proporcionar alertas cuando hay problemas que
            requieren atención.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Cloud Notify hace referencia a una característica que permite a los
            administradores de red recibir notificaciones sobre eventos y
            problemas de red a través de un servicio basado en la nube. Esto
            puede incluir alertas sobre interrupciones de la red, violaciones de
            seguridad y otros problemas, así como actualizaciones sobre
            actualizaciones de software y otros eventos importantes.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Al combinar, un monitor de estado de red en vivo con Cloud Notify
            puede proporcionar una visión completa del rendimiento de una red. Y
            permita que los administradores de red respondan rápidamente a
            cualquier problema que pueda surgir. Mediante el uso de
            notificaciones basadas en la nube, los administradores pueden
            mantenerse informados sobre los eventos de red incluso cuando están
            lejos de sus escritorios. y tomar medidas para resolver los
            problemas según sea necesario. Esto puede ayudar a mejorar la
            confiabilidad y el rendimiento de una red y garantizar que los
            usuarios puedan acceder a los recursos que necesitan cuando los
            necesitan.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={MonitorCloudNotify}
            alt="Monitor Cloud Notify"
          />
        </Col>
      </Row>
    </Container>
  );
}

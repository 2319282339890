import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import { Container, Nav, Navbar, Row } from "react-bootstrap";

export interface SidebarProps {
  title: string;
  links: LinkItem[];
}

export interface LinkItem {
  id?: string;
  name: string;
  path: string;
}

export function Sidebar(props: SidebarProps) {
  const [selectedLink, setSelectedLink] = useState<string>();

  const handleLinkClick = (path: string) => {
    setSelectedLink(path);
  };

  return (
    <Navbar variant="dark" expand="lg" className={styles.sidebar}>
      <Container>
        <Row className="w-100 m-0">
          <div className="d-flex align-item-center justify-content-between w-100 p-0">
            <h2 className={styles.sidebar_title}>{props.title}</h2>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav"  className="p-0">
            <Nav className="m-0">
              <Nav.Link className={styles.sidebar_links}>
                {props.links.map((link, index) => (
                  <Link
                    key={index}
                    to={link.path}
                    className={`${styles.sidebar_links_item} ${
                      selectedLink === link.path ? styles.sidebar_links_item_active : ""
                    }`}
                    onClick={() => handleLinkClick(link.path, )}
                  >
                    {link.name}
                  </Link>
                ))}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Row>
      </Container>
    </Navbar>
  );
}

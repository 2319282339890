import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionSwitchIGM642BT125G.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import IGM642BT125G from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/IGM642BT125G.jpg";
import descriptionSwitchIGM642BT125G from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/descriptionSwitchIGM642BT125G.jpg";

export function TitleSectionSwitchIGM642BT125G() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit L2+ Industrial Tipo anillo Administrable con POE+ 6-Puertos PoE++ Gigabit incluyendo 2 *1.25G SFP" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="IGM-642BT-1.25G" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={IGM642BT125G}
            alt="Switch Gigabit L2+ Industrial Tipo anillo Administrable con POE+ 6-Puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Visión general" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch POE gestionado altamente confiable de grado industrial para entornos extremos Con VLAN y QoS" />
          <p>
            El AirLive IGM-642BT-1.25G es un switch Gigabit PoE++ de 6 puertos
            gestionado por L2+ que incluye 4 puertos PoE y 2 puertos Gigabit
            SFP. Cada puerto PoE admite 802.3bt/at/af hasta 90W, ajuste
            automático, amplio rango de temperatura de -40 °C a 85 °C,
            condiciones extremas de carcasa metálica IP40 a través de un bloque
            de terminales de 4 pines (DC48 ~ 55V). El switch industrial L2 +
            Gigabit de 6 puertos proporciona funciones completas de
            administración SNMP, admite VLAN, agregación de puertos, duplicación
            de puertos QoS, control de ancho de banda y ERP de anillo de tokens.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchIGM642BT125G}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

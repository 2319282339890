import { Container } from "react-bootstrap";
import styles from "./AirCloudPlatform.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { AirCloud } from "./AirCloud";
import { FunctionsPlatformSection } from "./FunctionsPlatformSection";
import { AccessPointsCaptivePortal } from "./AccessPointsCaptivePortal";
import { AdministrationViewsNetworks } from "./AdministrationViewsNetworks";
import { AircloudPlatformAccess } from "./AircloudPlatformAccess";
import { AircloudAndroidApp } from "./AircloudAndroidApp";
import { AircloudLoginDemo } from "./AircloudLoginDemo";
import { SpecificationsAirCloud } from "./SpecificationsAirCloud";
import { ControllerSpecifications } from "./ControllerSpecifications";

export function AirCloudPlatform() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <AirCloud />
           <FunctionsPlatformSection />
           <AccessPointsCaptivePortal />
           <AdministrationViewsNetworks />
           <AircloudPlatformAccess />
           <AircloudAndroidApp />
           <AircloudLoginDemo />
           <SpecificationsAirCloud />
           <ControllerSpecifications />
        </Container>
    </div>
  );
}

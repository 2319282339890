import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionXGS2404400 } from "./TitleSectionXGS2404400";
import { FeatureProductXGS2404400 } from "./FeatureProductXGS2404400";
import styles from "./SwitchL3POEXGS2404400.module.scss";

export function SwitchL3POEXGS2404400() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionXGS2404400 />
           <FeatureProductXGS2404400 />
        </Container>
    </div>
  );
}

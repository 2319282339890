import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function PrivacyPolicySection() {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Política de privacidad" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>Fecha de vigencia: 06 de julio de 2018</p>
          <p>
            AirLive ("nosotros", "nosotros" o "nuestro") opera el sitio web
            https://www.airlive.com (el "Servicio"). Esta página le informa
            sobre nuestras políticas con respecto a la recopilación, el uso y la
            divulgación de datos personales cuando utiliza nuestro Servicio y
            las opciones que tiene asociadas con esos datos. Esta Política de
            privacidad de AirLive está impulsada por PrivacyPolicies.com.
          </p>
          <p>
            Utilizamos sus datos para proporcionar y mejorar el Servicio. Al
            usar el Servicio, usted acepta la recopilación y el uso de
            información de acuerdo con esta política. A menos que se defina lo
            contrario en esta Política de privacidad, los términos utilizados en
            esta Política de privacidad tienen los mismos significados que en
            nuestros Términos y condiciones, accesibles desde
            https://www.airlive.com
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <HomeSubtitle title="Recopilación y uso de información" />
        </Col>
        <Col xs={12}>
          <p>
            Recopilamos varios tipos diferentes de información para varios
            propósitos para brindarle y mejorar nuestro Servicio.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

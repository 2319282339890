import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalRightIGM642POE125G() {
  const PhysicStructure = [
    {
      title: "Estructura física:",
      detail: "Grado de protección: IP40 ",
    },
    {
      detail: "Instalación: Carril DIN",
    },
  ];
  const IndustryStandard = [
    {
      title: "Estándar de la industria: ",
      detail: "EMI: FCC Parte 15, CISPR (EN55032) clase A",
    },
    {
      detail:
        "EMS: EN61000-4-2 (ESD), EN61000-4-4 (EFT), EN61000-4-5 (Surge)",
    },
  ];
  const L2Management = [
    {
      title: "Función de Gestión L2",
      detail: "Administración de Puertos",
    },
    {
      detail: "Soporte para habilitar y deshabilitar puertos",
    },
    {
      detail: "Soporte de velocidad, dúplex, configuración de MTU, etc.",
    },
    {
      detail: "Admite ajustes de control de flujo de control de flujo",
    },
    {
      detail: "Soporte de duplicación de puertos, etc.",
    },
    {
      detail: "Dirección de acceso al puerto de soporte",
    },
  ];
  const STP = [
    {
      title: "STP: ",
      detail: "Árbol de expansión estándar (STP)",
    },
    {
      detail: "802.1d Rapid Spanning Tree (RSTP) 802.1w",
    },
    {
      detail: "Árbol de expansión múltiple (MSTP) 802.1s",
    },
  ];
  const RingNetworkProtocol = [
    {
      title: "Protocolo de red en anillo:",
      detail: "ERPS",
    },
  ];
  const LinkAggregation = [
    {
      title: "Agregación de enlaces:",
      detail: "Admite agregación manual estática",
    },
    {
      detail: "Soporte de agregación dinámica LACP",
    },
  ];
  const VLAN = [
    {
      title: "VLAN:",
      detail: "Soporte VLAN e IEEE 802.1Q VLAN",
    },
  ];
  const GVRPIGMP = [
    {
      title: "GVRP:",
      detail: "Configuración global, configuración de puertos",
    },
    {
      detail: "IGMP Fisgoneo: Soporte estático agregar / eliminar",
    },
  ];
  const Mac = [
    {
      title: "MAC:",
      detail: "Soporte estático agregar / eliminar",
    },
    {
      detail: "Límite de aprendizaje de direcciones MAC",
    },
    {
      detail: "Ajuste dinámico del tiempo de envejecimiento",
    },
  ];
  return (
    <Container>
      <ul>
        {PhysicStructure.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {L2Management.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {STP.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {RingNetworkProtocol.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LinkAggregation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {GVRPIGMP.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mac.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH800120BT.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH800120BT } from "./TitleSectionL2POEGSH800120BT";
import { CharacteristicsPOEGSH800120BT } from "./CharacteristicsPOEGSH800120BT";
import { FeatureProductPOEGSH800120BT } from "./FeatureProductPOEGSH800120BT";
import { PoETechnology } from "../PoETechnology";
import SurveillanceDevices from "../../../../../../../assets/imagesproduct/SwitchL2/SurveillanceDevices.jpg";
import PoEprotection from "../../../../../../../assets/imagesproduct/SwitchL2/PoEprotection.jpg";

export function POEGSH800120BT() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH800120BT />
           <CharacteristicsPOEGSH800120BT />
           <PoETechnology content={"POE-GSH800-120BT"} image={SurveillanceDevices} image2={PoEprotection} />
           <FeatureProductPOEGSH800120BT />
        </Container>
    </div>
  );
}

import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleXponONU1GE } from "./TitleXponONU1GE";
import { SpecificationsXponONU1GE } from "./SpecificationsXponONU1GE";
import styles from "./XponONU1GE.module.scss";

export function XponONU1GE() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleXponONU1GE />
           <SpecificationsXponONU1GE />
        </Container>
    </div>
  );
}

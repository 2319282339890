import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import Routes from "../../../../../../../assets/imagesproduct/SwitchL3/Routes.jpg";
import SnoopingFunctionality from "../../../../../../../assets/imagesproduct/SwitchL3/SnoopingFunctionality.jpg";

export function RouteManagement() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Manejo de rutas OSPF L3" />
          <p>
            Open shortest path first (OSPF) es un protocolo de enrutamiento de
            estado de vínculo que se usa para encontrar la mejor ruta entre el
            origen, que generalmente se usa en el mismo dominio de enrutamiento.
            Aquí, el dominio de enrutamiento se refiere a un sistema autónomo
            (as), que se refiere a un grupo de redes que intercambian
            información de enrutamiento a través de una política de enrutamiento
            unificada o protocolo de enrutamiento. En este sentido, todos los
            routers OSPF mantienen la misma base de datos que describe la
            estructura como, que almacena la información de estado de los
            enlaces correspondientes en el dominio de enrutamiento. Es a través
            de esta base de datos que los enrutadores OSPF calculan sus tablas
            de enrutamiento OSPF.
          </p>
          &nbsp;
          <p>
            Como protocolo de enrutamiento de estado de enlace, OSPF transmite
            datos de multidifusión de estado de enlace LSA (anuncio de estado de
            enlace) a todos los enrutadores en un área determinada, que es
            diferente del protocolo de enrutamiento de vector de distancia. El
            protocolo de enrutamiento vectorial de distancia que ejecuta el
            enrutador pasa parte o todas las tablas de enrutamiento a sus
            enrutadores vecinos.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={Routes}
            alt="Routes"
          />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte de la funcionalidad Snooping DHCP L3" />
          <p>
            Prevención contra ataques ilegales de enrutador (servidor DHCP) o
            envío de información DHCP.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img 
            className="w-100" 
            src={SnoopingFunctionality} 
            alt="Snooping functionality" />
        </Col>
      </Row>
    </Container>
  );
}

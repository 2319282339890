import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import ManageAdvancedFeaturesL2 from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/ManageAdvancedFeaturesL2.jpg";
import ProtectionRing from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/ProtectionRing.jpg";
import LongDistanceFiberBackbone from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/LongDistanceFiberBackbone.jpg";

export function ManageAdvancedFeatures() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Inteligencia - Capa 2+ para Administrar funciones avanzadas" />
          <p>
            El IGM-642BT-1.25G se puede utilizar para funciones avanzadas de
            gestión de conmutadores, como agregación dinámica de enlaces de
            puertos, VLAN Q-on-Q, protocolo de árbol de expansión rápida, QoS de
            capa 2 a capa 4, control de ancho de banda e inspección IGMP. El
            switch ofrece VLAN con etiquetas 802.1Q, y los grupos VLAN
            permitidos son hasta un máximo de 255. Al admitir la agregación de
            puertos, el IGM-642BT-1.25G PoE permite el funcionamiento de un
            troncal de alta velocidad que combina varios puertos. Un máximo de 2
            grupos troncales con un grupo troncal de 2 puertos y también admite
            la conmutación por error.
          </p>
        </Col>
        <Col xs={8} className="mb-5 text-align-center">
          <img className="w-100" src={ManageAdvancedFeaturesL2} alt="image" />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Anillo de Protección Ethernet ERPS" />
          <p>
            El switch IGM-642BT-1.25G soporta el mecanismo ERPS, mediante el
            cual es posible tener estructuras multianillo y multidominio
            (anillos maestro y subdominio) y optimiza el mecanismo de inspección
            en términos de fallos bidireccionales. Además, admite la copia de
            seguridad del dispositivo principal, el uso compartido de la carga y
            otros métodos de trabajo en la conmutación de 50 ms. Esto significa
            que cuando se han colocado varios conmutadores en un anillo y se
            interrumpe el trabajo de red, se recuperará en 50 ms o menos. Lo que
            significa que la red crítica, como en entornos hostiles o líneas de
            producción automatizadas y vigilancia, volverá a estar en línea con
            una pérdida mínima de tiempo. La red de anillo también está
            protegida de nuevo bucles.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img className="w-100" src={ProtectionRing} alt="img" />
        </Col>
      </Row>
      <Row>
        <Col xs={4} className="mb-5">
          <img className="w-100" src={LongDistanceFiberBackbone} alt="img" />
        </Col>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Backbone de fibra de larga Distancia" />
          <p>
            Entienda aún más la red con ranuras SPF. Utilice el IGM-642BT-1.25G
            como backbone de su red, que puede alcanzar hasta 120 km de
            distancia y 1250 Mbps de transmisión de datos. (Dependiendo del
            módulo SFP utilizado.)
          </p>
        </Col>
      </Row>
    </Container>
  );
}

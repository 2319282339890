import React, { Suspense, useState } from "react";
import "./App.scss";
import { Navigation } from "./components/Navigation";
import { Footer } from "./components/Footer/Footer";
import { Container } from "react-bootstrap";

import { RouterProvider } from "react-router-dom";

import i18n from "./i18n";
import LocaleContext from "./LocaleContext";
import { Loading } from "./components/Loading";
import { CreateRoutes } from "./routes";

const router = CreateRoutes();

function App() {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <Suspense fallback={<Loading />}>
        <Navigation />
        <Container fluid className="px-0">
          <RouterProvider router={router} />
        </Container>
        <Footer />
      </Suspense>
    </LocaleContext.Provider>
  );
}

export default App;

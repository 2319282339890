import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalLeftPOEGSH1602M200() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "16 x 30W PoE 10/100/1000 RJ45" 
        },
        {
            detail: "2 puertos Gigabit SFP" 
        },
        {
            detail: "2 puertos Gigabit RJ45" 
        },
        {
            detail: "20 puertos totalmente" 
        },
        {
            detail: "1 puerto de consola RJ45" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "Control de flujo IEEE802.3x y contrapresión" 
        },
        {
            detail: "IEEE802.3ad Método estándar para agregación de enlaces" 
        },
        {
            detail: "Árbol de expansión IEEE802.1d (STP) " 
        },
        {
            detail: "IEEE802.1w Árbol de expansión rápida (RSTP)" 
        },
        {
            detail: "Árbol de expansión múltiple IEEE802.1s (MSTP)" 
        },
        {
            detail: "Tráfico de clase de servicio (QoS / CoS) IEEE802.1p" 
        },
        {
            detail: "Priorización (función de filtrado de multidifusión)" 
        },
        {
            detail: "Operación del puente VLAN IEEE802.1Q" 
        },
        {
            detail: "IEEE802.1x Control de acceso cliente/servidor y Autenticación" 
        },
        {
            detail: "IEEE802.3at/af PoE" 
        },
        {
            detail: "IEEE802.3az Ethernet de eficiencia energética" 
        },
        {
            detail: "IEEE802.1ab Capa de enlace de dispositivo LLDP/LLDP-MED" 
        },
        {
            detail: "Protocolo de descubrimiento " 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), SYS (sistema), PoE/ACT (PoE), Link/ACT" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones de iluminación:",
            detail: "General 4KV, Diferencial 2KV, ESD 8KV aire, 6KV contacto" 
        },
    ];
    const Mechanic = [
        {
            title:"Mecánico:",
            detail: "Metal sólido 1U montable en rack, sin ventilador" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 16" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 30 vatios" 
        },
        {
            detail: "Salida de potencia PoE / conmutador PoE: máx. 200 vatios" 
        },
        {
            detail: "Entrada de energía: AC100 ~ 240VAC" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento de conmutación:",
            detail: "Medios de fibra: Fibra multimodo: 50/125, 62,5/125, 100/140 μm" 
        },
        {
            detail: "Fibra monomodo: 8/125, 8,7/125, 9/125, 10/125 μm" 
        },
        {
            detail: "Arquitectura de conmutación: Almacenar y reenviar" 
        },
        {
            detail: "Ancho de banda de la capacidad del conmutador: 40 Gbps" 
        },
        {
            detail: "Velocidad de reenvío de paquetes de conmutación; 29.76Mpps" 
        },
        {
            detail: "Dirección MAC: 8K" 
        },
        {
            detail: "Fotogramas gigantes: 9,6 Kbytes" 
        },
        {
            detail: "Memoria de búfer de paquetes: 4 Mb" 
        },
        {
            detail: "Memoria flash: 16MB" 
        },
        {
            detail: "DDR SDRAM: 128 MB" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

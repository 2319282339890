import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalspecificationsLeftXGS2404() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware Interfaz del dispositivo:",
      detail: "24 puertos 10/100/1000M",
    },
    {
      detail: "4 puertos SFP+ 10G",
    },
    {
      detail: "1 x puerto de consola RJ45 ",
    },
    {
      detail: "1 x puerto USB",
    },
  ];
  const Standard = [
    {
      title: "Estándar:",
      detail: "IEEE 802.3:Protocolo MAC Ethernet ",
    },
    {
      detail: "IEEE 802.3i:10BASE-T Ethernet ",
    },
    {
      detail: "IEEE 802.3u:100BASE-TX Fast Ethernet ",
    },
    {
      detail: "IEEE 802.3ab:1000BASE-T Gigabit Ethernet",
    },
    {
      detail: "IEEE 802.3z:1000BASE-X Gigabit Ethernet (fibra óptica) ",
    },
    {
      detail: "IEEE 802.3ae: 10G Ethernet (fibra óptica)",
    },
    {
      detail: "IEEE 802.3az:Ethernet de eficiencia energética",
    },
    {
      detail:
        "IEEE 802.3ad:Método estándar para realizar la agregación de vínculos ",
    },
    {
      detail: "IEEE 802.3x:Control de flujo",
    },
    {
      detail: "IEEE 802.1ab: LLDP/LLDP-MED (Protocolo de detección de capa de enlace)",
    },
    {
      detail:
        "IEEE 802.1p:Priorización de tráfico del protocolo QoS/CoS de capa LAN (función de filtrado de multidifusión)",
    },
    {
      detail: "IEEE 802.1q:Operación del puente VLAN",
    },
    {
      detail:
        "IEEE 802.1x:Protocolo de autenticación y control de acceso cliente/servidor ",
    },
    {
      detail: "IEEE 802.1d:STP",
    },
    {
      detail: "IEEE 802.1s:MSTP",
    },
    {
      detail: "IEEE 802.1w:RSTP",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail:
        "PWR (indicador de encendido), SYS (luces del sistema), enlace (luz de enlace).",
    },
    {
      detail: "PoE(luz),Act(luz de datos).",
    },
  ];
  const SurgeProtection = [
    {
      title: "Protección contra sobretensiones:",
      detail: "Sobretensión 6KV, ESD 8KV",
    },
  ];
  const Mechanic = [
    {
      title: "Mecánico:",
      detail: "Encapsulamiento de metal macizo 19 1U para rack, IP30",
    },
  ];
  const PowerInput = [
    {
      title: "Alimentación Entrada de energía:",
      detail: "AC100 ~ 240VAC",
    },
  ];
  const SwitchArchitecture = [
    {
      title:
        "Arquitectura del switch | Rendimiento Rendimiento de conmutación:",
      detail: "Ancho de banda: 128Gbps",
    },
    {
      detail: "Velocidad de reenvío de paquetes: 95.23Mpps",
    },
    {
      detail: "DDR SDRAM: 128MB",
    },
    {
      detail: "Memoria flash: 16MB",
    },
    {
      detail: "Caché del paquete: 12Mbit",
    },
    {
      detail: "Dirección MAC: 16K",
    },
    {
      detail: "Trama Jumbo: 12Kbytes",
    },
    {
      detail: "VLAN:4096",
    },
    {
      detail: "MTBF: 100000 hora ",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

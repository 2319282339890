import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./ConverterMC10GSFP.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionConverterMC10GSFP } from "./TitleSectionConverterMC10GSFP";
import { FeatureProductConverterMC10GSFP } from "./FeatureProductConverterMC10GSFP";

export function ConverterMC10GSFP() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionConverterMC10GSFP />
        <FeatureProductConverterMC10GSFP />
      </Container>
    </div>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./GeneralSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import AirCloudTOP12AC from "../../../../../../../../assets/imagesproduct/AircloudProducts/TOP12AC/AirCloudTOP12AC.jpg";
import BottomTOP12AC from "../../../../../../../../assets/imagesproduct/AircloudProducts/TOP12AC/BottomTOP12AC.jpg";
import SeamlessWirelessRoaming from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/SeamlessWirelessRoaming.jpg";

export function GeneralSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Access Point  Wave 2 MU-MIMO AC 1200 Gigabit PoE Gestión centralizada & AirCloud, roaming ininterrumpido" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AirCloud TOP-12AC" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AirCloudTOP12AC}
            alt="Access Point  Wave 2 MU-MIMO AC 1200 Gigabit PoE"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col className="mb-3">
          <SubTitleProduct content="TOP-12AC service 24/7 365 Management via the AirCloud" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            El Airlive TOP-12AC es un punto de acceso multifunción con 1 puerto
            LAN de 1 Gbps y 1 puerto de entrada PoE WAN de 48 V de 1 Gbps. Es
            compatible con AirCloud y el controlador inalámbrico AirLive. El
            TOP-12AC admite los modos de operación FIT y FAT y funciona con el
            controlador inalámbrico AirLive y AirCloud. Por lo tanto, es ideal
            tanto para residentes como para empresas. Cuando se configura en
            modo de operación FAT, funciona como un punto de acceso
            independiente que puede ser bienvenido para el hogar o la oficina
            pequeña. El cliente SMB puede usar el controlador inalámbrico
            AirLive para administrar múltiples conjuntos de AP en una sola
            ubicación. Las grandes empresas y organizaciones pueden realizar una
            gestión 24/7 365 y acceder a un número ilimitado de puntos de acceso
            desde cualquier lugar en cualquier momento con una conexión a
            Internet a través de AirCloud.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={BottomTOP12AC}
            alt="Bottom of TOP12AC"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Roaming wifi ininterrumpido" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Además, el TOP-12AC es compatible con la tecnología inalámbrica de
            itinerancia sin interrupciones 802.11r / 802.11k / 802.11v y la
            transición rápida BBS (FT) para que los usuarios puedan mantenerse
            conectados y productivos mientras están en movimiento. Estas
            tecnologías trabajan juntas para permitir que los dispositivos
            cambien rápida y sin problemas entre puntos de acceso sin
            interrumpir la conexión ni requerir que el usuario se vuelva a
            conectar manualmente. Esta tecnología es particularmente útil en
            entornos donde los usuarios necesitan moverse, como en grandes
            oficinas, aeropuertos, centros comerciales y campus.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={SeamlessWirelessRoaming}
            alt="Seamless Wireless Roaming"
          />
        </Col>
      </Row>
    </Container>
  );
}

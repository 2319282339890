import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdministrationViewsNetworks.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import AutomaticView from "../../../../../../../../assets/imagesproduct/AirCloud/AutomaticView.jpg";
import ManagementWirelessNetworks from "../../../../../../../../assets/imagesproduct/AirCloud/ManagementWirelessNetworks.jpg";

export function AdministrationViewsNetworks() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Vista automática de la topología de red" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Descubre tus dispositivos de un solo vistazo" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Con la vista de la topología de red en forma automática, los
            administradores de red pueden ver de un vistazo qué dispositivos
            conectados a la red están causando cuellos de botella o caída. Por
            lo tanto, pueden identificar rápidamente los problemas de red y
            solucionar problemas. La vista de topología también puede ayudar a
            los administradores a planear y optimizar la red, identificando
            áreas de congestión, redundancia o ineficiencia.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AutomaticView}
            alt="Vista automática de la topología de red "
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Administración optimizada de las redes inalámbricas" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El uso de AirCloud para la configuración e implementación de puntos
            de acceso ofrece ventajas importantes sobre los métodos
            tradicionales de administración en sitio. Con la administración
            remota de puntos de acceso mediante AirCloud, se posibilita a los
            equipos de TI configurar y mantener grandes redes inalámbricas de
            manera más eficiente. Además, el uso de plantillas preconfiguradas
            puede ayudar a estandarizar la configuración del punto de acceso en
            toda la red, reduciendo el riesgo de errores o inconsistencias.
          </p>
          &nbsp;
          <p>
            En general, la configuración e implementación de puntos de acceso
            basados en la nube, junto con el uso de plantillas, pueden ayudar a
            las organizaciones a optimizar sus procesos de administración de
            redes inalámbricas y mejorar el rendimiento y la seguridad de la
            red.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={ManagementWirelessNetworks}
            alt="Administración optimizada de las redes inalámbricas"
          />
        </Col>
      </Row>
    </Container>
  );
}

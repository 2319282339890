import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightXponONU1GE() {
  const multicast = [
    {
      title: "Multidifusión:",
      detail: "Soporte IGMPv1/v2/Snooping",
    },
    {
      detail: "Vlan de multidifusión máxima 8",
    },
    {
      detail: "Grupo máximo de multidifusión 64",
    },
  ];
  const QoS = [
    {
      title: "QoS:",
      detail: "Soporte 4 colas",
    },
    {
      detail: "Soporte SP y WRR",
    },
    {
      detail: "Soporte 802.1P",
    },
  ];
  const L3 = [
    {
      title: "L3:",
      detail: "Soporte IPv4/IPv6",
    },
    {
      detail: "Soporte DHCP / PPPOE / IP estática",
    },
    {
      detail: "Soporte de ruta estática",
    },
    {
      detail: "Soporte NAT",
    },
  ];
  const Administration = [
    {
      title: "Administración:",
      detail: " Soporte TR069 / WEB / TELNET / CLI",
    },
    {
      detail: "Soporte ITUT984.x OMCI",
    },
    {
      detail: "Soporte CTC OAM 2.0 y 2.1",
    },
  ];
  const PowerSupply = [
    {
      title: "Fuente de alimentación",
      detail: " Voltaje de entrada: DC 12V, adaptador de corriente de 0.5A",
    },
  ];
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C a +60 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -30 °C a +70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 10% ~ 90%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto - Tamaño del producto:",
      detail: "Dimensiones del producto: 8.2x8.2x2.5 cm",
    },
    {
      detail: "Dimensiones del paquete: 10x10x10 cm",
    },
    {
      detail: "Peso del paquete: N.W. del producto: 0.085KG Producto G.W: 0.195KG",
    },
    {
      detail:
        "Contenido del paquete: ONU x 1, QIG x 1, adaptador de corriente x 1",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "Modelo: XPON ONU-1GE",
    },
    {
      detail: "Nombre: XPON 1GE XPON ONU, Puerto único"
    }
  ];
  return (
    <Container>
      <ul>
        {multicast.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {QoS.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {L3.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Administration.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerSupply.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

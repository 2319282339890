import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionXGF28 } from "./TitleSectionXGF28";
import { FeatureProductXGF28 } from "./FeatureProductXGF28";
import styles from "./SwitchL3XGF28.module.scss";

export function SwitchL3XGF28() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionXGF28 />
           <FeatureProductXGF28 />
        </Container>
    </div>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./ReplacementSection.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1 } from "@fortawesome/free-solid-svg-icons";
import { fa2 } from "@fortawesome/free-solid-svg-icons";
import { fa3 } from "@fortawesome/free-solid-svg-icons";
import { fa4 } from "@fortawesome/free-solid-svg-icons";

export function ReplacementSection() {
  return (
    <Container>
      <Row>
        <Col className="mt-4 mb-5">
          <HomeSubtitle title="Procedimiento para el reemplazo" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>
            AirLive ® Technology Corp. devolverá el reemplazo dentro de los 7 a
            21 días posteriores a la recepción de su producto RMA, siempre que
            siga el procedimiento de continuación:
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <div className={styles.subTitle}>
            <FontAwesomeIcon icon={fa1} className={styles.icon} />
            Informe la situación defectuosa y resumen del formulario de
            solicitud de RMA a AirLive ® Technology Corp.
          </div>
          <ul>
            <li>
              <p>
                Se debe completar todos los campos obligatorios en el formulario
                de solicitud de RMA.
              </p>
            </li>
            <li>
              <p>
                Se requiere la firma del cliente antes de que se emita el número
                de RMA.
              </p>
            </li>
          </ul>
          <div className={styles.subTitle}>
            <FontAwesomeIcon icon={fa2} className={styles.icon} />
            AirLive ® Technology Corp. emitirá un número de RMA dentro del siglo
            día día hábil después de recibir la solicitud de RMA.
          </div>
          <div className={styles.subTitle}>
            <FontAwesomeIcon icon={fa3} className={styles.icon} />
            Los clientes envían productos RMA a AirLive ® Technology Corp.
          </div>
          <ul>
            <li>
              <p>
                Los cargos de flete de productos RMA enviados a AirLive ®
                Technology Corp. deben pagarse por adelantado a menos que
                AirLive ® Technology Corp. lo apruebe. De lo contrario, el flete
                se cargará a la cuenta del remitente. AirLive ® Technology Corp.
                devolverá el producto de reemplazo con flete prepago solo cuando
                los productos están destinados al período de garantía.
              </p>
            </li>
            <li>
              <p>
                El número de RMA debe estar aclarado marcado en la etiqueta o
                caja de envío; de lo contrario, solo retrasará el proceso de
                nuestro servicio RMA.
              </p>
            </li>
            <li>
              <p>
                El contenido del paquete debe coincidir con la información del
                formulario RMA. De lo contrario, solo retrasará el proceso de
                nuestro servicio RMA.
              </p>
            </li>
            <li>
              <p>
                Si los productos no se devuelven completos como se describe
                anteriormente, se aplica cargas de reposición.
              </p>
            </li>
          </ul>
          <div className={styles.subTitle}>
            <FontAwesomeIcon icon={fa4} className={styles.icon} />
            AirLive ® Technology Corp. repara / reemplaza los productos RMA
            dentro de los 7 a 21 días posteriores a la recepción de los
            productos delegados según el proceso normal.
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import styles from "./AboutUs.module.scss";
import { IndustrialSolutionSection, ProfileSection, SidebarAboutUs } from "../../../components";

export function AboutUs() {
  return (
    <div className={styles.container}>
      <SidebarAboutUs />
      <Container>
        <ProfileSection />
        <IndustrialSolutionSection />
      </Container>
    </div>
  );
}

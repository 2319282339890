import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsXGS2404.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalspecificationsLeftXGS2404 } from "./TechnicalspecificationsLeftXGS2404";
import { TechnicalspecificationsRightXGS2404 } from "./TechnicalspecificationsRightXGS2404";

export function TechnicalspecificationsXGS2404() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3POE-XGS2404" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <TechnicalspecificationsLeftXGS2404 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <TechnicalspecificationsRightXGS2404 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./AircloudLoginDemo.module.scss";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import LoginAirCloudApp from "../../../../../../../../assets/imagesproduct/AirCloud/LoginAirCloudApp.jpg";
import PairingDevice from "../../../../../../../../assets/imagesproduct/AirCloud/PairingDevice.jpg";
import APConfiguration from "../../../../../../../../assets/imagesproduct/AirCloud/APConfiguration.jpg";
import SettingsApp from "../../../../../../../../assets/imagesproduct/AirCloud/SettingsApp.jpg";
import Visualizetopology from "../../../../../../../../assets/imagesproduct/AirCloud/Visualizetopology.jpg";
import SetAlarms from "../../../../../../../../assets/imagesproduct/AirCloud/SetAlarms.jpg";

export function AircloudLoginDemo() {
  const images = [
    {
      src: LoginAirCloudApp,
      alt: "Login AirCloud App",
      title: "Login a AirCloud App",
    },
    {
      src: PairingDevice,
      alt: "Pairing a device",
      title: "Vinculando un dispositivo",
    },
    {
      src: APConfiguration,
      alt: "AP Configuration",
      title: "Configuración de AP",
    },
    {
      src: SettingsApp,
      alt: "settings app",
      title: "Aplicación de configuración",
    },
    {
      src: Visualizetopology,
      alt: "Visualize the topology",
      title: "Visualice la topología",
    },
    {
      src: SetAlarms,
      alt: "set alarms",
      title: "Configure alarmas",
    },
  ];
  return (
    <Container>
      <Row>
        {images.map((item) => (
          <Col xs={12} md={4} className={styles.cardimage}>
            <img src={item.src} alt={item.alt} />
            <SubTitleProduct content={item.title} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

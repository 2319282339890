import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./CharacteristicsPOEGSH802120.module.scss";
import descriptionSwitchL2POEGSH802120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH802120/descriptionSwitchL2POEGSH802120.jpg";

export function CharacteristicsPOEGSH802120() {
    const Feature = [
        {
          detail: "Todos y cada uno de los 10 puertos, cada puerto soporta alta velocidad Gigabit",
        },
        {
          detail: "8 x puertos PoE 10/100/1000Mbps (hasta 30W)",
        },
        {
          detail: "Amplíe la red con 2 ranuras SFP que aceptan transceptores de fibra y van más allá de los límites de Ethernet",
        },
        {
          detail: "Alimentación PoE, soporta 802.3af/at PoE",
        },
        {
          detail: "Potencia interna 120W",
        },
        {
          detail: "Modo extendido: transmisión de hasta 250M en configuración de modo extendido mediante interruptor DIP de hardware (PoE Watchdog)",
        },
        {
          detail: "Protección contra sobretensiones; Protección ESD",
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
        </Row>
        <Row>
            <Col xs={7} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col xs={5} className="mb-5">
                <img
                    className={styles.image}
                    src={descriptionSwitchL2POEGSH802120}
                    alt="Description Switch"
                />
            </Col>
        </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleAWOD12ACiSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopAWOD12ACi from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD12ACi/TopAWOD12ACi.jpg";
import AirDualAC12Details from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/AirDualAC12Details.jpg";

export function TitleAWOD12ACiSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Access Point Wi-Fi AC1200 Dual Omni para exteriores" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AWOD-12ACi" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={TopAWOD12ACi}
            alt="Access Point Wi-Fi AWOD-12ACi"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="AP/repetidor de doble banda para exteriores de alto rendimiento con antena omnidireccional." />
          <p>
            El AWOD-12ACi es un AP/repetidor para exteriores de 1200 Mbps de
            alto rendimiento construido con los más altos estándares. Cuenta con
            una antena Omni MIMO de 5dBi y 500 mWatios de potencia para
            garantizar una recepción óptima. Adicionalmente, soporta
            alimentación entrante PoE 802.at IEEE de 48 V por el puerto WAN para
            permitir una larga distancia de cableado UTP.
          </p>
          &nbsp;
          <SubTitleProduct content="Diseño profesional para exteriores + protección interna contra rayos, adecuado para múltiples entornos hostiles" />
          <p>
            Encapsulado en una carcasa de plástico ABS para exteriores y
            componentes profesionales de protección contra rayos, proporciona
            IP65 a prueba de agua, a prueba de polvo, adecuado para múltiples
            entornos hostiles. Incluso puede operar eficientemente en ambientes
            extremadamente cálidos y fríos (-40 °C ~ + 55 °C).
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-40"
            src={AirDualAC12Details}
            alt="Access Point Wi-Fi "
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsL32TX2406() {
    const Feature = [
        {
          detail: "Características enriquecidas de capa 3",
        },
        {
          detail: "Las características totalmente L2 proporcionan una administración, seguridad y QoS más sencillas",
        },
        {
          detail: "Conmutación de protección de anillo Ethernet (ERPS) UIT-T G.8032",
        },
        {
          detail: "SNMP, WEB, CLI, SSH2.0, Telnet",
        },
        {
          detail: "Voz de la división VLAN",
        },
        {
          detail: "Ruta estática IPv4/IPv6 L3",
        },
        {
          detail: "Rutas OSPF enrutamiento dinámico",
        },
        {
          detail: "SFP+ 10Gbps Fibra de larga distancia",
        },
        {
          detail: "Compatibilidad con Voice Vlan",
        },
        {
          detail: "Visualización de estatuas claras, incluido el tráfico, la CPU y el estado por puerto",
        },
        {
          detail: "Web-UI para una fácil administración; CLI y script de comandos para la configuración avanzada; SNMP utilizado para la gestión de herramientas de red populares.",
        },
    ];
    const NotableSpecifications = [
        {
            detail: "24 x 10/100/1000/2500Mbps" 
        },
        {
            detail: "6 x 2.5/10G SFP+" 
        },
        {
            detail: "Características de capa 3" 
        },
        {
            detail: "Servidor/retransmisión DHCP L3" 
        },
        {
            detail: "L3 DHCP Snooping" 
        },
        {
            detail: "Ruta estática IPv4/IPv6 L3" 
        },
        {
            detail: "Rutas OSPF enrutamiento dinámico" 
        },
        {
            detail: "Protección contra sobretensiones 6KV, protección ESD 8KV" 
        },
        {
            detail: "Autenticación: 802.1x, AAA" 
        },
        {
            detail: "DHCP Snooping evita la instalación de enrutadores no autorizados" 
        },
    ];
  return (
    <Container>
        <Row>
            <Col xs={12} md={6} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
            <Col xs={12} md={6} className="mb-3">
                <HomeSubtitle title="Especificaciones principales" />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={5} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col xs={12} md={5} className="mb-5">
                <ul>
                    {NotableSpecifications.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}

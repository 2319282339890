import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH800120BT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH800120BT } from "../../POEGSH800120BT/FeatureProductPOEGSH800120BT/SpecificationsLeftPOEGSH800120BT";
import { SpecificationsRightPOEGSH800120BT } from "../../POEGSH800120BT/FeatureProductPOEGSH800120BT/SpecificationsRightPOEGSH800120BT";

export function FeatureProductPOEGSH800120BT() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH800-120-BT" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH800120BT content={"120w"} />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH800120BT />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

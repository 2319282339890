import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleGPONOLT121.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GPONOLT121Details from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT121/GPONOLT121Details.jpg";
import OLT121TriplaySolution from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT121/OLT121TriplaySolution.jpg";

export function TitleGPONOLT121() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="OLT GPON 1 Puerto PON de 10G SFP+" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="GPON OLT-121" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GPONOLT121Details}
            alt="OLT GPON 1 Puerto PON de 10G SFP+"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El GPON OLT-121 cumple con las normas ITU-T G.984/G.988, que son los
            estándares para el acceso a la red, lo cual garantiza que el
            dispositivo sea interoperable con otros equipos compatibles con GPON
            y se pueda utilizar en una variedad de entornos de red. Representa
            una solución versátil y eficaz para gestionar hasta 128 dispositivos
            ONU en una red de banda ancha de fibra. Su facilidad de instalación
            y mantenimiento lo convierten en una opción atractiva para
            implementaciones pequeñas y medianas.
          </p>
          &nbsp;
          <p>
            El GPON OLT-121 está diseñado para su uso en aplicaciones FTTH
            (fibra hasta el hogar) y pequeñas empresas y tiene un solo puerto
            PON con una relación de división de 1:128. Puede transmitir datos
            hasta una distancia de 20 km y tiene altas capacidades de ancho de
            banda de enlace ascendente y descendente de 1.25 Gbps / 2.5 Gbps,
            respectivamente.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={OLT121TriplaySolution}
            alt="GPON OLT-121 Tri-play Solution"
          />
        </Col>
        <Col>
          <p>
            <HomeSubtitle title="Aspectos resaltantes" />
            <ul>
              <li>Módulo óptico PON incorporado</li>
              <li>1 puerto PON, 1 * SFP / SFP + 10G, 2 * RJ45 1G</li>
              <li>Puerto ascendedente de 1.244Gbps, Descendente de 2.488Gbps</li>
              <li>El puerto PON admite hasta 128 ONU vía splitter de fibra</li>
            </ul>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

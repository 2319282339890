import { Link } from "react-router-dom";
import styles from "./ProductCard.module.scss";

export interface ProductCardProp {
  img: ImgObj;
  title: string;
  description?: string;
  path?: string;
  buttonCard?: ButtonObj;
}

export interface ImgObj {
  src: string;
  alt: string;
}
export interface ButtonObj {
  name: string;
  path: string;
}

export function ProductCard({
  img,
  title,
  description,
  buttonCard,
}: ProductCardProp) {
  return (
    <div className={styles.card}>
      <div className={styles.imgContainer}>
        <img className={styles.img} {...img} />
      </div>
      <span className={styles.title}>{title}</span>
      <p className={styles.description}>{description}</p>
      {buttonCard && (
        <Link to={buttonCard.path} className={styles.buttonCard}>
          {buttonCard.name}
        </Link>
      )}
    </div>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./Specifications.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftIGM642POE125G } from "./SpecificationsLeftIGM642POE125G/SpecificationsLeftIGM642POE125G";
import { SpecificationsRightIGM642POE125G } from "./SpecificationsRightIGM642POE125G/SpecificationsRightIGM642POE125G";

export function Specifications() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AirLive IGM-642POE-1.25G" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <SpecificationsLeftIGM642POE125G />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <SpecificationsRightIGM642POE125G />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

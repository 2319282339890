import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsAirDualAC12.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftAirDualAC12 } from "./SpecificationsLeftAirDualAC12";
import { SpecificationsRightAirDualAC12 } from "./SpecificationsRightAirDualAC12";

export function SpecificationsAirDualAC12() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AirDual-AC12" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftAirDualAC12 />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightAirDualAC12 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { Products } from "../Products";
import { SidebarProducts } from "../../SidebarProducts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./WirelessNetworksAirLiveCloud.module.scss";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { ProductCard } from "../../../../../ProductCard";
import AirCloud from "../../../../../../assets/images/AirCloud.jpg";
import AirLiveTOP18AX from "../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/AirLiveTOP18AX.jpg";
import TOP12AC from "../../../../../../assets/imagesproduct/AircloudProducts/TOP12AC/TOP12AC.jpg";
import AirLiveinWallU618AX from "../../../../../../assets/imagesproduct/AircloudProducts/AirLiveinWallU618AX/AirLiveinWallU618AX.jpg";
import AirLiveAirMax5XII from "../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AirLiveAirMax5XII.jpg";
import WLAN128GM from "../../../../../../assets/imagesproduct/AircloudProducts/WLAN128GM/WLAN128GM.jpg";
import WLAN64GMl from "../../../../../../assets/imagesproduct/AircloudProducts/WLAN64GMl/WLAN64GMl.jpg";
import W6184QAX from "../../../../../../assets/imagesproduct/AircloudProducts/W6184QAX/W6184QAX.jpg";
import AirDualAC12 from "../../../../../../assets/imagesproduct/AircloudProducts/AirDualAC12/AirDualAC12.jpg";
import AWOD12ACi from "../../../../../../assets/imagesproduct/AircloudProducts/AWOD12ACi/AWOD12ACi.jpg";
import AWOD30AXi from "../../../../../../assets/imagesproduct/AircloudProducts/AWOD30AXi/AWOD30AXi.jpg";
import ACTopAccessPoint from "../../../../../../assets/imagesproduct/AircloudProducts/ACTOP/ACTopAccessPoint.jpg";
import NTopAccessPoint from "../../../../../../assets/imagesproduct/AircloudProducts/NTOP/NTopAccessPoint.jpg";
import ExtensorInalámbricoN3 from "../../../../../../assets/imagesproduct/AircloudProducts/ExtensorInalámbricoN3/ExtensorInalámbricoN3.jpg";
import ExtensorInalámbricoN3A300Mbps from "../../../../../../assets/imagesproduct/AircloudProducts/ExtensorInalámbricoN3A300Mbps/ExtensorInalámbricoN3A300Mbps.jpg";
import USB18AX from "../../../../../../assets/imagesproduct/AircloudProducts/USB18AX/USB18AX.jpg";
import USBN15AadaptadorUSB from "../../../../../../assets/imagesproduct/AircloudProducts/USBN15AadaptadorUSB/USBN15AadaptadorUSB.jpg";
import Gateway4GLTEparaExterioes from "../../../../../../assets/imagesproduct/AircloudProducts/Gateway4GLTEparaExterioes/Gateway4GLTEparaExterioes.jpg";
import GatewayVH4GWLTEvehículos from "../../../../../../assets/imagesproduct/AircloudProducts/GatewayVH4GWLTEvehículos/GatewayVH4GWLTEvehículos.jpg";

export function WirelessNetworksAirLiveCloud() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const Platform = [
    {
      img: { src: AirCloud, alt: "AIRCLOUD" },
      title: "AIRCLOUD",
      description: "Plataforma de Control de dispositivos inalámbricos",
      button: {
        name: "VER MÁS",
        path: "../AirCloudPlatform",
      },
    },
  ];
  const WLANCentralController = [
    {
      img: { src: WLAN128GM, alt: "WLAN-128GM" },
      title: "WLAN-128GM",
      description: "Controlador Inalámbrico & Gateway",
      button: {
        name: "VER MÁS",
        path: "../WLAN128GM",
      },
    },
    {
      img: { src: WLAN64GMl, alt: "WLAN64GMl" },
      title: "WLAN-64GM",
      description: "Controlador Inalámbrico & Gateway Capacidad hasta 64 APs.",
      button: {
        name: "VER MÁS",
        path: "../WLAN64GMl",
      },
    },
  ];
  const Wifi6Routers = [
    {
      img: { src: W6184QAX, alt: "W6184QAX" },
      title: "W6-184QAX-CE",
      description: "Enrutador Mesh Wi-Fi 6 AX 1800 Gigabit",
      button: {
        name: "VER MÁS",
        path: "../W6184QAXCE",
      },
    },
  ];
  const InternalAccessPoints = [
    {
      img: { src: AirLiveTOP18AX, alt: "AirLiveTOP18AX" },
      title: "AirCloud TOP-18AX",
      description: "Access Point Wi-Fi 6 AX 1800Mbps",
      button: {
        name: "VER MÁS",
        path: "../AirCloudTOP18AX",
      },
    },
    {
      img: { src: TOP12AC, alt: "AIRCLOUD TOP12AC" },
      title: "AirCloud TOP-12AC",
      description: "Access Point  Wave 2 MU-MIMO AC 1200 Gigabit PoE",
      button: {
        name: "VER MÁS",
        path: "../AirCloudTOP12AC",
      },
    },
    {
      img: { src: AirLiveinWallU618AX, alt: "AirLiveinWallU618AX" },
      title: "AirCloud inWall-U618AX",
      description: "Access Point Wi-Fi 5Ghz N300 para exteriores",
      button: {
        name: "VER MÁS",
        path: "../AirLiveinWallU618AX",
      },
    },
  ];
  const ExternalAccessPoints = [
    {
      img: { src: AirLiveAirMax5XII, alt: "AIRCLOUD AirMax5XII" },
      title: "AirMax5XII",
      description: "Plataforma de Control de dispositivos inalámbricos",
      button: {
        name: "VER MÁS",
        path: "../AirCloudAirMax5XII",
      },
    },
    {
      img: { src: AirDualAC12, alt: "AirDual-AC12" },
      title: "AirDual-AC12",
      description: "Access Point Wi-Fi CPE AC1200 Dual",
      button: {
        name: "VER MÁS",
        path: "../AirDualAC12",
      },
    },
    {
      img: { src: AWOD12ACi, alt: "AWOD-12ACi" },
      title: "AWOD-12ACi",
      description: "Access Point Wi-Fi AC1200 Dual Omni",
      button: {
        name: "VER MÁS",
        path: "../AWOD12ACi",
      },
    },
    {
      img: { src: AWOD30AXi, alt: "AWOD-30AXi" },
      title: "AWOD-30AXi",
      description: "Acecss Point Wi-Fi 6 AX3000 Dual Omni",
      button: {
        name: "VER MÁS",
        path: "../AWOD30AXi",
      },
    },
  ];
  const Wifi5Routers = [
    {
      img: { src: ACTopAccessPoint, alt: "AC.TOP" },
      title: "AC.TOP",
      description: "11AC 1200Mbps Dual bands Ceiling, multi function modes",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: NTopAccessPoint, alt: "N.TOP" },
      title: "N.TOP",
      description: "11n 300Mbps Ceiling 48V PoE AP, multi function modes",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
  ];
  const WirelessAdapters = [
    {
      img: { src: ExtensorInalámbricoN3, alt: "Extensor Inalámbrico - N3" },
      title: "Extensor Inalámbrico - N3",
      description: "Presentamos nuestro extensor inalámbrico N300 con antena integrada",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: ExtensorInalámbricoN3A300Mbps, alt: "Extensor Inalámbrico N3A 300Mbps" },
      title: "Extensor Inalámbrico N3A",
      description: "Presentamos nuestro extensor  inalámbrico N300 con antena externa",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: USB18AX, alt: "USB18AX" },
      title: "USB - 18AX",
      description: "Se está desarrollando el adaptador AirLive USB-18AX WiFi 6 USB 3.0 WiFi 6",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: USBN15AadaptadorUSB, alt: "USB-N15A adaptadorUSB" },
      title: "USB-N15A adaptador USB",
      description: "Presentamos nuestro dongle inalámbrico USB 2.0 con antena externa de 2 dBi",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
  ];
  const GatewaysLTE4G = [
    {
      img: { src: Gateway4GLTEparaExterioes, alt: "Gateway4GLTEparaExterioes" },
      title: "Gateway 4G LTE Exterioes",
      description: "Puerta de enlace nivel industrial con punto de acceso Wi-Fi, VPN, Gigabit PoE 48V",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: GatewayVH4GWLTEvehículos, alt: "GatewayVH4GWLTEvehículos" },
      title: "Gateway VH-4GW LTE vehículos",
      description: "Puerta de enlace 4G LTE de nivel industrial con Wi-Fi, VPN, GPS, 9~36 VCC",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
  ];

  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <Products />
        <Row>
          <Col xs={12} className="mb-5">
            <HomeSubtitle title="Redes Inalámbricas- AirCloud:" />
          </Col>
          <Col xs={12} className="mb-3">
            <HomeSubtitle title="Plataforma AirCloud" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {Platform.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Access points Internos - AirCloud" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {InternalAccessPoints.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Access points Externos - AirCloud" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {ExternalAccessPoints.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Controlador Inteligente de Puntos de Acceso" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {WLANCentralController.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Enrutadores wifi 6 - Mesh - AirCloud" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {Wifi6Routers.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Access Points wifi 5 - Internos - AirCloud" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {Wifi5Routers.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  //TODO: en espera de info
                  // buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Adaptadores inalámbricos" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {WirelessAdapters.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  //TODO: en espera de info
                  // buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Gateways LTE 4G" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {GatewaysLTE4G.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  //TODO: en espera de info
                  // buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

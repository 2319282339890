import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./SolutionsSection.module.scss";

export function SolutionsSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>
            En Global Access WLL C.A. nos especializamos en la provisión de
            soluciones de IT & Telecom abarcando las siguientes áreas:
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Infraestructura de tecnología:" />
          <p className="mt-3">
            tales como servidores de las más importantes marcas tales como IBM,
            HP, Dell, así como unidades de respaldo y plataformas de firewall.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Soluciones de conectividad para entornos locales:" />
          <p className="mt-3">
            Tales como cuartos de cableado, centros de cómputos, adecuación de
            infraestructura de redes, tales como switches core, de borde y de
            acceso, capa 2 y capa 3, administrables, Gigabit y 10 Gigabit con
            interconexión de fibra óptica.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Soluciones de conectividad inalámbrica:" />
          <p className="mt-3">
            vía Access points y routers con administración en la nube tanto para
            ambientes de oficina, tiendas, centros comerciales, con acceso de
            clientes vía portal cautivo.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Soluciones de conectividad mediante plataformas de servicio FTTX:" />
          <p className="mt-3">
            como fibra al hogar y la oficina, con tecnologías GPON /XGPON.
          </p>&nbsp;
          <p>
            Centrales telefónicas, teléfonos IP y accesorios para Call centers.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.description}>
          <HomeSubtitle title="Sistemas de alimentación:" />
          <p className="mt-3">
            para asegurar la estabilidad y/o continuidad del servicio eléctrico
            mediante inversores, UPS, banco de baterías y paneles solares.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../HomeSubtitle";
import styles from "./ProfileSection.module.scss";
import bannerGlobalAccess from "../../../../../assets/images/bannerGlobalAccess.png";

export function ProfileSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-5 mt-4">
          <HomeSubtitle title="Empresa" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.imageBanner}
            src={bannerGlobalAccess}
            alt="Banner Global Access"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-5">
          <HomeSubtitle title="Global Access WLL C.A." />
        </Col>
        <Col xs={12} className="mb-3">
          <div className={styles.subTitle}>Perfil Corporativo:</div>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Fundada en 2006 con la misión satisfacer la demanda de atención
            esmerada y confiable del sector de Telecomunicaciones y redes,
            especialmente en el área de la mediana y pequeña empresa, la cual
            constituye un gran nicho mercado para ese momento desestimado por la
            competencia, quienes han enfocado sus labores de mercadeo en el
            sector corporativo.
          </p>
          &nbsp;
          <p>
            Global Access WLL C.A. asume valores de respeto y confiabilidad en
            su rol empresarial. Esta misión es la base de la relación con
            nuestros clientes, empleados y la comunidad. Nuestra filosofía de
            atención nos motiva a atender con el mismo entusiasmo y calidad de
            servicio los requerimientos de nuestros clientes de categoría SOHO y
            resellers independientes talo como a las respetadas corporaciones.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Con la finalidad de prestar una mejor y rápida atención a nuestros
            clientes, GLOBAL ACCESS WLL C.A. dispone de personal técnico en
            algunas ciudades del país donde ejecuta operaciones y/o existe
            demanda de nuestros clientes, bien sea motivado a ejecución de
            proyectos o contratos de mantenimiento.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

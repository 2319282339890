import { Container } from "react-bootstrap";
import styles from "./WarrantyRma.module.scss";
import { SidebarAboutUs } from "../../SidebarAboutUs";
import { WarrantySection, RmaSection, ReplacementSection, WarningSection } from "./components";

export function WarrantyRma() {
  return (
    <div className={styles.container}>
      <SidebarAboutUs />
      <Container>
        <WarrantySection />
        <RmaSection />
        <ReplacementSection />
        <WarningSection />
      </Container>
    </div>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./Specifications.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH2404M400 } from "./SpecificationsLeftPOEGSH2404M400/SpecificationsLeftPOEGSH2404M400";
import { SpecificationsRightPOEGSH2404M400 } from "./SpecificationsRightPOEGSH2404M400/SpecificationsRightPOEGSH2404M400";

export function Specifications() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH2404M-400" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH2404M400 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH2404M400 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

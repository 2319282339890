import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalLeftIGM642BT125G() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "4 x 90W PoE 10/100/1000 RJ45" 
        },
        {
            detail: "2 x puertos Gigabit SFP" 
        },
        {
            detail: "6 puertos totalmente" 
        },
        {
            detail: "1 x puerto de consola RJ45" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "Control de flujo IEEE802.3x y contrapresión" 
        },
        {
            detail: "IEEE802.3ad Método estándar para realizar vínculos, agregación" 
        },
        {
            detail: "Árbol de expansión IEEE802.1d (STP) " 
        },
        {
            detail: "IEEE802.1w Rapid Spanning Tree (RSTP)" 
        },
        {
            detail: "Árbol de expansión múltiple IEEE802.1s (MSTP)" 
        },
        {
            detail: "Clase de servicio IEEE802.1p (QoS / CoS) Priorización del tráfico (función de filtrado de multidifusión)" 
        },
        {
            detail: "Operación del puente VLAN IEEE802.1Q" 
        },
        {
            detail: "IEEE802.1x Control de acceso cliente/servidor y Autenticación" 
        },
        {
            detail: "IEEE802.3at/af PoE" 
        },
        {
            detail: "IEEE802.3az Ethernet de eficiencia energética" 
        },
        {
            detail: "IEEE802.1ab LLDP/ Protocolo de descubrimiento de capa de enlace de dispositivo " 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), SYS (sistema), 1-4 PoE" 
        },
        {
            detail: "1-4 Link y ACT, 5-6 Link y Act" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones de iluminación:",
            detail: "6KV (modo general), 2KV (modo diferencial), ESD15KV, Touch 8KV" 
        },
    ];
    const Mechanic = [
        {
            title:"Mecánico:",
            detail: "Caja de metal de alta resistencia IP40 monture para riel DIN35" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación y PoE:",
            detail: "Protocolo: IEEE802.3bt (90W), IEEE802.3at (30W), IEEE802.3af (15.4W)" 
        },
        {
            detail: "Puerto PoE: 4" 
        },
        {
            detail: "Salida de potencia PoE / Puerto PoE: máx. 90 vatios" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "Medio de fibra" 
        },
        {
            detail: "Fibra multimodo: 850nm, 1310nm" 
        },
        {
            detail: "Distancia de transmisión: 550m / 2km" 
        },
        {
            detail: "Fibra monomodo: 1310nm, 1550nm" 
        },
        {
            detail: "Distancia de transmisión 20/40/60/80/100/120Km" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Characteristics } from "./Characteristics";
import { Specifications } from "./Specifications";
import { Technicalspecifications } from "./Technicalspecifications";

export function FeatureProduct() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Characteristics"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Characteristics" title="Características">
                        <Characteristics />
                    </Tab>
                    <Tab eventKey="Specifications" title="Especificaciones">
                        <Specifications />
                    </Tab>
                    <Tab eventKey="Technicalspecifications" title="Especificaciones técnicas">
                        <Technicalspecifications />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}

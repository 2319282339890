import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsXGS4804() {
    const Feature = [
        {
          detail: "Características de capa 3",
        },
        {
          detail: "Las características totalmente L2 proporcionan una mayor capacidad de gestión, seguridad y QoS",
        },
        {
          detail: "ITU-T G.8032 Conmutación de protección de anillo Ethernet (ERPS)",
        },
        {
          detail: "SNMP, WEB, CLI, SSH2.0, Telnet",
        },
        {
          detail: "Voz de división de VLAN",
        },
        {
          detail: "Ruta estática IPv4/IPv6 L3",
        },
        {
          detail: "Enrutamiento dinámico de rutas OSPF",
        },
        {
          detail: "SFP+ 10 Gbps Fibra de larga distancia",
        },
        {
          detail: "Compatibilidad con Vlan de voz",
        },
        {
          detail: "Borrar la visualización de estatuas, incluido el tráfico, la CPU y el estado por puerto",
        },
        {
          detail: "Interfaz de usuario web para una fácil administración; CLI y Command Script para configuración avanzada; SNMP se utiliza para la gestión de herramientas de red populares.",
        },
    ];
    const NotableSpecifications = [
        {
            detail: "48 puertos de 10/100/1000" 
        },
        {
            detail: "4 x 10G SFP+" 
        },
        {
            detail: "Funcionalidades de capa 3" 
        },
        {
            detail: "Servidor/retransmisión DHCP L3" 
        },
        {
            detail: "Indagación DHCP L3" 
        },
        {
            detail: "Ruta estática IPv4/IPv6 L3" 
        },
        {
            detail: "Rutas OSPF enrutamiento dinámico" 
        },
        {
            detail: "Protección contra sobretensiones 6KV, protección ESD 8KV" 
        },
        {
            detail: "Autenticación: 802.1x, AAA" 
        },
        {
            detail: "DHCP Snooping evita la instalación de enrutadores no autorizados" 
        },
    ];
  return (
    <Container>
        <Row>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Especificaciones resaltantes" />
            </Col>
        </Row>
        <Row>
            <Col md={5} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col md={5} className="mb-5">
                <ul>
                    {NotableSpecifications.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftSFP10GT30m() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware:",
      detail: "Voltage de la Fuente de Alimentación: 3.13V~3.47V",
    },
    {
      detail: "Voltage típico de la Fuente: 3.3V",
    },
  ];
  const Standards = [
    {
      title: "Estándares:",
      detail: "IEEE 802.3ab:1000BASE-T Gigabit Ethernet",
    },
    {
      detail: "IEEE 802.3bz: 2.5G Ethernet",
    },
    {
      detail: "IEEE 802.3bz: 5G Ethernet",
    },
    {
      detail: "IEEE 802.3ae:10G Ethernet",
    },
    {
      detail: "Cumple con el Digital Diagnostic SFF-8472",
    },
    {
      detail: "Soporte DDM",
    },
    {
      detail: "Protección ESD",
    },
  ];
  const TotalSupplyCurrent  = [
    {
      title: "Corriente Total de suministro:",
      detail: "700mA",
    },
  ];
  const DataSpeed = [
    {
      title: "Velocidad de datos:",
      detail: "10 Gbps, 5 Gbps, 2.5 Gbps, 1 Gbps",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {TotalSupplyCurrent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DataSpeed.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

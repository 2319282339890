import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./TransceiverISFPLX125G20KMSMFBIDI.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionTransceiverISFPLXGMSMFBIDI } from "./TitleSectionTransceiverISFPLXGMSMFBIDI";
import { CharacteristicsISFPLX125G20KMSMFBIDI } from "./CharacteristicsISFPLX125G20KMSMFBIDI";
import { OperatingCharacteristicsIndustrialGradeFiber } from "../OperatingCharacteristicsIndustrialGradeFiber";
import { FeatureProductTransceiverISFPLXGMSMFBIDI } from "./FeatureProductTransceiverISFPLXGMSMFBIDI";
import ElectricalOpticalTransmitterISFPLX125G20KMSMFBIDI from "../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KMSMFBIDI/ElectricalOpticalTransmitterISFPLX125G20KMSMFBIDI.jpg";
import ElectricalOpticalReceiverISFPLX125G20KMSMFBIDI from "../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KMSMFBIDI/ElectricalOpticalReceiverISFPLX125G20KMSMFBIDI.jpg";

export function TransceiverISFPLXGMSMFBIDI() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionTransceiverISFPLXGMSMFBIDI />
        <CharacteristicsISFPLX125G20KMSMFBIDI />
        <OperatingCharacteristicsIndustrialGradeFiber image={ElectricalOpticalTransmitterISFPLX125G20KMSMFBIDI} image2={ElectricalOpticalReceiverISFPLX125G20KMSMFBIDI} />
        <FeatureProductTransceiverISFPLXGMSMFBIDI />
      </Container>
    </div>
  );
}

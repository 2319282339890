import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live5GTII.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live5GTII } from "./TitleSectionL2Live5GTII";
import { FeatureProductLive5GTII } from "./FeatureProductLive5GTII";

export function Live5GTII() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live5GTII />
           <FeatureProductLive5GTII />
        </Container>
    </div>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministrationTOP12AC.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import LiveStatusNetworkMonitoring from "../../../../../../../../assets/imagesproduct/AircloudProducts/TOP12AC/LiveStatusNetworkMonitoring.jpg";

export function AdvancedAdministrationTOP12AC() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Gestión avanzada " />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Operación flexible: administración central, administración en la nube, AP independiente" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive TOP-12AC puede ser un FIT AP o FAT AP. Puede funcionar
            como un punto de acceso independiente o puerta de enlace para
            aplicaciones domésticas o WLAN pequeñas. Para grandes empresas u
            organizaciones, el TOP-12AC es compatible con los controladores
            inalámbrico AirLive WLAN-128GM, WLAN-64GM y AirCloud para una
            gestión central y/o en la nube avanzada y eficiente.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Gestión central y en la nube 24/7 365 a través de AirCloud con un número ilimitado de AP para grandes empresas o acceso público" />
          <p>
            Control en la nube: el AirLive TOP-12AC se puede gestionar a través
            de AirCloud una vez que se ha añadido a la nube. Esto proporciona
            control remoto desde cualquier ubicación, lo que lo hace ventajoso
            para grandes redes de puntos de acceso, ya que el acceso local no es
            necesario y el soporte se puede proporcionar desde una oficina
            remota. Además, se pueden asignar múltiples niveles de control en la
            nube, que van desde los derechos de administrador hasta los derechos
            de espectador. Los puntos de acceso se pueden agregar o eliminar
            fácilmente en cualquier momento.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Gestión centralizada de multiples Access Points en modo FIT por medio del controlador inalámbrico" />
          <SubTitleProduct content="Fit mode" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            es una configuración para el punto de acceso TOP-12AC que permite
            que sea controlado por un controlador de acceso AirLive WLAN128GM y
            WLAN-64GM. El controlador WLAN administra todas las configuraciones
            inalámbricas, cifrado y otras funciones, sirviendo como el centro de
            control central para el punto de acceso TOP-12AC. El modo de ajuste
            es ideal para configuraciones de PYMES o grandes empresas donde se
            deben controlar varios puntos de acceso a la vez. Mediante el uso
            del modo Fit y un controlador de acceso WLAN, puede administrar
            todos los puntos de acceso conectados en una sola ubicación; El
            tiempo de configuración y la complejidad se pueden reducir
            considerablemente.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funciona también como AP independiente para el hogar o la oficina" />
          <SubTitleProduct content="Fat mode" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El punto de acceso TOP-12AC funciona como un dispositivo
            independiente con todas las funciones inalámbricas y de cifrado
            controladas dentro del propio AP. Este modo es más adecuado para
            configuraciones pequeñas como residenciales u oficinas con menos
            puntos de acceso. El modo Fat admite dos modos de operación: modo AP
            y modo AP Gateway. En el modo AP Gateway, el TOP-12AC funciona como
            una puerta de enlace y utiliza su puerto WAN que se conecta a un
            módem / enrutador a través de PPPoE, DHCP o IP estática. En el modo
            AP, el TOP-12AC se puede conectar a una red existente y trabajar sin
            las funciones del puerto WAN.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Estado en vivo y monitoreo de la red" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            Puede monitorear el uso de Internet inalámbrico mediante un gráfico
            de transmisión en vivo, proveer información en tiempo real sobre la
            actividad de su conexión, relevante para la toma de acciones sobre
            la administración de las redes.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={LiveStatusNetworkMonitoring}
            alt="Live status and network monitoring"
          />
        </Col>
      </Row>
    </Container>
  );
}

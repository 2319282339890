import { Container, Row, Col } from "react-bootstrap";
import styles from "./SectionFunctionalitiesTOP12AC.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function SectionFunctionalitiesTOP12AC() {
  const LeftFunctionalities = [
    {
      detail: "Compatible con Wi-Fi 6 11a/b/g/n/ac",
    },
    {
      detail: "Wave 2 MU-MIMO",
    },
    {
      detail: "2T2R 1200Mbps inalámbrico de alta velocidad (300Mbps + 900Mbps)",
    },
    {
      detail: "Canal 20/40/80Mhz",
    },
    {
      detail: "Bandas duales simultáneas, 2.4Ghz y 5Ghz",
    },
    {
      detail: "Itinerancia sin interrupciones",
    },
    {
      detail: "1 x 10/100/1000mbps WAN",
    },
    {
      detail: "1 x 10/100/1000mbps LAN",
    },
    {
      detail: "Entrada de alimentación PoE (WAN) 802.3af",
    },
  ];
  const RightFunctionalities = [
    {
      detail: "Modo de operación FIT/FAT",
    },
    {
      detail: "Modo de operación de AP y AP Gateway",
    },
    {
      detail: "Gestión en la nube compatible con AirCloud",
    },
    {
      detail: "Controlador inalámbrico AirLive compatible",
    },
    {
      detail: "Hasta 8 SSID múltiples",
    },
    {
      detail: "Soporte VLAN en modo AP",
    },
    {
      detail: "QoS en modo de puerta de enlace de AP",
    },
    {
      detail: "Filtrado de URL/MAC/IP",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidad" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

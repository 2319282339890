import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive2TX501.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import EasilyBuild from "../../../../../../../../assets/imagesproduct/SwitchL2/EasilyBuild.jpg";
import { SpecificationsLeftLive2TX501 } from "./SpecificationsLeftLive2TX501";
import { SpecificationsRightLive2TX501 } from "./SpecificationsRightLive2TX501/SpecificationsRightLive2TX501";

export function FeatureProductLive2TX501() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-2TX501" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive2TX501 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive2TX501 />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={EasilyBuild}
            alt="construye en forma sencilla la mejor red de alta velocidad"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
        <Col xs={12} className={styles.minTitle}>
          Todas las marcas comerciales, logotipos y nombres de marcas son
          propiedad de sus respectivos dueños.
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductTransceiverSFP10GT30m.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftSFP10GT30m } from "./SpecificationsLeftSFP10GT30m/SpecificationsLeftSFP10GT30m";
import { SpecificationsRightSFP10GT30m } from "./SpecificationsRightSFP10GT30m/SpecificationsRightSFP10GT30m";

export function FeatureProductTransceiverSFP10GT30m() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive MC-10GSFP+" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftSFP10GT30m />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightSFP10GT30m />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./TransceiverISFPLX125G20KM.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionTransceiverISFPLX125G20KM } from "./TitleSectionTransceiverISFPLX125G20KM";
import { CharacteristicsISFPLX125G20KM } from "./CharacteristicsISFPLX125G20KM";
import { OperatingCharacteristicsIndustrialGradeFiber } from "../OperatingCharacteristicsIndustrialGradeFiber";
import { FeatureProductTransceiverISFPLX125G20KM } from "./FeatureProductTransceiverISFPLX125G20KM";
import ElectricalOpticalTransmitter from "../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KM/ElectricalOpticalTransmitter.jpg";
import ElectricalOpticalReceiver from "../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KM/ElectricalOpticalReceiver.jpg";

export function TransceiverISFPLX125G20KM() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleSectionTransceiverISFPLX125G20KM />
        <CharacteristicsISFPLX125G20KM />
        <OperatingCharacteristicsIndustrialGradeFiber image={ElectricalOpticalTransmitter} image2={ElectricalOpticalReceiver} />
        <FeatureProductTransceiverISFPLX125G20KM />
      </Container>
    </div>
  );
}

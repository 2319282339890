import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live2TX501.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live2TX501 } from "./TitleSectionL2Live2TX501";
import { CharacteristicsLive2TX501 } from "./CharacteristicsLive2TX501";
import { FeatureProductLive2TX501 } from "./FeatureProductLive2TX501";

export function Live2TX501() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live2TX501 />
           <CharacteristicsLive2TX501 />
           <FeatureProductLive2TX501 />
        </Container>
    </div>
  );
}

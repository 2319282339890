import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live10TX800.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live10TX800 } from "./TitleSectionL2Live10TX800";
import { CharacteristicsLive10TX800 } from "./CharacteristicsLive10TX800";
import { FeatureProductLive10TX800 } from "./FeatureProductLive10TX800";

export function Live10TX800() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live10TX800 />
           <CharacteristicsLive10TX800 />
           <FeatureProductLive10TX800 />
        </Container>
    </div>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftMC1GSFP() {
  const HardwareDeviceInterface = [
    {
      title: "Hardware:",
      detail: "Voltage de entrada: Fuente externa de 5V 2A DC",
    },
    {
      detail: "Voltage típico de la Fuente: 3.3V",
    },
    {
      detail: "Interfaces: 1 x SFP 1000 Mbps",
    },
    {
      detail: "1 x RJ45 10/100/1000 Mbps",
    },
  ];
  const SupportedOpticalInterfaces  = [
    {
      title: "Interfaces ópticas soportadas:",
      detail: "Connector: SFP",
    },
    {
      detail: "Data rate: 1000Mbps",
    },
    {
      detail:
        "Duplex Mode: Full duplex",
    },
    {
      detail:
        "Fiber: Single or Multi mode auto-negotiable",
    },
    {
      detail:
        "- MM 50/125um, 62.5/125um",
    },
    {
      detail:
        "- SM: 9/125um",
    },
    {
      detail:
        "Distance: - Multi-Mode(MM): 550m/2Km",
    },
    {
      detail:
        "- Single-Mode(SM): 20/40/60/80/100/120Km",
    },
    {
      detail:
        "Wavelength: - MM 850nm, 1310nm; SM 1310nm, 1550nm",
    },
    {
      detail:
        "WDM: - Tx1310/Rx1550nm(A side), - Tx1550/Rx1310nm(B side)",
    },
    {
      detail:
        "WDM: - Tx1490/Rx1550nm(A side) - Tx1550/Rx1490nm(B side)",
    },
  ];
  const UTPInterfaces = [
    {
      title: "Interfaces UTP:",
      detail: "Connector: RJ-45",
    },
    {
      detail: "Data rate: 10/100/1000Mbps",
    },
    {
      detail: "Duplex Mode: Half/Full duplex",
    },
    {
      detail: "Cable: Cat5, Cat6",
    },
  ];
  const LedIndicators = [
    {
      title: "Indicadores LED:",
      detail: "Power, 100M, 1000M, LINK/ACT",
    },
  ];
  const CurrentConsumption = [
    {
      title: "Consumo de corriente:",
      detail: "Bajo consumo: Hasta 3 W",
    },
  ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SupportedOpticalInterfaces.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {UTPInterfaces.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CurrentConsumption.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

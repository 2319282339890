import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import GradeFiber from "../../../../../../../assets/imagesproduct/Accessories/GradeFiber.jpg";

export function OperatingCharacteristicsIndustrialGradeFiber({
  image,
  image2,
}: {
  image: string | undefined;
  image2: string | undefined;
}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Características de funcionamiento del transmisor Óptica y eléctrica" />
          <img className="w-100" src={image} alt="image" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Característica de funcionamiento del receptor Óptica, eléctrica" />
          <img className="w-100" src={image2} alt="image" />
        </Col>
      </Row>
      <Row>
        <SubTitleProduct content="Fibra de nivel industrial para larga distancia de hasta 20 km, 1,25 Gbps" />
        <Col md={6} className="mb-5">
          <p>
            Amplíe aún más la red con los conmutadores de red AirLive y los
            módulos de fibra. Utilice los módulos SFP de nivel industrial en los
            puertos SFP, que también admiten DDM y ofrecen más información sobre
            la conexión de fibra y facilitan la búsqueda de fallas o malas
            conexiones.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className="w-100"
            src={GradeFiber}
            alt="Industrial Grade Fiber"
          />
        </Col>
      </Row>
    </Container>
  );
}

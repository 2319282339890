import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive5GTII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive5GTII } from "./SpecificationsLeftLive5GTII";
import { SpecificationsRightLive5GTII } from "./SpecificationsRightLive5GTII/SpecificationsRightLive5GTII";

export function FeatureProductLive5GTII() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-5GT II" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive5GTII />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive5GTII />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./Live8GTII.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2Live8GTII } from "./TitleSectionL2Live8GTII";
import { FeatureProductLive8GTII } from "./FeatureProductLive8GTII";

export function Live8GTII() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2Live8GTII />
           <FeatureProductLive8GTII />
        </Container>
    </div>
  );
}

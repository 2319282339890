import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleFeaturesSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import TopAirCloudTOP18AX from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/TopAirCloudTOP18AX.jpg";
import BottomTOP18AX from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/BottomTOP18AX.jpg";
import SeamlessWirelessRoaming from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveTOP18AX/SeamlessWirelessRoaming.jpg";

export function TitleFeaturesSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Access Point Wi-Fi 6 AX 1800Mbps Gigabit PoE Gestión centralizada con AirCloud + roaming " />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AirCloud TOP-18AX" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={TopAirCloudTOP18AX}
            alt="Access Point Wi-Fi 6 AX 1800Mbps Gigabit PoE"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Servicio del TOP-18AX con gestión 24/7 x 365 via AirCloud" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            El Airlive TOP-18AX es un avanzado punto de acceso de múltiples
            funciones de banda dual de 1800Mbps compatible con Wi-Fi 6 que
            cuenta con 1 puerto LAN de 1 Gbps y 1 puerto de entrada PoE WAN de
            48 V de 1 Gbps. Es compatible con AirCloud y el controlador
            inalámbrico AirLive. Las grandes empresas u organizaciones pueden
            administrar de forma centralizada un gran número de puntos de acceso
            en una sola ubicación con el controlador inalámbrico. O haga la
            gestión y el acceso desde cualquier lugar en cualquier momento con
            una conexión a Internet a través de AirCloud, plataforma basada en
            la nube. Además, puede funcionar como un AP independiente en el
            hogar o la oficina cuando se configura en modo de operación FAT.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={BottomTOP18AX}
            alt="Bottom of TOP18AX"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Roaming inalámbrico ininterrunpido" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            Además, el TOP-18AX es compatible con la tecnología inalámbrica de
            itinerancia inalámbrica 802.11r/802.11k/802.11v y la transición
            rápida BSS (FT) para que los usuarios puedan mantenerse conectados y
            productivos mientras están en movimiento. Estas tecnologías trabajan
            juntas para permitir que los dispositivos cambien rápida y sin
            problemas entre puntos de acceso sin interrumpir la conexión ni
            requerir que el usuario se vuelva a conectar manualmente. Esta
            tecnología es particularmente útil en entornos donde los usuarios
            necesitan moverse, como en grandes oficinas, hoteles, aeropuertos,
            centros comerciales y campus.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={SeamlessWirelessRoaming}
            alt="Seamless Wireless Roaming"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";

export function PoETechnology({
  content,
  image,
  image2,
}: {
  content: string | undefined;
  image: string | undefined;
  image2: string | undefined;
}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Tecnología eficiente PoE BT de alta potencia" />
          <p>
            Conecte varios dispositivos PoE al switch, los puertos 1 y 2 del
            switch pueden alimentar dispositivos de hasta 60W. Esta capacidad de
            60W puede ser utilizado por cámaras IP de alta potencia o por PoE
            Led, estaciones de clientes de bajo consumo. Dándole mucha más
            flexibilidad en el uso, ya que ya no está restringido a solo 30W.
            Otros dispositivos, como puntos de acceso o cámaras IP normales,
            pueden conectarse a cualquiera de los puertos PoE del switch y
            encender hasta un máximo de 30 W (at).
          </p>
        </Col>
        <Col xs={8} className="mb-5 text-align-center">
          <img className="w-100" src={image} alt="image" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo VLAN y Extendido" />
          <p>
            Este switch {content} se puede configurar en modo Extender para
            alcanzar una distancia de hasta 250 m (limitado a 10Mbps), esto
            puede ser muy útil en lugares donde es difícil obtener energía para
            encender su dispositivo PD. El modo Extender lo hace más fácil. El
            modo Extender es solo para el puerto 3 ~ 6 en el switch y cuando
            está habilitado la velocidad de transmisión será de 10Mbps. Modo
            VLAN: Cuando el modo de aislamiento de puertos está habilitado, los
            puertos PoE (1 ~ 6) no pueden comunicarse entre sí y solo pueden
            comunicarse con el puerto UP-Link 7/8.
          </p>
          &nbsp;
          <SubTitleProduct content="Tecnología eficiente de protección PoE Watch dog" />
          <p>
            El switch {content} es compatible con PoE Watchdog cuando el
            Dip-Switch cambia al modo VLAN o al modo Extender. Después de
            habilitar la función PoE Watchdog, el conmutador {content}
            funciona como un guardia de seguridad virtual. Cuando el puerto POE
            se alimenta continuamente pero no hay tráfico, se activará el perro
            guardián POE. Después de 2 minutos de detección, la fuente de
            alimentación se detendrá y luego se encenderá. El perro guardián PoE
            es particularmente útil para las cámaras. Por ejemplo, cuando el
            interruptor está encendido, reconocerá la cámara frontal las 24
            horas del día, los 7 días de la semana. Si no hay tráfico de datos
            de la cámara, la cámara se considera congelada y el perro guardián
            PoE se utiliza para reiniciar la cámara frontal para resolver el
            problema. *Nota: Función disponible en VLAN o modo extendido.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img className="w-100" src={image2} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleW6184QAXCESection } from "./TitleW6184QAXCESection";
import { SpecificationsW6184QAXCE } from "./SpecificationsW6184QAXCE";
import styles from "./W6184QAXCE.module.scss";

export function W6184QAXCE() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleW6184QAXCESection />
           <SpecificationsW6184QAXCE />
        </Container>
    </div>
  );
}

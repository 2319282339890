import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SpecificationsL32TX2406 } from "./SpecificationsL32TX2406";
import { TechnicalspecificationsL32TX2406 } from "./TechnicalspecificationsL32TX2406";

export function FeatureProductL32TX2406() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Specifications"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Specifications" title="Especificaciones técnicas">
                        <SpecificationsL32TX2406 />
                    </Tab>
                    <Tab eventKey="Technicalspecifications" title="Especificaciones técnicas">
                        <TechnicalspecificationsL32TX2406 />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsW6184QAXCE.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftW6184QAXCE } from "./SpecificationsLeftW6184QAXCE";
import { SpecificationsRightW6184QAXCE } from "./SpecificationsRightW6184QAXCE";

export function SpecificationsW6184QAXCE() {
  return (
    <Container>
      <Row>
        <Col xs={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={6} className="mb-3">
          <HomeSubtitle title="W6-184QAX AX1800 Gigabit Router (CE)" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftW6184QAXCE />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightW6184QAXCE />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import VoltageInputs from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/VoltageInputs.jpg";
import WideTemperature from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/WideTemperature.jpg";
import IndustrialApplication from "../../../../../../../../assets/imagesproduct/IndustrialSwitch/SwitchIGM642BT125G/IndustrialApplication.jpg";

export function WideTemperatureVoltageInputs() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Entradas de Voltage DC Dual" />
          <p>
            El IGM-642BT-1.25G admite 2 entradas de alimentación de CC para
            redundancia de alimentación. Eso mejora en gran medida la
            confiabilidad y el tiempo de actividad de una red sensible en un
            entorno hostil. Cuando falla una de las entradas de alimentación de
            DC, la segunda entrada continuará encendiendo el switch. Éste admite
            una entrada de DC 48 ~ 55V. El usuario puede definir una política
            para la operación de video / datos de voz en alta prioridad, para
            garantizar que su reunión de video no se retrase a causa de alguna
            congestión de red.
          </p>
        </Col>
        <Col xs={6} className="mb-5 text-align-center">
          <img className="w-100" src={VoltageInputs} alt="image" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Amplia cobertura de temperatura de -40°C ~ 85°C" />
          <p>
            El IGM-642BT-1.25G puede funcionar entre -40°C ~ 85°C. Y cada puerto
            POE tiene protección contra sobretensiones de 6KV. Es la solución
            ideal para aplicaciones que funcionan en un entorno hash y requieren
            una red estable y alimentación PoE, como estacionamientos o
            vigilancia IP. El IGM-642BT-1.25G tiene una carcasa metálica robusta
            de alta resistencia y tamaño compacto con clasificación IP40 y una
            conexión de riel DIN35, lo que lo hace ideal para uso en red en
            industrias pesadas o fábricas. Se puede colocar en casi cualquier
            entorno hostil.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img className="w-100" src={WideTemperature} alt="img" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Aplicación Industrial para ambientes hostiles" />
          <p>
            El switch industrial AirLive IGM-642BT-1.25G cumple con las demandas
            de alto estándar de proporcionar conectividad de red confiable,
            capaz de soportar entornos industriales hostiles y de manera
            rentable.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img className="w-100" src={IndustrialApplication} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}

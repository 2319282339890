import { Container, Row, Col } from "react-bootstrap";
import styles from "./AirCloud.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import ImageAirCloud from "../../../../../../../../assets/imagesproduct/AirCloud/ImageAirCloud.jpg";
import DevicesSupportedAirCloud from "../../../../../../../../assets/imagesproduct/AirCloud/DevicesSupportedAirCloud.jpg";

export function AirCloud() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-3">
          <HomeSubtitle title="Plataforma de Control de dispositivos inalámbricos" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AIRCLOUD" />
        </Col>
        <Col xs={12} className="mb-5">
          <img className="w-100" src={ImageAirCloud} alt="AirCloud" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            AirCloud es una plataforma en la nube para la instalación y
            administración en vivo de dispositivos inalámbricos de la marca
            AirLive. Está basado en un poderoso software de control inalámbrico
            basado en la nube desarrollado por AirLive, especialmente diseñado
            para administrar la nueva gama de puntos de acceso inalámbricos de
            AirLive, incluidos los modelos de techo, pared y exterior, así como
            sus controladores inalámbricos de hardware.
          </p>
          &nbsp;
          <p>
            AirCloud ofrece una plataforma centralizada para que los
            administradores incorporen, administren y monitoreen múltiples
            puntos de acceso inalámbricos desde una sola ubicación, sin la
            necesidad de hardware o software local. Se puede acceder a AirCloud
            desde cualquier lugar con conexión a Internet, lo que lo hace ideal
            para la gestión remota de redes distribuidas, especialmente para
            organizaciones con múltiples ubicaciones o fuerzas de trabajo
            distribuidas.
          </p>
          &nbsp;
        </Col>
        <Col md={5} className="mb-5">
          <p>
            Características principales de AirCloud:
            <ul className={styles.listItem}>
              <li>✓ Gestión centralizada</li>
              <li>✓ Configuración automatizada</li>
              <li>✓ Monitoreo de red</li>
              <li>✓ Acceso de invitados</li>
              <li>✓ Características de seguridad</li>
            </ul>
          </p>
        </Col>
        <Col md={7} className="mb-5">
          <div>
            <video width="100%" height="100%" className={styles.video} controls>
              <source
                src="https://www.hiottech.com/assets/Aircloud.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </Col>
        <Col className="mb-3">
          <SubTitleProduct content="Dispositivos soportados por AirCloud" />
        </Col>
        <Col xs={12} className="mb-3">
          <p>
            Todos los dispositivos, Access points de techo, de pared, externos y
            controladores de la línea Aircloud.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={DevicesSupportedAirCloud}
            alt="Devices Supported by AirCloud"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import EasilyBuild from "../../../../../../../../assets/imagesproduct/SwitchL2/EasilyBuild.jpg";
import HighVideoStreaming from "../../../../../../../../assets/imagesproduct/SwitchL2/Live10TX800/HighVideoStreaming.jpg";

export function CharacteristicsLive10TX800() {
  const Feature = [
    {
      detail: "8 puertos RJ45",
    },
    {
      detail: "100/1000M/2.5G",
    },
    {
      detail: "5G/10G",
    },
    {
      detail:
        "Todos los puertos a alta velocidad Multi Gigabit, negociación automática",
    },
    {
      detail: "Configuración Zero",
    },
    {
      detail: "Tamaño compacto para conectividad de red doméstica o SMB",
    },
    {
      detail: "Protección ESD",
    },
    {
      detail: "Funciona con cables Cat6e/6a o ascendentes",
    },
  ];
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Perfecto para aplicaciones Multi Gigabit  1G/2.5G/5G" />
          <p>
            Con el switch multigiga AirLive 10G, puede conectarse a una variedad
            de dispositivos habilitados para 10 GbE, como NAS | NVR | PC |
            Servidor, los puertos RJ45 son autoadaptables y también pueden
            funcionar a 100/1000M / 2.5G / 5G, para disfrutar de una experiencia
            más rápida y manejar aplicaciones que consumen mucho ancho de banda.
          </p>
        </Col>
        <Col md={6} className="mb-5 text-align-center">
          <img className="w-100" src={EasilyBuild} alt="image" />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="High Video Streaming" />
          <p>
            Además, un mayor ancho de banda mejora la calidad de reproducción de
            videos 4K / 8K, evita el retraso para los juegos, suaviza la
            velocidad al descargar o cargar datos grandes, aumenta la velocidad
            de transferencia de datos al realizar copias de seguridad en una
            unidad NAS.
          </p>
          <img
            className="W-100"
            src={HighVideoStreaming}
            alt="Switch Multi Gigabit 2.5Gbps Base-T, plug & play Incluye 5 puertos RJ45"
          />
        </Col>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Facilidades y especificaciones " />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductPOEGSH822120.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH822120 } from "./SpecificationsLeftPOEGSH822120";
import { SpecificationsRightPOEGSH822120 } from "./SpecificationsRightPOEGSH822120/SpecificationsRightPOEGSH822120";

export function FeatureProductPOEGSH822120() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH822-120" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH822120 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH822120 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

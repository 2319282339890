import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftU618AX() {
  const MainChip = [
    {
      title: "Chip principal:",
      detail: "MTK, Dual Core MT7621DAT",
    },
  ];
  const Flash = [
    {
      title: "Flash:",
      detail: "16MB",
    },
  ];
  const RAM = [
    {
      title: "RAM:",
      detail: " 256MB",
    },
  ];
  const Ethernet = [
    {
      title: "Ethernet (LAN):",
      detail: "4 x RJ45 10/100/1000mbps",
    },
  ];
  const WANPoE = [
    {
      title: "WAN (PoE):",
      detail: "1x RJ-45 10/100/1000mbps",
    },
  ];
  const WiFi = [
    {
      title: "WiFi:",
      detail:
        "802.11b / g / n / n / ac / ax 2T2R (2.4Ghz), 802.11a / n / ac / ax 2T2R (5Ghz)",
    },
  ];
  const Button = [
    {
      title: "Botón:",
      detail: "un botón para restablecer",
    },
  ];
  const PowerInput = [
    {
      title: "Entrada de alimentación:",
      detail: "1 x 48V (802.3af PoE)",
    },
  ];
  const connector = [
    {
      title: "Conector de antena:",
      detail: "Antena inalámbrica interna 2/4dBi (2.4/5Ghz) MIMO",
    },
  ];
  const WAN = [
    {
      title: "WAN:",
      detail: "PPPoE, DHCP, IP estática, (puente (modo AP))",
    },
  ];
  const StandardWifi = [
    {
      title: "Wi-Fi Estándar:",
      detail: "IEEE 802.11a / b / g / n / ac / ax, 2x2, canales 20/40/80 MHz",
    },
  ];
  const functions = [
    {
      title: "Funciones:",
      detail:
        "Multi-SSID 8 (4 por radio), SSID oculto, aislamiento SSID, dirección de banda, potencia de RF ajustable, tiempo de encendido / apagado de Wi-Fi para ahorrar energía",
    },
  ];
  const Security = [
    {
      title: "Seguridad:",
      detail: "WPA/WPA2PSK-TKIPAES, WPA3PSK-TKIPAES",
    },
  ];
  const Modulation = [
    {
      title: "Modulación:",
      detail: "OFDMA, 1024QAM",
    },
  ];
  const ContinuousRoaming = [
    {
      title: "Itinerancia continua:",
      detail: "802.11k, 802.11v, 802.11r",
    },
  ];
  const AdvancedWifi = [
    {
      title: "Wi-Fi Avanzado:",
      detail: "aislamiento de clientes inalámbricos",
    },
  ];
  const WirelessChannel = [
    {
      title: "Canal inalámbrico",
      detail:
        "2.4G: 1 ~ 13 (canal CE) (depende de la región), 5.8G: 36 ~ 48, 36 ~ 64,100 ~ 140,149 ~ 165 (depende de la región)",
    },
  ];
  const WirelessSpeed = [
    {
      title: "Velocidad inalámbrica:",
      detail: "1800Mbps Wireless High Speed (600Mbps + 1200Mbps)",
    },
  ];
  const WirelessTXPower2_4G = [
    {
      title: "Alimentación TX inalámbrica - 2.4G:",
      detail:
        "≤20dbm, 5.8G: ≤18dbm Tecnología de itinerancia inalámbrica sin interrupciones una combinación de tecnologías, incluyendo Fast BSS Transition (FT), 802.11r y 802.11k. Estas tecnologías trabajan juntas para permitir que los dispositivos cambien rápida y sin problemas entre puntos de acceso sin interrumpir la conexión ni requerir que el usuario se vuelva a conectar manualmente.",
    },
  ];
  const StandardWifi802_11r = [
    {
      title: "802.11r:",
      detail:
        "facilita los traspasos rápidos mediante la autenticación previa de dispositivos con el siguiente punto de acceso. ",
    },
  ];
  const StandardWifi802_11k = [
    {
      title: "802.11k:",
      detail:
        "proporciona una mejor información de señal para las decisiones del dispositivo en qué punto de acceso conectarse.",
    },
  ];
  const StandardWifi802_11v = [
    {
      title: "802.11v:",
      detail:
        "ofrece información de QoS para la selección de dispositivos de los mejores punto de acceso en función de sus necesidades.",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Componentes del dispositivo" />
      </Col>
      <ul>
        {MainChip.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Flash.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RAM.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Ethernet.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WANPoE.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WiFi.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Button.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {connector.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WAN.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {functions.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Security.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Modulation.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {ContinuousRoaming.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {AdvancedWifi.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessChannel.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessSpeed.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessTXPower2_4G.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi802_11r.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi802_11k.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardWifi802_11v.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}

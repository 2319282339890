import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./AWOD30AXi.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleAWOD30AXiSection } from "./TitleAWOD30AXiSection";
import { SectionFunctionalitiesAWOD30AXi } from "./SectionFunctionalitiesAWOD30AXi";
import { AdvancedAdministrationAWOD30AXi } from "./AdvancedAdministrationAWOD30AXi";
import { SpecificationsAWOD30AXi } from "./SpecificationsAWOD30AXi";

export function AWOD30AXi() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <TitleAWOD30AXiSection />
        <SectionFunctionalitiesAWOD30AXi />
        <AdvancedAdministrationAWOD30AXi />
        <SpecificationsAWOD30AXi />
      </Container>
    </div>
  );
}

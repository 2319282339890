import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./WarrantySection.module.scss";

export function WarrantySection() {
  return (
    <Container>
      <Row>
        <Col className="mt-4 mb-5">
          <HomeSubtitle title="Garantía" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            <span className={styles.subTitle}>AirLive ® Technology Corp.</span>
            Garantiza que cada producto AirLive está libre de defectos físicos
            en materiales y mano de obra cuando se utiliza correctamente para la
            Guía del usuario. Si el producto parece defectuoso durante el
            período de garantía, el Soporte Técnico de AirLive ® Technology
            Corp. está listo para ayudar. Cuando sea necesario devolver el
            producto, se debe admitir el comprobante de compra.
          </p>
          &nbsp;
          <p>
            La provisión de garantía se realiza exclusivamente al aliado de
            negocios, canal de ventas o distribuidor que realizó la compra del
            producto a AirLive Technology Corp. Son éstos los que ejecutan la
            garantía en cada país cada vez que sea necesario, de acuerdo a las
            condiciones de ventas particulares y convenimientos con el cliente
            final.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <div className={styles.subTitle}>Período de garantía</div>
          <p>
            El período de garantía ofrecido a través de los canales de ventas y
            distribuidores es de un (1) año contra defectos de fabricación,
            dependiendo del productos y contrato entre AirLive Technology y el
            distribuidor autorizado en cada país.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

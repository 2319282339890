import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalLeftSNMPGSH2404M() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "24 x 10/100/1000 RJ-45" 
        },
        {
            detail: "4 x puertos Gigabit SFP" 
        },
        {
            detail: "28 puertos totalmente" 
        },
        {
            detail: "1 x puerto de consola RJ45" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3u 100Base-TX / 100Base-FX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-T" 
        },
        {
            detail: "IEEE802.3z Gigabit SX/LX (fibra óptica)" 
        },
        {
            detail: "Control de flujo IEEE802.3x y contrapresión" 
        },
        {
            detail: "IEEE802.3ad Método estándar para realizar vínculos, agregación" 
        },
        {
            detail: "Árbol de expansión IEEE802.1d (STP) " 
        },
        {
            detail: "IEEE802.1w Rapid Spanning Tree (RSTP)" 
        },
        {
            detail: "Árbol de expansión múltiple IEEE802.1s (MSTP)" 
        },
        {
            detail: "Tráfico de clase de servicio (QoS / CoS) IEEE802.1p" 
        },
        {
            detail: "Priorización (función de filtrado de multidifusión)" 
        },
        {
            detail: "Operación del puente VLAN IEEE802.1Q" 
        },
        {
            detail: "IEEE802.1x Control de acceso cliente/servidor y Autenticación" 
        },
        {
            detail: "IEEE802.3az Ethernet de eficiencia energética" 
        },
        {
            detail: "Protocolo de descubrimiento de capa de enlace de dispositivo IEEE802.1ab LLDP/LLDP-MED" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), SYS (sistema), enlace, ACT" 
        },
    ];
    const SurgeProtection = [
        {
            title:"Protección contra sobretensiones:",
            detail: "6KV (modo general), 4KV (modo diferencial), ESD15KV" 
        },
    ];
    const Mechanic = [
        {
            title:"Mecánico:",
            detail: "Metal sólido de 19, 1U montable en rack, sin ventilador" 
        },
    ];
    const PowerInputPoe = [
        {
            title:"Alimentación Entrada de energía:",
            detail: "AC100 ~ 240VAC" 
        },
        {
            detail: "potencia total 36W" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "almacenar y reenviar" 
        },
        {
            detail: "Multi-mode Fiber: 850/1310nm  (0-2Km), Single-mode Fiber: 1310/1550/1490nm (0-120Km)" 
        },
        {
            detail: "Capacidad del switch: Back-plan de hasta 56 Gbps" 
        },
        {
            detail: "Capacidad de conmutar paquetes; 41.66Mpps" 
        },
        {
            detail: "Dirección MAC: 8K" 
        },
        {
            detail: "Tramas jumbo: 9.6Kbytes" 
        },
        {
            detail: "Búfer de paquetes: 4Mb" 
        },
        {
            detail: "Memoria flash: 16MB" 
        },
        {
            detail: "DDR SDRAM: 128 MB" 
        },
        {
            detail: "MTBF: 100000" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SurgeProtection.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PowerInputPoe.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

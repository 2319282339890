import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsL32TX2406.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { TechnicalLeftL32TX2406 } from "./TechnicalLeftL32TX2406";
import { TechnicalRightL32TX2406 } from "./TechnicalRightL32TX2406";

export function TechnicalspecificationsL32TX2406() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3-2TX2406" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <TechnicalLeftL32TX2406 />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <TechnicalRightL32TX2406 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalRightIGM642BT125G() {
  const SwitchingArchitecture = [
    {
      title: "Arquitectura de conmutación:",
      detail: "Almacenar y reenviar",
    },
    {
      detail: "Capacidad del switch: Back-plan de hasta 12 Gbps",
    },
    {
      detail: "Capacidad de conmutar paquetes; 8.928Mpps",
    },
    {
      detail: "Dirección MAC: 8K",
    },
    {
      detail: "Tramas jumbo: 9.6Kbytes",
    },
    {
      detail: "Búfer de paquetes: 4Mb",
    },
    {
      detail: "Memoria flash: 16MB",
    },
    {
      detail: "DDR SDRAM: 128 MB",
    },
  ];
  const L2Management = [
    {
      title: "Función de Gestión L2",
      detail: "Gestión de Puertos: Habilitar / Deshabilitar puerto, Ajuste de velocidad, dúplex",
    },
    {
      detail: "MTU, Control de flujo, Comprobación de información portuaria",
    },
  ];
  const PortMirroring = [
    {
      title: "Duplicación de puertos: ",
      detail: "Admite la duplicación de puertos laterales",
    },
    {
      detail:
        "Límite de velocidad del puerto: Administración del ancho de banda de entrada/salida, basada en puertos",
    },
  ];
  const PortIsolation = [
    {
      title: "Aislamiento de puertos: ",
      detail:
        "Admite el aislamiento del puerto de enlace descendente ",
    },
    {
      detail:
        "puede comunicarse con el puerto de enlace ascendente",
    },
  ];
  const StormSuppression = [
    {
      title: "Supresión de tormentas:",
      detail: "Soporta unidifusión desconocida, multidifusión, multidifusión desconocida",
    },
    {
      detail: "Supresión de tormentas de tipo broadcast",
    },
    {
      detail: "Supresión de tormentas basada en la regulación del ancho de banda y el filtrado de tormentas",
    },
  ];
  const LinkAggregation = [
    {
      title: "Agregación de enlaces:",
      detail:
        "Admite agregación manual estática",
    },
    {
      detail: "Soporte de agregación dinámica LACP",
    },
  ];
  const VLAN = [
    {
      title: "VLAN:",
      detail: "Soporte 4K VLAN",
    },
    {
        detail: "Acceso, Troncal, Híbrido",
    },
    {
      detail: "Soporte basado en puertos",
    },
    {
      detail: "Soporte de registro de VLAN dinámica GVRP genérica",
    },
  ];
  const MacAddress = [
    {
      title: "Dirección MAC:",
      detail: "Soporte estático, dinámico, adición, eliminación",
    },
    {
      detail: "Límite de aprendizaje de direcciones MAC",
    },
    {
      detail: "Ajuste dinámico del tiempo de envejecimiento",
    },
  ];
  const SpanningTree = [
    {
      title: "Árbol de expansión:",
      detail: "Compatibilidad con el protocolo STP 802.1d",
    },
    {
      detail: "Spanning Tree",
    },
    {
      detail: "Soporta el protocolo RSTP 802.1w Spanning Tree",
    },
    {
      detail: "Soporta el protocolo MSTP 802.1w Spanning Tree",
    },
    {
      detail: "Soporte de protección BPDU",
    },
  ];
  const Multicast = [
    {
      title: "Multidifusión:",
      detail: "Soporte de adición estática, eliminación",
    },
    {
      detail: "Soporte IGMP-Snooping",
    },
    {
      detail: "Soporte MLD-Snooping",
    },
    {
      detail: "Soporte v1/2/3 monitor multicast dinámico, DDM: Soporte SFP / SFP + DDM",
    },
  ];
  return (
    <Container>
      <ul>
        {SwitchingArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {L2Management.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortMirroring.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortIsolation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StormSuppression.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LinkAggregation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {MacAddress.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SpanningTree.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Multicast.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

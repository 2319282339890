import { Container } from "react-bootstrap";
// TODO: Pendientea verificación en proximas fases
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
import {
  MarketingHeader,
  TableListProducts,
  // TODO: Pendientea verificación en proximas fases
  // MarketingListProducts,
  // MarketingListGpon,
} from "./components";

export function MarketingPage() {
  return (
    <Container fluid className="px-0 pb-5">
      <MarketingHeader />
      <TableListProducts />
      {/* TODO: Pendientea verificación en proximas fases
       <Container>
        <Row>
          <Col xs={12}>
            <Tabs
              defaultActiveKey="Characteristics"
              id="fill-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Characteristics" title="Lista de productos">
                <MarketingListProducts />
              </Tab>
              <Tab eventKey="Specifications" title="Lista de productos GPON">
                <MarketingListGpon />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
}

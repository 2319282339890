import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import ApplicationsConnectivity from "../../../../../../../assets/imagesproduct/SwitchL3/ApplicationsConnectivity.jpg";
import L3Functionality from "../../../../../../../assets/imagesproduct/SwitchL3/L3Functionality.jpg";

export function PerformanceandScalability({ content }: { content: string | undefined}) {
  return (
    <Container>
      <Row>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Rendimiento y escalabilidad 10G" />
          <p>
            Con una alta capacidad de conmutación, {content} admite el reenvío
            L2/L3 a velocidad de cable y un alto rendimiento de enrutamiento
            para los protocolos IPv4 e IPv6.
          </p>
          &nbsp;
          <p>
            La conectividad de 10 Gigabits de {content} se logra a través de un
            transceptor SFP+ de 10 Gigabits conectable en caliente que admite
            distancias de hasta 300 metros sobre fibra multimodo y de 10 a 40 km
            sobre fibra monomodo (la distancia depende del módulo óptico
            elegido).
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img
            className="w-100"
            src={ApplicationsConnectivity}
            alt="applications with connectivity 10G"
          />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Potente funcionalidad L3 IGMP Snooping Multicast" />
          <p>
            Los protocolos de multidifusión L3 son compatibles con IGMPv1/v2/v3
            y admiten abundantes funciones de multidifusión como IGMP v2/v3
            snooping y salida rápida. Con registro VLAN de multidifusión (MVR),
            control de receptor/remitente de multidifusión y funciones de
            detección de fuentes de multidifusión ilegales; La serie de fibra
            {content} proporciona una excelente experiencia de aplicación para
            el cliente.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className="w-100"
            src={L3Functionality}
            alt="L3 functionality"
          />
        </Col>
      </Row>
    </Container>
  );
}

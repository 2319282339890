import { Col, Container, Row } from "react-bootstrap";
import { Products } from "../Products";
import { SidebarProducts } from "../../SidebarProducts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HomeSubtitle } from "../../../../../HomeSubtitle";
import { ProductCard } from "../../../../../ProductCard";
import styles from "./FiberAdapters.module.scss";
import ConverterMC1GSFP from "../../../../../../assets/imagesproduct/Accessories/ConverterMC1GSFP/ConverterMC1GSFP.jpg";
import ConverterMC10GSFP from "../../../../../../assets/imagesproduct/Accessories/ConverterMC10GSFP/ConverterMC10GSFP.jpg";
import TransceiverSFP10GT30m from "../../../../../../assets/imagesproduct/Accessories/TransceiverSFP10GT30m/TransceiverSFP10GT30m.jpg";
import TransceiverISFPLX125G20KM from "../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KM/TransceiverISFPLX125G20KM.jpg";
import TransceiverISFPLX125G20KMSMFBIDI from "../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KMSMFBIDI/TransceiverISFPLX125G20KMSMFBIDI.jpg";
import TransceiverISFPSR10G300M from "../../../../../../assets/imagesproduct/Accessories/TransceiverISFPSR10G300M/TransceiverISFPSR10G300M.jpg";
import TransceiverSFPLR10G10KM from "../../../../../../assets/imagesproduct/Accessories/TransceiverSFPLR10G10KM/TransceiverSFPLR10G10KM.jpg";
import TransceiverSFPLX125G20KM from "../../../../../../assets/imagesproduct/Accessories/TransceiverSFPLX125G20KM/TransceiverSFPLX125G20KM.jpg";
import TransceiverSFPLX25G20KM from "../../../../../../assets/imagesproduct/Accessories/TransceiverSFPLX25G20KM/TransceiverSFPLX25G20KM.jpg";
import TransceiverSFPSR10G300M from "../../../../../../assets/imagesproduct/Accessories/TransceiverSFPSR10G300M/TransceiverSFPSR10G300M.jpg";
import TransceiverSFPSX125G500M from "../../../../../../assets/imagesproduct/Accessories/TransceiverSFPSX125G500M/TransceiverSFPSX125G500M.jpg";

export function FiberAdapters() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const FiberConverters = [
    {
      img: { src: ConverterMC1GSFP, alt: "AirLive MC-1GSFP" },
      title: "AirLive MC-1GSFP",
      description: "MC-1GSFP+: Multi Giga Network Fiber Media Converter",
      button: {
        name: "VER MÁS",
        path: "../ConverterMC1GSFP",
      },
    },
    {
      img: { src: ConverterMC10GSFP, alt: "AirLive MC10GSFP+" },
      title: "AirLive MC-10GSFP+",
      description: "MC-10GSFP+: Multi Giga Network Fiber Media Converter",
      button: {
        name: "VER MÁS",
        path: "../ConverterMC10GSFP",
      },
    },
  ];
  const SFPTransceivers = [
    {
      img: { src: TransceiverSFP10GT30m, alt: "SFP-10GT-30m" },
      title: "Transceiver SFP-10GT-30m",
      description: "Transceiver 10GBASE-T de Cobre SFP+ Hasta 100M",
      button: {
        name: "VER MÁS",
        path: "../TransceiverSFP10GT30m",
      },
    },
    {
      img: { src: TransceiverISFPLX125G20KMSMFBIDI, alt: "ISFP-LX-1.25G-20KM-SMF-BIDI A&B" },
      title: "Transceiver ISFP-SMF-BIDI A&B",
      description: "Transceptor nivel Industrial A:1310/1550, B:1550/1310, DDM",
      button: {
        name: "VER MÁS",
        path: "../TransceiverISFPLXGMSMFBIDI",
      },
    },
    {
      img: { src: TransceiverISFPLX125G20KM, alt: "ISFP-LX-1.25G-20KM" },
      title: "Transceiver ISFP-LX-1.25G-20KM",
      description: "Transceptor nivel Industrial 1000Base-LX MiniGBIC",
      button: {
        name: "VER MÁS",
        path: "../TransceiverISFPLX125G20KM",
      },
    },
    {
      img: { src: TransceiverISFPSR10G300M, alt: "ISFP-SR-10G-300M" },
      title: "Transceiver ISFP-SR-10G-300M",
      description: "Transceptor grado Industrial 10G Multi Giga SFP+ MiniGBIC",
      button: {
        name: "VER MÁS",
        path: "../TransceiverISFPSR10G300M",
      },
    },
    {
      img: { src: TransceiverSFPLR10G10KM, alt: "SFP-LR-10G-10KM" },
      title: "Transceiver SFP-LR-10G-10KM",
      description: "Transceptor MiniGBIC 10G Multi Giga SFP+ Hasta 10KM",
      //TODO: en espera de info
      //  button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: TransceiverSFPLX125G20KM, alt: "SFP-LX-1.25G-20KM" },
      title: "Transceiver SFP-LX-1.25G-20KM",
      description: "Transceptor MiniGBIC 1000Base-LX Hasta 20 km",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: TransceiverSFPLX25G20KM, alt: "SFP-LX-2.5G-20KM" },
      title: "Transceiver SFP-LX-2.5G-20KM",
      description: "Transceptor MiniGBIC 2,5G 2500Base-X Hasta 20 km",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: TransceiverSFPSR10G300M, alt: "SFP-SR-10G-300M" },
      title: "Transceiver SFP-SR-10G-300M",
      description: "10G Multi Giga SFP+ MiniGBIC transceiver Hasta 300M",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // },
    },
    {
      img: { src: TransceiverSFPSX125G500M, alt: "SFP-SX-1.25G-500M" },
      title: "Transceiver SFP-SX-1.25G-500M",
      description: "1000Base-SX MiniGBIC transceiver Hasta 500M",
      //TODO: en espera de info
      // button: {
      //   name: "VER MÁS",
      //   path: "#",
      // }    
    },
  ];

  return (
    <div className={styles.container}>
      <SidebarProducts />
      <Container>
        <Products />
        <Row>
          <Col className="mb-5">
            <HomeSubtitle title="Adaptadores de fibra:" />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Convertidores de fibra" />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {FiberConverters.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <HomeSubtitle title="Módulos SFP" />
          </Col>
        </Row>
        <Row>
          <Col xs={10} className="mb-5">
            <Carousel responsive={responsive}>
              {SFPTransceivers.map((products) => (
                <ProductCard
                  img={products.img}
                  title={products.title}
                  description={products.description}
                  buttonCard={products.button}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import RMONStatisticsSupportPic from "../../../../../../../assets/imagesproduct/SwitchL2/RMONStatisticsSupportPic.jpg";

export function RMONStatisticsSupport({image}: {image: string | undefined;}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte de estadísticas RMON" />
          <p>Para cada puerto y elemento de paquetes diferentes.</p>
        </Col>
        <Col xs={6} className="mb-5 text-align-center">
          <img
            className="w-100"
            src={RMONStatisticsSupportPic}
            alt="RMON Statistics Support"
          />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Fibra para larga distancia" />
          <p>Extiende el alcance de la red con ranuras SPF.</p>
        </Col>
        <Col xs={8} className="mb-5">
          <img
            className="w-100"
            src={image}
            alt="image"
          />
        </Col>
      </Row>
    </Container>
  );
}

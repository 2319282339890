import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./AirCloudTOP12AC.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { GeneralSection } from "./GeneralSection";
import { SectionFunctionalitiesTOP12AC } from "./SectionFunctionalitiesTOP12AC";
import { AdvancedAdministrationTOP12AC } from "./AdvancedAdministrationTOP12AC";
import { SpecificationsTOP12AC } from "./SpecificationsTOP12AC";

export function AirCloudTOP12AC() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <GeneralSection />
           <SectionFunctionalitiesTOP12AC />
           <AdvancedAdministrationTOP12AC />
           <SpecificationsTOP12AC />
        </Container>
    </div>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsISFPLX125G20KMSMFBIDI() {
  const Feature = [
    {
      detail: "Transceptor/receptor de fibra Mini GBIC SFP BIDI de 1.25 Gbps",
    },
    {
      detail: "Fibra SFP monomodo",
    },
    {
      detail: "Paquete SFP con conector LC",
    },
    {
      detail: "Láser FP de longitud de onda de 1310nm y fotodetector PIN de 1550nm",
    },
    {
      detail: "Láser DFP de longitud de onda de 1550nm y fotodetector PIN de 1310nm",
    },
    {
      detail: "Alta velocidad de 1,25 Gbps",
    },
    {
      detail: "Distancia hasta 20KM",
    },
    {
      detail: "Bajo consumo de energía máx. 1.0W",
    },
    {
      detail: "Capacidad conectable en caliente",
    },
    {
      detail: "EMI muy baja",
    },
    {
      detail: "Excelente protección contra ESD",
    },
    {
      detail: "IEEE802.3z",
    },
    {
      detail: "Interfaz de entrada/salida de datos compatible con LVPECL",
    },
    {
      detail: "Temperatura de funcionamiento: -40 °C ~ +85 °C",
    },
  ];
  const NotableSpecifications = [
    {
      detail: "Infraestructura de canal de fibra",
    },
    {
      detail: "Interfaz óptica STM-16",
    },
    {
      detail: "Conmutadores y enrutadores ATM",
    },
    {
      detail: "Infraestructura de conmutadores SDH/SONET",
    },
    {
      detail: "Ejemplos de entorno de aplicación: Bancos, Aeropuertos, Hoteles",
    },
    {
      detail: "Autopista, Industria petroquímica, Metro, Fábrica",
    },
  ];
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Aplicaciones" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className="mb-5">
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={5} className="mb-5">
          <ul>
            {NotableSpecifications.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import styles from "./CharacteristicsPOEGSH820120.module.scss";
import descriptionSwitchL2POEGSH820120 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH820120/descriptionSwitchL2POEGSH820120.jpg";
import VLANExtendMode from "../../../../../../../../assets/imagesproduct/SwitchL2/VLANExtendMode.jpg";

export function CharacteristicsPOEGSH820120() {
  const Feature = [
    {
      detail:
        "Todos y cada uno de los 10 puertos, cada puerto soporta alta velocidad Gigabit",
    },
    {
      detail: "8 x puertos PoE 10/100/1000Mbps (hasta 30W)",
    },
    {
      detail:
        "Amplíe la red con 2 puertos de enlace ascendente RJ-45 de 10/100/1000 Mbps",
    },
    {
      detail: "Alimentación PoE, compatible con PoE 802.3af/at",
    },
    {
      detail: "Potencia interna 120W",
    },
    {
      detail:
        "Modo VLAN, modo extendido: transmisión de hasta 250M en modo extendido, configuración vía interruptor DIP de hardware",
    },
    {
      detail: "Protección contra sobretensiones; Protección ESD",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
      </Row>
      <Row>
        <Col xs={7} className="mb-5">
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={5} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH820120}
            alt="Description Switch"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-5">
          <SubTitleProduct content="Modo VLAN" />
          <p>
            Esta característica adicional le da al switch seguridad adicional
            para los usuarios conectados a él. El modo VLAN funciona como
            aislamiento de puerto, lo que significa que cuando está habilitado,
            los usuarios conectados a los puertos LAN no pueden comunicarse
            entre sí, sino que solo se comunican con los puertos UP-Link (puerto
            9/10). De esta manera, hay una capa adicional de seguridad y los
            datos no se compartirán entre los usuarios. Esto se puede usar en
            una oficina pequeña para separar a los invitados de la red de la
            empresa o en un hogar para separar a los inquilinos.
          </p>
          &nbsp;
          <SubTitleProduct content="Modo Extendido" />
          <p>
            El POE-GSH820-120 se puede configurar en modo Extender para alcanzar
            una distancia de hasta 250 m, esto puede ser muy útil en lugares
            donde es difícil obtener energía para encender su dispositivo PD. El
            modo Extender es solo para los puertos 7 y 8 en el switch y cuando
            está habilitado la velocidad de transmisión será de 10Mbps. PoE
            Watchdog también está habilitado cuando el modo Extender está
            activado.
          </p>
        </Col>
        <Col xs={6} className="mb-5">
          <img
            className={styles.image}
            src={VLANExtendMode}
            alt="VLAN Extend Mode"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH1622200.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH1622200 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1622200/GSH1622200.jpg";
import descriptionSwitchL2POEGSH1622200 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH1622200/descriptionSwitchL2POEGSH1622200.jpg";

export function TitleSectionL2POEGSH1622200() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit POE de 20 puertos, 802.3af/at, 200W Incluye 16 puertos PoE de 30W PoE + 2x1.25G SFP, 2x1000T" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH1622-200" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH1622200}
            alt="Switch Gigabit POE de 20 puertos, 802.3af/at, 200W"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit PoE+ de 20 puertos con prioridad PoE, modo extendido y puerto SFP/RJ-45" />
          <p>
            El switch AirLive POE-GSH1622-200 Gigabit es compatible con 802.3af
            (15 W) / 802.3at (30 W) posee 16 puertos PoE y 4 puertos de enlace
            ascendente 2x RJ45 o 2x SPF de Fibra. La potencia total PoE es de
            200 W. Es un switch PoE no gestionado plug and play con prioridad
            PoE y extensión de cableado de 250 metros, que se puede configurar
            fácilmente a través de su conmutador DIP. Los cuatro puertos Gigabit
            Uplink (2 SFP y 2 RJ-45) permiten extender la red a mayor alcance,
            lo que lo convierte en un switch costo efectivo para diversas
            aplicaciones en el hogar o la oficina. Permite conectar dispositivos
            vía PoE, tales como puntos de acceso o cámaras IP a cualquiera de
            los puertos PoE y operar hasta un máximo de 30 W (at).
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH1622200}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightGPONOLT121() {
  const GPONFunction = [
    {
      title: "Función GPON:",
    },
    {
      detail: "Tcont DBA",
    },
    {
      detail: "Tráfico de Gemport",
    },
    {
      detail: "Cumple con la norma ITU-T984.x",
    },
    {
      detail: "Hasta 20 km de distancia de transmisión",
    },
    {
      detail: "Soporte de cifrado de datos, multidifusión, VLAN de puerto, separación, etc.",
    },
    {
      detail: "Admite el descubrimiento automático de ONT / detección de enlaces / actualización remota de software",
    },
    {
      detail: "Admite la división VLAN y la separación de usuarios para evitar tormentas de difusión",
    },
    {
      detail: "Soporte de función de alarma de apagado, fácil para la detección de problemas de enlace",
    },
    {
      detail: "Soporte de transmisión de la función de resistencia a tormentas",
    },
  ];
  const Feeding = [
    {
      title: "Alimentación:",
    },
    {
      detail: "DC 12V/2.5A (opcional)",
    },
    {
      detail: "CA 85 ~ 264V, 47 / 60Hz (predeterminado)",
    },
  ];
  const EnvironmentalConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de trabajo: 0 °C a +55 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -40 °C a +85 °C",
    },
    {
      detail: "Humedad relativa: 10% ~ 85%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto:",
      detail: "Tamaño del producto: 22.4x19.9x4.36 cm",
    },
    {
      detail: "Dimensiones del paquete: 35x26x7 cm ",
    },
    {
      detail: "Peso del paquete: N.W. del producto: 1.07KG Producto G.W: KG",
    },
    {
      detail:
        "Contenido del paquete: 1 x GPON OLT121, accesorio de instalación, cable LAN, manual del usuario",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "Modelo: AirLive GPON OLT121 1 Puertos PON GPON OLT con 10G SFP+",
    },
  ];
  return (
    <Container>
      <ul>
        {GPONFunction.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Feeding.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH2422300.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH2422300 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH2422300/GSH2422300.jpg";
import descriptionSwitchL2POEGSH2422300 from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH2422300/descriptionSwitchL2POEGSH2422300.jpg";

export function TitleSectionL2POEGSH2422300() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit POE de 28 puertos, 802.3af/at, 300W Incluye 24 puertos PoE de 30W, 2x1.25G SFP, 2x1000T" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="POE-GSH2422-300" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH2422300}
            alt="Switch Gigabit POE de 28 puertos, 802.3af/at, 300W"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch Gigabit PoE+ de 28 puertos con prioridad PoE, modo extendido y puerto SFP/RJ-45" />
          <p>
            El switch AirLive POE-GSH2422-300 Gigabit es compatible con 802.3af
            (15 W) / 802.3at (30 W). Soporta 24 puertos PoE y 4 puertos de
            enlace ascendente 2x RJ45 o 2x SPF Fibra. La potencia total de PoE
            es de 300 W. Es un switch PoE no gestionado plug and play con
            prioridad PoE y extensión de cableado de 250 metros, que se puede
            configurar fácilmente a través de su interruptor DIP. Los cuatro
            puertos Gigabit Uplink (2 SFP y 2 RJ-45) permiten conectar a una red
            de mayor alcance, lo que lo convierte en un switch altamente costo
            efectivo para diversas aplicaciones en el hogar o la oficina. Puede
            conectar dispositivos PoE al switch, tales como puntos de acceso o
            cámaras IP normales en cualquiera de los puertos PoE, y alimentar
            hasta un máximo de 30 W (at).
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH2422300}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function ChangesPrivacyPolicySection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Cambios a esta política de privacidad" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Es posible que actualicemos nuestra Política de privacidad de vez en
            cuando. Le notificaremos cualquier cambio publicando la nueva
            Política de Privacidad en esta página.
          </p>
          <p>
            Le informaremos por correo electrónico y/o un aviso destacado en
            nuestro Servicio, antes de que el cambio entre en vigencia y
            actualizaremos la "fecha de vigencia" en la parte superior de esta
            Política de privacidad.
          </p>
          <p>
            Se le recomienda revisar esta Política de Privacidad periódicamente
            para cualquier cambio. Los cambios a esta Política de privacidad son
            efectivos cuando se publican en esta página.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

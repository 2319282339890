import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";

export function CharacteristicsXGF28() {
    const Feature = [
        {
          detail: "Funcionalidades capa 3",
        },
        {
          detail: "Las prestaciones de capa 2 proporcionan una administración, seguridad y QoS sencillas",
        },
        {
          detail: "Conmutación de protección de anillo Ethernet (ERPS) UIT-T G.8032",
        },
        {
          detail: "SNMP, WEB, CLI, SSH2.0, Telnet",
        },
        {
          detail: "VLAN de la división de Voz",
        },
        {
          detail: "Ruta estática IPv4/IPv6 L3",
        },
        {
          detail: "Rutas OSPF enrutamiento dinámico",
        },
        {
          detail: "SFP+ 10Gbps Fibra de larga distancia",
        },
        {
          detail: "Compatibilidad con Voice Vlan",
        },
        {
          detail: "Visualización del estado de tráfico, CPU, el consumo de fibra y el estado por puerto",
        },
        {
          detail: "Web-UI para una fácil administración; CLI y script de comandos para la configuración avanzada; SNMP utilizado para la gestión con herramientas",
        },
    ];
    const NotableSpecifications = [
        {
            detail: "24 x ranuras SFP" 
        },
        {
            detail: "4 ranuras de 10G SFP+" 
        },
        {
            detail: "8 puertos Gigabit Ethernet RJ45 (combo)" 
        },
        {
            detail: "Funcionalidad de capa 3" 
        },
        {
            detail: "Servidor/retransmisión DHCP L3" 
        },
        {
            detail: "L3 DHCP Snooping" 
        },
        {
            detail: "Ruta estática IPv4/IPv6 L3" 
        },
        {
            detail: "Rutas OSPF enrutamiento dinámico" 
        },
        {
            detail: "Protección contra sobretensiones 6KV, protección ESD 8KV" 
        },
        {
            detail: "Autenticación: 802.1x, AAA" 
        },
        {
            detail: "DHCP Snooping evita la instalación de enrutadores no autorizados" 
        },
        {
            detail: "Acoplamiento NMS" 
        },
    ];
  return (
    <Container>
        <Row>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Especificaciones resaltantes" />
            </Col>
        </Row>
        <Row>
            <Col md={5} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col md={5} className="mb-5">
                <ul>
                    {NotableSpecifications.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive8GT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive8GT } from "./SpecificationsLeftLive8GT";
import { SpecificationsRightLive8GT } from "./SpecificationsRightLive8GT/SpecificationsRightLive8GT";

export function FeatureProductLive8GT() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-8GT" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive8GT />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive8GT />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}

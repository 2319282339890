import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightAirDualAC12() {
  const networkFunction = [
    {
      title: "Función de red:",
      detail: "Configuración de VLAN de etiqueta basada en SSID, Servidor DHCP",
    },
  ];
  const DeviceManagement = [
    {
      title: "Gestión de dispositivos:",
      detail:
        "Copia de seguridad de la información de configuración, Restaurar la información de configuración, Restablecer la configuración predeterminada de fábrica",
    },
  ];
  const DeviceReboot = [
    {
      title: "Reinicio del dispositivo:",
      detail: "reinicio inmediato o reinicio programado, Actualización de firmware",
    },
  ];
  const TimeManagement = [
    {
      title: "Gestión del tiempo:",
      detail:
        "incluida la hora del sistema y la sincronización horaria, Registro del sistema, Soporta la gestión de la GUI WEB, Admite la gestión remota en la nube (modo AP)",
    },
  ];
  const MultipleLanguages = [
    {
      title: "Múltiples idiomas",
      detail: "Inglés",
    },
  ];
  const SurgeESDProtection = [
    {
      title: "Protección contra sobretensiones/ESD:",
      detail: "ESD: Descarga de aire: ±8KV, descarga de contacto: ±4KV",
    },
  ];
  const Environment = [
    {
      title: "Condiciones ambientales:",
      detail:
        "Temperatura de funcionamiento: -30 °C ~ +55 °C, Temperatura de almacenamiento: -40 °C ~ +70 °C, Humedad de funcionamiento: 10% ~ 90% (sin condensación), Humedad de almacenamiento: 5% ~ 90% (sin condensación)",
    },
  ];
  const Case = [
    {
      title: "Carcasa:",
      detail: "IP65",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar Tamaño del producto:",
      detail: "31,5 cm x 14,5 cm x 8 cm (L * W * H)",
    },
  ];
  const PackageSize = [
    {
      title: "Tamaño del paquete:",
      detail: "TBD cm (L * W * H)",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail:
        "TBD, 1 x AirDual-AC12, inyector PoE, accesorio de instalación, cable LAN, manual de usuario",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "AirLive AirDual-AC12, Wi-Fi AC1200 Gigabit CPE para exteriores",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Seguridad" />
      </Col>
      <ul>
        {networkFunction.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DeviceManagement.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DeviceReboot.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {TimeManagement.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {MultipleLanguages.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {SurgeESDProtection.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Environment.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Case.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PackageSize.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}

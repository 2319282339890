import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";
import styles from "./EfficientPoETechnology.module.scss";

export function EfficientPoETechnology({
  content,
  image,
  image2,
  numberports,
  numberports2,
  TextPoeWatchdog,
}: {
  content: string | undefined;
  image: string | undefined;
  image2: string | undefined;
  numberports: string | undefined;
  numberports2: string | undefined;
  TextPoeWatchdog: string | undefined;
}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Tecnología PoE eficiente AT PoE con prioridad PoE." />
          <p>
            Puedes conectar varios dispositivos PoE al switch, por ejemplo, los
            puntos de acceso o las cámaras IP normales en cualquiera de los
            puertos PoE y alimentarlos hasta un máximo de 30 W (at). La función
            de prioridad PoE es soportada desde el puerto {numberports}, esto es
            altamente útil para que una IPCAM o un dispositivo preferido tenga
            alta prioridad. Esta función está activada de forma predeterminada.
          </p>
        </Col>
        <Col xs={12} className="mb-5 text-align-center">
          <img className="w-100" src={image} alt="image" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo extendido" />
          <p>
            Modo de extensión: el switch {content} se puede configurar en modo
            de extensión para alcanzar una distancia de hasta 250 m, esto puede
            ser muy útil en lugares donde es difícil obtener energía para
            encender su dispositivo PD. El modo Extender hace que esto sea más
            fácil. El modo Extend es solo para los puertos {numberports2} del
            switch y, cuando está habilitado, la velocidad de transmisión será
            de 10 Mbps.
            {TextPoeWatchdog}
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Backbone de fibra para Larga Distancia e Interconexión entre plantas" />
          <p>
            Amplíe aún más la red con la ranura SFP. Utilice el puerto de fibra
            {content} para conectar diferentes pisos en un edificio grande
            o a la oficina principal a una distancia más larga. Dependiendo del
            módulo utilizado, puede alcanzar hasta 20KM de velocidad de
            transmisión de datos de 1250Mbps.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img className={styles.image2} src={image2} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}

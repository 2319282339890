import { Col, Container, Row } from "react-bootstrap";
import styles from "./Specifications.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftXGS4804850 } from "./SpecificationsLeftXGS4804850/SpecificationsLeftXGS4804850";
import { SpecificationsRightXGS4804850 } from "./SpecificationsRightXGS4804850/SpecificationsRightXGS4804850";

export function Specifications() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Switch Layer 3" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftXGS4804850 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightXGS4804850 content={"L3POE-XGS4804-850BT"} nameProduct={"Switch Gigabit PoE++ 802.3at/bt gestionado L3, 600 W y 48 puertos, incluidos 4 puertos de fibra SFP+ 10G."} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsRightIGM642BT125G() {
  const Powerinput = [
    {
        title: "Entrada de alimentación:",
        detail: "No posee fuente interna",
    },
    {
        detail: "Voltaje de entrada: DC48 ~ 55V",
    },
    {
        detail: "Tipo de entrada: Terminal de 4 pines (bloque V1 + V1- V2 + V1- Soporta entrada de alimentación dual",
    },
  ];
  const Environment = [
    {
        title: "Condiciones Ambientales:",
        detail: "Temperatura de funcionamiento: -40 °C a +85 °C",
    },
    {
        detail: "Temperatura de almacenamiento: -40 °C a +85 °C",
    },
    {
        detail: "Humedad: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardPackage = [
    {
      title: "Paquete estándar del producto Tamaño del producto:",
      detail: "Dimensiones del switch: 14,5 x 12,5 x 4 cm(L * W * H)",
    },
    {
      detail: "Dimensiones del paquete: 25 x 21 x 6,5 cm(L * W * H)",
    },
  ];
  const PackageWeight = [
    {
      title: "Peso del paquete:",
      detail: "N.W: 0.6KGs; G.W: 0.8KGs",
    },
  ];
  const PackageContent = [
    {
      title: "Contenido del paquete:",
      detail: "1 x Switch, 1 x cable serie",
    },
  ];
  const CartonPackaging = [
    {
      title: "Envase de cartón estándar:",
      detail: "Cantidad: 20 Piezas / 1 cartón ",
    },
    {
      detail: "Dimensiones: 50.5 x 32 x 40cm",
    },
  ];
  const InformationOrder = [
    {
      title: "Información de pedido:",
      detail: "IGM-642BT-1.25G",
    },
    {
      detail:
        "Nombre: Switch Gigabit POE++ gestionado por anillo industrial L2+ Gigabit PoE++ de 6 puertos, incluido SFP de 2*1,25 G",
    },
  ];
  return (
    <Container>
      <ul>
        {Powerinput.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Environment.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardPackage.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageWeight.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PackageContent.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CartonPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {InformationOrder.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

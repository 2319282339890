import { Route, Routes } from 'react-router-dom';
import { Products, Layer3ManagedSwitches, Layer2ManagedSwitches, IndustrialSwitches, TelecomXGPONCloud } from '../components/Pages';
import { WirelessNetworksAirLiveCloud } from '../components/Pages/WirelessNetworksAirLiveCloud/WirelessNetworksAirLiveCloud';
import { FiberAdapters } from '../components/Pages/FiberAdapters/FiberAdapters';
import { AirCloudPlatform } from '../components/Pages/WirelessNetworksAirLiveCloud/AirCloudPlatform';
import { AirCloudTOP18AX } from '../components/Pages/WirelessNetworksAirLiveCloud/AirCloudTOP18AX';
import { AirCloudTOP12AC } from '../components/Pages/WirelessNetworksAirLiveCloud/AirCloudTOP12AC';
import { AirLiveinWallU618AX } from '../components/Pages/WirelessNetworksAirLiveCloud/AirLiveinWallU618AX';
import { AirCloudAirMax5XII } from '../components/Pages/WirelessNetworksAirLiveCloud/AirCloudAirMax5XII';
import { WLAN128GM } from '../components/Pages/WirelessNetworksAirLiveCloud/WLAN128GM';
import { WLAN64GMl } from '../components/Pages/WirelessNetworksAirLiveCloud/WLAN64GMl';
import { W6184QAXCE } from '../components/Pages/WirelessNetworksAirLiveCloud/W6184QAXCE';
import { AirDualAC12 } from '../components/Pages/WirelessNetworksAirLiveCloud/AirDualAC12';
import { AWOD12ACi } from '../components/Pages/WirelessNetworksAirLiveCloud/AWOD12ACi';
import { AWOD30AXi } from '../components/Pages/WirelessNetworksAirLiveCloud/AWOD30AXi';
import { SwitchL3POEXGS4804850BT } from '../components/Pages/Layer3ManagedSwitches/SwitchL3POEXGS4804850BT';
import { SwitchL3XGS2404 } from '../components/Pages/Layer3ManagedSwitches/SwitchL3XGS2404';
import { SwitchL3XGS4804 } from '../components/Pages/Layer3ManagedSwitches/SwitchL3XGS4804';
import { SwitchL3XGF28 } from '../components/Pages/Layer3ManagedSwitches/SwitchL3XGF28';
import { SwitchL310XGF12 } from '../components/Pages/Layer3ManagedSwitches/SwitchL310XGF12';
import { SwitchL3POEXGS2404400 } from '../components/Pages/Layer3ManagedSwitches/SwitchL3POEXGS2404400';
import { L32TX2406 } from '../components/Pages/Layer3ManagedSwitches/L32TX2406';
import { PlataformAirLiveGPON } from '../components/Pages/TelecomXGPONCloud/PlataformAirLiveGPON';
import { GPONOLT121 } from '../components/Pages/TelecomXGPONCloud/GPONOLT121';
import { XponONU1GE } from '../components/Pages/TelecomXGPONCloud/XponONU1GE';
import { POEGSH2404M400 } from '../components/Pages/Layer2ManagedSwitches/POEGSH2404M400';
import { POEGSH1602M200 } from '../components/Pages/Layer2ManagedSwitches/POEGSH1602M200';
import { POEGSH4804M600 } from '../components/Pages/Layer2ManagedSwitches/POEGSH4804M600';
import { POEGSH402BT } from '../components/Pages/Layer2ManagedSwitches/POEGSH402BT';
import { POEGSH41160BT } from '../components/Pages/Layer2ManagedSwitches/POEGSH41160BT';
import { POEGSH411120BT } from '../components/Pages/Layer2ManagedSwitches/POEGSH411120BT';
import { POEGSH42060 } from '../components/Pages/Layer2ManagedSwitches/POEGSH42060';
import { POEGSH800120BT } from '../components/Pages/Layer2ManagedSwitches/POEGSH800120BT';
import { POEGSH802120 } from '../components/Pages/Layer2ManagedSwitches/POEGSH802120';
import { POEGSH820120 } from '../components/Pages/Layer2ManagedSwitches/POEGSH820120';
import { POEGSH822120 } from '../components/Pages/Layer2ManagedSwitches/POEGSH822120';
import { POEGSH1622200 } from '../components/Pages/Layer2ManagedSwitches/POEGSH1622200';
import { POEGSH2422300 } from '../components/Pages/Layer2ManagedSwitches/POEGSH2422300';
import { Live2TX501 } from '../components/Pages/Layer2ManagedSwitches/Live2TX501';
import { Live2TX801 } from '../components/Pages/Layer2ManagedSwitches/Live2TX801';
import { Live10TX800 } from '../components/Pages/Layer2ManagedSwitches/Live10TX800';
import { SNMPGSH802M } from '../components/Pages/Layer2ManagedSwitches/SNMPGSH802M';
import { SNMPGSH2404M } from '../components/Pages/Layer2ManagedSwitches/SNMPGSH2404M';
import { Live8GTII } from '../components/Pages/Layer2ManagedSwitches/Live8GTII';
import { Live5GTII } from '../components/Pages/Layer2ManagedSwitches/Live5GTII';
import { Live8GT } from '../components/Pages/Layer2ManagedSwitches/Live8GT';
import { Live16GTMini } from '../components/Pages/Layer2ManagedSwitches/Live16GTMini';
import { Live24GT } from '../components/Pages/Layer2ManagedSwitches/Live24GT';
import { Live26G } from '../components/Pages/Layer2ManagedSwitches/Live26G';
import { SwitchIGM642BT125G } from '../components/Pages/IndustrialSwitches/SwitchIGM642BT125G';
import { SwitchIGM642POE125G } from '../components/Pages/IndustrialSwitches/SwitchIGM642POE125G';
import { ConverterMC1GSFP } from '../components/Pages/FiberAdapters/ConverterMC1GSFP';
import { ConverterMC10GSFP } from '../components/Pages/FiberAdapters/ConverterMC10GSFP';
import { TransceiverSFP10GT30m } from '../components/Pages/FiberAdapters/TransceiverSFP10GT30m';
import { TransceiverISFPLX125G20KM } from '../components/Pages/FiberAdapters/TransceiverISFPLX125G20KM';
import { TransceiverISFPLXGMSMFBIDI } from '../components/Pages/FiberAdapters/TransceiverISFPLXGMSMFBIDI';
import { TransceiverISFPSR10G300M } from '../components/Pages/FiberAdapters/TransceiverISFPSR10G300M';

export const ProductsRoutes = () => (
  <Routes>
    <Route path="/" element={<Products />} />
    <Route path="/WirelessNetworksAirLiveCloud" element={<WirelessNetworksAirLiveCloud />} />
    <Route path="/Layer3ManagedSwitches" element={<Layer3ManagedSwitches />} />
    <Route path="/Layer2ManagedSwitches" element={<Layer2ManagedSwitches />} />
    <Route path="/IndustrialSwitches" element={<IndustrialSwitches />} />
    <Route path="/TelecomXGPONCloud" element={<TelecomXGPONCloud />} />
    <Route path="/FiberAdapters" element={<FiberAdapters />} />
    {/* WirelessNetworksAirLiveCloud */}
    <Route path="/AirCloudPlatform" element={<AirCloudPlatform />} />
    <Route path="/AirCloudAirMax5XII" element={<AirCloudAirMax5XII />} />
    <Route path="/AirCloudTOP12AC" element={<AirCloudTOP12AC />} />
    <Route path="/AirCloudTOP18AX" element={<AirCloudTOP18AX />} />
    <Route path="/AirDualAC12" element={<AirDualAC12 />} />
    <Route path="/AirLiveinWallU618AX" element={<AirLiveinWallU618AX />} />
    <Route path="/AWOD12ACi" element={<AWOD12ACi />} />
    <Route path="/AWOD30AXi" element={<AWOD30AXi />} />
    <Route path="/WLAN128GM" element={<WLAN128GM />} />
    <Route path="/WLAN64GMl" element={<WLAN64GMl />} />
    <Route path="/W6184QAXCE" element={<W6184QAXCE />} />
    {/* Layer3ManagedSwitches */}
    <Route path="/L32TX2406" element={<L32TX2406 />} />
    <Route path="/SwitchL3POEXGS2404400" element={<SwitchL3POEXGS2404400 />} />
    <Route path="/SwitchL3POEXGS4804850BT" element={<SwitchL3POEXGS4804850BT />} />
    <Route path="/SwitchL3XGS2404" element={<SwitchL3XGS2404 />} />
    <Route path="/SwitchL3XGS4804" element={<SwitchL3XGS4804 />} />
    <Route path="/SwitchL3XGF28" element={<SwitchL3XGF28 />} />
    <Route path="/SwitchL310XGF12" element={<SwitchL310XGF12 />} />
    {/* Layer2ManagedSwitches */}
    <Route path="/POEGSH402BT" element={<POEGSH402BT />} />
    <Route path="/POEGSH1602M200" element={<POEGSH1602M200 />} />
    <Route path="/POEGSH2404M400" element={<POEGSH2404M400 />} />
    <Route path="/POEGSH4804M600" element={<POEGSH4804M600 />} />
    <Route path="/POEGSH41160BT" element={<POEGSH41160BT />} />
    <Route path="/POEGSH42060" element={<POEGSH42060 />} />
    <Route path="/POEGSH411120BT" element={<POEGSH411120BT />} />
    <Route path="/POEGSH800120BT" element={<POEGSH800120BT />} />
    <Route path="/POEGSH802120" element={<POEGSH802120 />} />
    <Route path="/POEGSH820120" element={<POEGSH820120 />} />
    <Route path="/POEGSH822120" element={<POEGSH822120 />} />
    <Route path="/POEGSH1622200" element={<POEGSH1622200 />} />
    <Route path="/POEGSH2422300" element={<POEGSH2422300 />} />
    <Route path="/Live2TX501" element={<Live2TX501 />} />
    <Route path="/Live2TX801" element={<Live2TX801 />} />
    <Route path="/Live10TX800" element={<Live10TX800 />} />
    <Route path="/SNMPGSH802M" element={<SNMPGSH802M />} />
    <Route path="/SNMPGSH2404M" element={<SNMPGSH2404M />} />
    <Route path="/Live8GTII" element={<Live8GTII />} />
    <Route path="/Live5GTII" element={<Live5GTII />} />
    <Route path="/Live8GT" element={<Live8GT />} />
    <Route path="/Live16GTMini" element={<Live16GTMini />} />
    <Route path="/Live24GT" element={<Live24GT />} />
    <Route path="/Live26G" element={<Live26G />} />
    {/* TelecomXGPONCloud */}
    <Route path="/PlataformAirLiveGPON" element={<PlataformAirLiveGPON />} />
    <Route path="/GPONOLT121" element={<GPONOLT121 />} />
    <Route path="/XponONU1GE" element={<XponONU1GE />} />
    {/* IndustrialSwitches */}
    <Route path="/SwitchIGM642BT125G" element={<SwitchIGM642BT125G />} />
    <Route path="/SwitchIGM642POE125G" element={<SwitchIGM642POE125G />} />
    {/* FiberAdapters */}
    <Route path="/ConverterMC1GSFP" element={<ConverterMC1GSFP />} />
    <Route path="/ConverterMC10GSFP" element={<ConverterMC10GSFP />} />
    <Route path="/TransceiverSFP10GT30m" element={<TransceiverSFP10GT30m />} />
    <Route path="/TransceiverISFPLX125G20KM" element={<TransceiverISFPLX125G20KM />} />
    <Route path="/TransceiverISFPLXGMSMFBIDI" element={<TransceiverISFPLXGMSMFBIDI />} />
    <Route path="/TransceiverISFPSR10G300M" element={<TransceiverISFPSR10G300M />} />
  </Routes>
);

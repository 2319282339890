import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionTransceiverSFP10GT30m.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import SFP10GT30m from "../../../../../../../../assets/imagesproduct/Accessories/TransceiverSFP10GT30m/SFP10GT30m.jpg";

export function TitleSectionTransceiverSFP10GT30m() {
  const columns = [
    { title: "Estándar" },
    { title: "Cable" },
    { title: "Alcance" },
    { title: "Puerto de Host" },
  ];
  const data = [
    {
      estándar: "10Gbase-T",
      cable: "CAT6A",
      alcance: "30m",
      puertoHost: "SFI",
    },
    {
      estándar: "5Gbase-T/2.5Gbase-T",
      cable: "CAT5E",
      alcance: "50m",
      puertoHost: "5GBase-R/2.5GBase-X",
    },
    {
      estándar: "1000base-T",
      cable: "CAT5E",
      alcance: "100m",
      puertoHost: "1000base-FX",
    },
  ];
  const Feature = [
    {
      detail: "Soporte 10Gbase-T / 5Gbase-T / 2.5Gbase-T / 1000base-T",
    },
    {
      detail: "Pista de circuito SFP conectable en caliente",
    },
    {
      detail: "Encapsulamiento compacto de conector RJ-45",
    },
    {
      detail: "Cumple con RoHS y sin plomo",
    },
    {
      detail: "Fuente de alimentación única de +3.3V",
    },
    {
      detail: "Cable Ethernet a través de Cat 6a de 10 Gigabit",
    },
    {
      detail: "Temperatura ambiente de funcionamiento: 0 °C a +65 °C",
    },
  ];
  const NotableSpecifications = [
    {
      detail: "Entorno de aplicaciones típicas",
    },
    {
      detail: "Infraestructura de redes para:",
    },
    {
      detail: "Banca",
    },
    {
      detail: "Aeropuerto",
    },
    {
      detail: "Hotel",
    },
    {
      detail: "Autopista",
    },
    {
      detail: "Industria petroquímica",
    },
    {
      detail: "Metro",
    },
    {
      detail: "Fábrica",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Transceiver 10GBASE-T de Cobre SFP+ Hasta 100M, Conectable en caliente, Auto-fall back" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="SFP-10GT-30m" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={SFP10GT30m}
            alt="Transceiver 10GBASE-T de Cobre SFP+"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Información General" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El SFP-10GT-30m es un transceptor SFP+-10GBASE-T Cobre Small Form
            Pluggable (SFP) basado en el SFP Multi Source Agreement (MSA). Son
            compatibles con los estándares 10Gbase-T / 5Gbase-T / 2.5Gbase-T /
            1000base-T como se especifica en IEEE Std 802.3. SFP+-10GBASE-T
            utiliza el pin RX_LOS (debe ser levantado en el host) del SFP para
            la indicación del enlace. Si levanta o abre el pin TX_DISABLE de
            SFP, se restablecerá PHY IC. El SFP-10GT-30m funciona con una
            temperatura de funcionamiento de 0 °C ~ +65 °C y el módulo es
            conectable en caliente. Las aplicaciones adecuadas, como redes de
            área de almacenamiento de alta velocidad, conexión cruzada de
            clústeres de computadoras, tuberías de datos de alta velocidad
            personalizadas y más.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Aplicaciones" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className="mb-5">
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={5} className="mb-5">
          <ul>
            {NotableSpecifications.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <table>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr>
                  <td>{row.estándar}</td>
                  <td>{row.cable}</td>
                  <td>{row.alcance}</td>
                  <td>{row.puertoHost}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
}

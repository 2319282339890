import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsSNMPGSH2404M.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftSNMPGSH2404M } from "./SpecificationsLeftSNMPGSH2404M/SpecificationsLeftSNMPGSH2404M";
import { SpecificationsRightSNMPGSH2404M } from "./SpecificationsRightSNMPGSH2404M/SpecificationsRightSNMPGSH2404M";

export function SpecificationsSNMPGSH2404M() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive SNMP-GSH2404M" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftSNMPGSH2404M />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightSNMPGSH2404M />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

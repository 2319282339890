import { Container } from "react-bootstrap";
import styles from "./WLAN128GM.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleWLAN128GMSection } from "./TitleWLAN128GMSection";
import { WirelessOptimization } from "./WirelessOptimization";
import { RoamingWifiWLAN128GM } from "./RoamingWifiWLAN128GM";
import { SpecificationsWLAN128GM } from "./SpecificationsWLAN128GM";
import { TechnicalspecificationsWLAN128GM } from "./TechnicalspecificationsWLAN128GM";

export function WLAN128GM() {
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleWLAN128GMSection />
           <WirelessOptimization />
           <RoamingWifiWLAN128GM />
           <SpecificationsWLAN128GM />
           <TechnicalspecificationsWLAN128GM />
        </Container>
    </div>
  );
}

import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftWLAN128GM() {
  const UserSpeedLimit = [
    {
      title: "Límite de velocidad del usuario:",
      detail: "Una tecla Activar/Desactivado, Grupo IP, Grupo horario, Arriba/Abajo, Remake",
    },
  ];
  const Firewall = [
    {
      title: "Cortafuegos:",
      detail: "Filtro IP, Nombre de la regla, Protocolo, TCP/UDP, Dirección IP, Buscar, Puerto externo/interno, Filtro MAC, Nombre, Grupo horario",
    },
  ];
  const FirewallContinue = [
    {
      detail: "Dirección Mac, Buscar dirección Mac, Filtro de URL, Dirección URL, Mapa del puerto, DMZ Host, Dirección IP DMZ",
    },
  ];
  const System = [
    {
      title: "Sistema:",
      detail: "Hora del sistema, Versión actual, Tipo de actualización, Actualización en línea",
    },
  ];
  const SystemContinue = [
    {
      detail: "Actualización local, Tiempo de actualización, Restaurar configuración predeterminada, Actualizar",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Componentes del dispositivo" />
      </Col>
      <ul>
        {UserSpeedLimit.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Firewall.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FirewallContinue.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {System.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {SystemContinue.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import { SubTitleProduct } from "../../../SubTitleProduct";

export function TechnologyPoEManagement({
  content,
  image,
  image2,
}: {
  content: string | undefined;
  image: string | undefined;
  image2: string | undefined;
}) {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Protector de reloj PoE eficiente Tecnología PoE Watch dog y gestión PoE" />
          <p>
            El switch alimentado por PoE, {content}, detectará y
            proporcionará toda la potencia a los PD conectados de forma
            adaptativa y automática. Además, cuenta con la tecnología PoE
            Watchdog que puede detectar automáticamente PD conectados y
            reiniciar los dispositivos bloqueados. Por ejemplo, cuando la
            función "PoE Watchdog" está activada, el Switch {content} detecta
            automáticamente la cámara frontal las 24 horas del día, los 7 días
            de la semana. Si no hay salida de tráfico de la cámara, se juzga que
            la cámara está congelada y la cámara frontal se reinicia a través de
            un fallo de alimentación PoE para resolver el problema del bloqueo
            de la cámara. No se requiere operación manual, y puede proporcionar
            soluciones oportunas y confiables para la operación y el
            mantenimiento posteriores del sistema.
          </p>
        </Col>
        <Col xs={6} className="mb-5 text-align-center">
          <img className="w-100" src={image} alt="image" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Soporte VLAN de vigilancia" />
          <p>
            La división VLAN {content} incluye VLAN de vigilancia, VLAN de voz,
            red MED (concesión de video) y VLAN de datos. Cuando se habilitan
            las funciones VLAN de vigilancia que garantizan la calidad del vídeo
            en tiempo real para la supervisión y el control sin comprometer la
            transmisión de datos de red convencionales.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img className="w-100" src={image2} alt="img" />
        </Col>
      </Row>
    </Container>
  );
}

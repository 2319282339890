import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2SNMPGSH2404M.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH2404M from "../../../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH2404M/GSH2404M.jpg";
import descriptionSwitchL2SNMPGSH2404M from "../../../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH2404M/descriptionSwitchL2SNMPGSH2404M.jpg";

export function TitleSectionL2SNMPGSH2404M() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch L2 Administrable 28 puertos Gigabit 24 puertos Gigabit + 4 puertos SFP" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="SNMP-GSH2404M" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH2404M}
            alt="Switch L2 Administrable 28 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Visión general" />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <p>
            El AirLive SNMP-GSH2404M es un switch Gigabit de 28 puertos
            gestionado capa L2+ que incluye 24 puertos RJ-45 y 4 puertos SFP
            Gigabit. Admite una función integral de administración SNMP, como
            IGMP EAPS Ring Network, VLAN Division, agregación de puertos,
            duplicación de puertos QoS, control de ancho de banda. Proporcionar
            la función de política de división de VLAN para establecer una mayor
            prioridad para voz o datos que eviten la pérdida o los retrasos del
            paquete. Además, todos los puertos funcionan a alta velocidad
            Gigabit; la función de autoaprendizaje de direcciones MAC garantiza
            una transmisión de datos sin errores; el modo de almacenamiento y
            reenvío evita que los paquetes dañados se inunden en la red; la
            función de control de flujo puede evitar el impacto de una gran
            cantidad de datos instantáneos en la red; el puerto admite la
            función de volteo automático MDI / MDIX, la transmisión de velocidad
            de línea sin bloqueo de todos los puertos.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2SNMPGSH2404M}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministrationAWOD30AXi.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import WaterproofABS from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD30AXi/WaterproofABS.jpg";
import AdvancedModeOperation from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AdvancedModeOperation.jpg";

export function AdvancedAdministrationAWOD30AXi() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Encapsulamiento ABS resistente a la intemperie" />
          <p>
            El AWOD-30AXi está diseñado con encapsulamiento ABS impermeable, a
            prueba de polvo y carcasa de protector solar, evita el daño por
            partículas nocivas y clima lluvioso. Por lo tanto, se adapta a
            varios entornos de clima, con una temperatura de operación de -30 °C
            a 70 °C. Altamente recomendado para servicios en campus y WISPs en
            todos los países.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img className="w-40" src={WaterproofABS} alt="Waterproof ABS" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="AP / repetidor avanzado PTP, modo PTMP o gestión en la nube" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Modo AP y repetidor:" />
          <p>
            En este modo, el AirLive AWOD-30AXi funciona como un AP o repetidor,
            lo que permite al instalador configurar una red que cubre un área de
            100 a 300 m (dependiendo del entorno). O construya una conexión de
            red a una distancia más larga entre varios edificios o cubra un área
            abierta más grande con varias unidades en modo repetidor.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Control en la nube:" />
          <p>
            El AirLive AWOD-30AXi se puede controlar a través de la nube (SOLO
            en modo AP), una vez que el AP se ha agregado a la nube, se puede
            controlar desde cualquier lugar. Esto ofrece beneficios adicionales
            cuando se trata de una gran red de puntos de acceso, ya que no se
            necesita acudir al sitio de instalación, y el soporte se puede dar
            desde una oficina remota. Además, se pueden otorgar múltiples
            niveles de control en la nube desde el administrador hasta solo los
            derechos de visualizar el estado de la red. Los AP se pueden agregar
            y eliminar en cualquier momento mediante la plataforma de
            administración en la nube airCloud.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AdvancedModeOperation}
            alt="Advanced Mode of Operation"
          />
        </Col>
      </Row>
    </Container>
  );
}

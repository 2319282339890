import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function DataTransferSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-4 mb-5">
          <HomeSubtitle title="Transferencia de datos" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            Su información, incluidos los Datos personales, puede transferirse y
            mantenerse en computadoras ubicadas fuera de su estado, provincia,
            país u otra jurisdicción gubernamental donde las leyes de protección
            de datos pueden diferir de las de su jurisdicción.
          </p>
          <p>
            Si se encuentra fuera y elige proporcionarnos información, tenga en
            cuenta que transferimos los datos, incluidos los Datos personales, y
            los procesamos allí.
          </p>
          <p>
            Su consentimiento a esta Política de privacidad seguido de su envío
            de dicha información representa su acuerdo con esa transferencia.
          </p>
          <p>
            AirLive tomará todas las medidas razonablemente necesarias para
            garantizar que sus datos se traten de forma segura y de acuerdo con
            esta Política de privacidad y no se realizará ninguna transferencia
            de sus Datos personales a una organización o país a menos que
            existan controles adecuados establecidos, incluida la seguridad de
            su datos y otra información personal.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftLive5GTII() {
    const HardwareDeviceInterface = [
        {
            title:"Hardware Interfaz del dispositivo:",
            detail: "5 x RJ45 de 10/100/1000 Mbps" 
        },
    ];
    const Standard = [
        {
            title:"Estándar:",
            detail: "IEEE802.3 Base-T" 
        },
        {
            detail: "IEEE802.3u 100Base-TX" 
        },
        {
            detail: "IEEE802.3ab Gigabit 1000Base-TX" 
        },
        {
            detail: "IEEE802,3 veces" 
        },
    ];
    const IndustryStandards = [
        {
            title:"Cumplimiento de los estándares de la industria:",
            detail: "FCC, CE, RoHS" 
        },
    ];
    const LedIndicators = [
        {
            title:"Indicadores LED:",
            detail: "PWR (fuente de alimentación), Link (enlace de red)" 
        },
    ];
    const Mechanic = [
        {
            title:"Aspectos Mecánicos:",
            detail: "Encapsulamiento de plástico" 
        },
    ];
    const SwitchArchitecture = [
        {
            title:"Arquitectura del switch | Rendimiento:",
            detail: "almacenar y reenviar" 
        },
        {
            detail: "Capacidad del switch: 10 Gbps" 
        },
        {
            detail: "Rendimiento: 7.44Mpps" 
        },
        {
            detail: "Dirección MAC: 2K" 
        },
        {
            detail: "Búfer de paquetes: 3Mbit" 
        },
        {
            detail: "Fotograma gigante: 15Kbytes" 
        },
        {
            detail: "VLAN: 4K" 
        },
        {
            detail: "Modo de transferencia: almacenar y reenviar, MTBF: 100000 hora" 
        },
    ];
  return (
    <Container>
      <ul>
        {HardwareDeviceInterface.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IndustryStandards.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LedIndicators.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Mechanic.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SwitchArchitecture.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}
import { Col, Container, Row } from "react-bootstrap";

export function CharacteristicsW6184QAXCE() {
  const Characteristics = [
    {
      detail:
        "Wi-Fi 6 2.4Ghz 40Mhz, 2 x 2 a 574Mbps, 90% más rápido que 11ac 1200",
    },
    {
      detail:
        "Wi-Fi 6 5Ghz 80Mhz, 2 x 2 a 1201Mbps, 40% más rápido que 11ac 1200",
    },
    {
      detail:
        "CPU avanzada Qualcomm Quad-Core y DDR3 256MB de alta memoria, admite una conexión estable de hasta 128 dispositivos",
    },
    {
      detail: "OFDMA + MU-MIMO + Range Boost + 1024QAM +OSBB",
    },
    {
      detail:
        "Varios usuarios pueden transmitir datos simultáneamente al mismo tiempo",
    },
    {
      detail: "Menor latencia en entornos saturados",
    },
    {
      detail: "Amplia cobertura",
    },
    {
      detail: "Mayor rendimiento y capacidad",
    },
    {
      detail: "Mayor rendimiento y capacidad",
    },
    {
      detail: "Acortar el tiempo de espera de las señales",
    },
    {
      detail: "Soporte IPTV / VLAN",
    },
    {
      detail: "Soporta el último protocolo de seguridad avanzado WPA3",
    },
    {
      detail:
        "Soporte de control parental (lista negra / blanca, control de URL)",
    },
    {
      detail: "Smart Easy MESH Roaming",
    },
    {
      detail: "VPN para el trabajo remoto",
    },
    {
      detail: "Precargar OpenWrt",
    },
  ];
  const functionalities = [
    {
      detail:
        "Estándar WiFi 6 11a / b / g / n / ax",
    },
    {
      detail:
        "2T2R 1800Mbps inalámbrico de alta velocidad (573Mbps + 1201Mbps)",
    },
    {
      detail:
        "procesador  Qualcomm IPQ6000 1.2Ghz 64-bit Quad Core CPU ",
    },
    {
      detail:
        "DDR3 de 256 MB de RAM y 128 MB de memoria Flash",
    },
    {
      detail:
        "Canal 20/40/80Mhz",
    },
    {
      detail:
        "Bandas duales simultáneas",
    },
    {
      detail:
        "1 x 10/100/1000mbps WAN",
    },
    {
      detail:
        "3 x 10/100/1000mbps LAN",
    },
    {
      detail:
        "2 x 2.4Ghz 5dBi antenas externas",
    },
    {
      detail:
        "Conexión a Internet estable para la multitarea",
    },
    {
      detail:
        "Servidor VPN",
    },
    {
      detail:
        "Soporte Easy Mesh",
    },
    {
      detail:
        "Soporte de IPTV",
    },
    {
      detail:
        "Precargar OpenWrt",
    },
    {
      detail:
        "Idioma : Inglés, Español",
    },
  ];
  return (
    <Container>
      <Row xs={12}>
        <Col className="mb-5">
          <ul>
            {Characteristics.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col className="mb-5">
          <ul>
            {functionalities.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftW6184QAXCE() {
  const MainChip = [
    {
      title: "Chip principal: ",
      detail: "Qualcomm IPQ6000, Quad Core, 64-bit",
    },
  ];
  const Flash = [
    {
      title: "Flash:",
      detail: "128MB",
    },
  ];
  const RAM = [
    {
      title: "RAM:",
      detail: "DDR3 256MB",
    },
  ];
  const Ethernet = [
    {
      title: "Ethernet (LAN):",
      detail: "3 x RJ45 10/100/1000mbps",
    },
  ];
  const WAN = [
    {
      title: "WAN:",
      detail: "1x RJ-45 10/100/1000mbps",
    },
  ];
  const WiFi = [
    {
      title: "WiFi:",
      detail:
        "2 x 802.11ax / ac / n 2T2R (2.4Ghz), 2 x 802.11ax/ac 2T2R (5Ghz)",
    },
  ];
  const Button = [
    {
      title: "Botón:",
      detail: "WPS / Reset x 1, MESH x 1, Power x 1",
    },
  ];
  const PowerInput = [
    {
      title: "Entrada de energía:",
      detail: "1 x 12VDC 1 o 1.5A aceptable",
    },
  ];
  const AntennaConnector = [
    {
      title: "Conector de antena:",
      detail: "2 x antena inalámbrica (5Ghz), 2 x antena inalámbrica (2.4Ghz)",
    },
  ];
  const WANWAN = [
    {
      title: "WAN-WAN:",
      detail: "PPPoE, DHCP, IP estática, puente transparente, Wi-Fi",
    },
  ];
  const Standard = [
    {
      title: "Estándar:",
      detail: "IEEE 802.11a/b/g/n/ax, 2x2, 20/40/80 MHz canales",
    },
  ];
  const Functions = [
    {
      title: "Funciones:",
      detail: "Multi-SSID, SSID oculto, aislamiento SSID",
    },
  ];
  const Security = [
    {
      title: "Seguridad:",
      detail: "WEP, WPA, WPA2, WPA3, WPA-PSK, 802.1x",
    },
  ];
  const Modulation = [
    {
      title: "Modulación:",
      detail: "tasas de modulación 1024-QAM",
    },
  ];
  const WiFiAdvanced = [
    {
      title: "Wi-fi Advanced:",
      detail:
        "Aislamiento de cliente inalámbrico, STBC, aceleración multimedia WMM",
    },
  ];
  const WirelessChannel2g = [
    {
      title: "2.4G:",
      detail: "1 ~ 13",
    },
  ];
  const WirelessChannel5g = [
    {
      title: "5.8G (canal CE):",
      detail: "36,40,44,48,52,56,60,64,100",
    },
    {
      detail: "108,112,116,120,124,128,132,136,140",
    },
  ];
  const WirelessPower = [
    {
      title: "Potencia TX inalámbrica - 2.4G:",
      detail: "20dbm MAX; 5.8G: TX 23dbm MAX",
    },
  ];
  const IPv6 = [
    {
      title: "IPv6:",
      detail: "Aplicaciones IPv6 RA/Prefix",
    },
  ];
  const FirewallFiltrado = [
    {
      title: "Firewall/Filtrado:",
      detail:
        "Nivel de firewall: alto, medio, bajo, Regla de filtro: filtro de URL/filtro de Mac, Filtrado de URL: Lista blanca/Lista negra.",
    },
  ];
  const DMZ = [
    {
      title: "DMZ:",
      detail: "DMZ",
    },
  ];
  const IPTV = [
    {
      title: "IPTV:",
      detail: "Soporte VLAN, IPv4, IPv6, LAN1/LAN2/LAN3",
    },
  ];
  const PortForwarding = [
    {
      title: "Regla/intervalo de reenvío de puertos:",
      detail: "TCP/UDP",
    },
  ];
  return (
    <Container>
      <Col className="mb-3">
        <SubTitleProduct content="Interfaz del dispositivo" />
      </Col>
      <ul>
        {MainChip.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Flash.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {RAM.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Ethernet.map((item) => (
          <li>
            <SubTitleProduct content={item.title} />
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WAN.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WiFi.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Button.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PowerInput.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {AntennaConnector.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WANWAN.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Standard.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Functions.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Security.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {Modulation.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WiFiAdvanced.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <Col className="mb-3">
        <SubTitleProduct content="Canal inalámbrico" />
      </Col>
      <ul>
        {WirelessChannel2g.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessChannel5g.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {WirelessPower.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {IPv6.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {FirewallFiltrado.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {DMZ.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {IPTV.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
      <ul>
        {PortForwarding.map((item) => (
          <li>
            <p>{item.detail}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { TechnicalspecificationsSNMPGSH2404M } from "./TechnicalspecificationsSNMPGSH2404M";
import { SpecificationsSNMPGSH2404M } from "./SpecificationsSNMPGSH2404M";

export function FeatureProductSNMPGSH2404M() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Technicalspecifications"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Technicalspecifications" title="Especificaciones">
                        <TechnicalspecificationsSNMPGSH2404M />
                    </Tab>
                    <Tab eventKey="Specifications" title="Especificaciones">
                        <SpecificationsSNMPGSH2404M />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}

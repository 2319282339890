import { Container, Row, Col } from "react-bootstrap";
import styles from "./SectionFunctionalitiesAWOD30AXi.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import OmniAntenna from "../../../../../../../../assets/imagesproduct/AircloudProducts/AWOD12ACi/OmniAntenna.jpg";

export function SectionFunctionalitiesAWOD30AXi() {
  const LeftFunctionalities = [
    {
      detail: "Cumple con 802.11 A/B/G/N/AC/AX",
    },
    {
      detail: "Carcasa IP 67",
    },
    {
      detail: "Antena omnidireccional incorporada de 8 dBi",
    },
    {
      detail: "Potencia de 27dBm",
    },
    {
      detail: "Control de la nube en modo AP",
    },
  ];
  const RightFunctionalities = [
    {
      detail: "2.4/5Ghz AC 3000Mbps",
    },
    {
      detail: "1 x WAN de 10/100/1000 Mbps",
    },
    {
      detail: "1 puerto de consola de 10/100/1000 Mbps",
    },
    {
      detail: "Entrada de alimentación PoE (WAN) IEEE 802.3af de 48 V",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {LeftFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {RightFunctionalities.map((item) => (
              <li>
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="Frecuencia de 5 GHz, más canales, menor latencia, mayor estabilidad" />
          <p>
            El AP AWOD-30AXi soporta la banda completa de 5 Ghz de 5150 ~ 5850
            MHz, es decir, más de 200 canales disponibles, lo cual permite
            evitar el problema de interferencia de frecuencia, asegurando la
            eficiente transmisión de datos con alta velocidad, bajo retardo y
            alta estabilidad, ideal para locaciones dfíciles con congestión de
            redes wifi.
          </p>
        </Col>
        <Col xs={6} md={6} className="mb-3">
          <SubTitleProduct content="Omni Antenna de largo alcance." />
          <p>
            El AWOD-30AXi tiene un área de cobertura de entre 100 y 300 metros,
            dependiendo del entorno.
          </p>
          &nbsp;
          <p>
            Puede cubre un área muy amplia con tu señal Wi-Fi, mediante la
            combinación de una eficiente antena y la alta potencia de salida.
          </p>
        </Col>
        <Col xs={6} md={6} className="mb-5">
          <img
            className={styles.image}
            src={OmniAntenna}
            alt="Omni Antenna de largo alcance"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftIGM642BT125G() {
  const L2ManagementExtension = [
    {
      title: "Función de extensión de gestión L2:",
      detail: "ACL (Lista de control de acceso): Basado en MAC de origen",
    },
    {
      detail:
        "MAC de destino, tipo de protocolo, IP de origen, IP de destino, puerto L4",
    },
    {
      detail: "Soporte de gestión de tiempo de rango de tiempo",
    },
  ];
  const QoS = [
    {
      title: "QoS:",
      detail: "Basado en la clasificación 802.1p (CoS)",
    },
    {
      detail: "Basado en la clasificación DSCP",
    },
    {
      detail:
        "Clasificación basada en IP de origen, IP de destino y puerto número",
    },
    {
      detail: "Soporte SP, estrategia de programación WRR y DRR",
    },
    {
      detail: "Límite de caudal de soporte CAR",
    },
  ];
  const DiscoveryLLDP = [
    {
      title: "Descubrimiento de LLDP: ",
      detail: "Soporta el protocolo de descubrimiento LLDP",
    },
  ];
  const UserSettings = [
    {
      title: "Configuración del usuario: ",
      detail: "AGREGAR/Eliminar usuarios",
    },
    {
      detail:
        "Acceso de usuario: Inicio de sesión de usuario, operación, estado, eventos",
    },
  ];
  const AntiAttack = [
    {
      title: "Antiataque (Seguridad):",
      detail: "Defensa DOS (basada en puertos) ",
    },
    {
      detail:
        "Admite la protección de la CPU y limita la velocidad de envío de paquetes de CPU",
    },
    {
      detail: "Enlace ARP (enlace IP, MAC, PORT)",
    },
  ];
  const CertificationAuthentication = [
    {
      title: "Certificación | Autenticación:",
      detail: "Admite autenticación de puerto 802.1x",
    },
    {
      detail: "Compatibilidad con la autenticación AAA",
    },
    {
      detail: "Soporta autenticación TACACS+",
    },
  ];
  const NetworkDiagnostics = [
    {
      title: "Diagnóstico de red:",
      detail: "Soporte Ping, Telnet, Trace",
    },
  ];
  const SystemAdministration = [
    {
      title: "Administración del sistema:",
      detail:
        "Restablecimiento del dispositivo, guardado/restauración de la configuración, administración de actualizaciones, configuración de fecha y hora, etc.",
    },
    {
      detail: "CLI: Soporte de gestión de línea de comandos de puerto serie",
    },
    {
      detail: "SSH: Soporte de administración remota SSHv1 / 2",
    },
    {
      detail: "Telnet: Soporte de administración remota de Telnet",
    },
    {
      detail: "WEB: Soporte de configuración de capa 2",
    },
    {
      detail: "SNMP: Soporte V1/V2/V3; captura de soporte",
    },
    {
      detail: "PoE: Soporte de alimentación PoE",
    },
    {
      detail: "Otras funciones, Soporte DHCP Snooping, Alerta de caída (Dying Gasp)",
    },
  ];
  const RouterFunctionL3 = [
    {
      title: "L3 (conmutación de capa 3) y función de enrutador:",
      detail: "Configuración de la interfaz: Soporte de interfaz VLAN virtual",
    },
    {
      detail: "ARP: Comprobación de soporte ARP",
    },
    {
      detail: "Función de enrutador: Router estático",
    },
  ];
  return (
    <Container>
      <ul>
        {L2ManagementExtension.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {QoS.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DiscoveryLLDP.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {UserSettings.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {AntiAttack.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <SubTitleProduct content="Puerto PoE: 48" />
      <ul>
        {CertificationAuthentication.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {NetworkDiagnostics.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SystemAdministration.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {RouterFunctionL3.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive8GTII.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive8GTII } from "./SpecificationsLeftLive8GTII";
import { SpecificationsRightLive8GTII } from "./SpecificationsRightLive8GTII/SpecificationsRightLive8GTII";

export function FeatureProductLive8GTII() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-8GT II" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive8GTII />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive8GTII />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}
import { Container, Row, Col } from "react-bootstrap";
import styles from "./GeneralcharacteristicsSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import AirMax5XIIDescription from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/AirMax5XIIDescription.jpg";
import BottomAirMax5XII from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveAirMax5XII/BottomAirMax5XII.jpg";

export function GeneralcharacteristicsSection() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Access Point Wi-Fi 5Ghz N300 para exteriores" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="AirMax5X II" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={AirMax5XIIDescription}
            alt="Access Point Wi-Fi 5Ghz N300 para exteriores"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <SubTitleProduct content="AP / repetidor para exteriores de alto rendimiento de 5 GHz CPE" />
          <p>
            El AirMax5X II es un AP/repetidor/CPE para exteriores de 300 Mbps de
            alto rendimiento construido con los más altos estándares. Cuenta con
            antena MIMO de doble polaridad para garantizar una recepción óptima.
            El PoE pasivo PoE de 48 V se presenta para permitir una larga
            distancia de cableado
          </p>
          &nbsp;
          <SubTitleProduct content="Diseño profesional para exteriores + protección interna contra rayos, adecuado para múltiples entornos hostiles" />
          <p>
            Posee una carcasa de plástico ABS para exteriores y componentes
            profesionales de protección contra rayos, proporcione IP65 a prueba
            de agua, a prueba de polvo, adecuado para múltiples entornos
            hostiles. incluso en ambientes extremadamente cálidos y fríos (-30
            °C ~ +70 °C), puede operar de manera estable.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={BottomAirMax5XII}
            alt="Bottom of AirMax5XII"
          />
        </Col>
      </Row>
    </Container>
  );
}

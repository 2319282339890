
import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsPOEGSH1602M200.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftPOEGSH1602M200 } from "./SpecificationsLeftPOEGSH1602M200/SpecificationsLeftPOEGSH1602M200";
import { SpecificationsRightPOEGSH1602M200 } from "./SpecificationsRightPOEGSH1602M200/SpecificationsRightPOEGSH1602M200";

export function SpecificationsPOEGSH1602M200() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive POE-GSH1602M-200" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftPOEGSH1602M200 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightPOEGSH1602M200 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

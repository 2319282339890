import { createBrowserRouter } from "react-router-dom";
import {
  Home,
  Solutions,
  News,
  Drivers,
  Videos,
} from "../components/Pages";
import { AboutUsRoutes, ProductsRoutes, MarketingRoutes } from "../components/Pages";

export function CreateRoutes() {
  return createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/products/*",
      element: <ProductsRoutes />,
    },
    {
      path: "/solutions",
      element: <Solutions />,
    },
    {
      path: "/news",
      element: <News />,
    },
    {
      path: "/marketing",
      element: <MarketingRoutes />,
    },
    {
      path: "/videos",
      element: <Videos />,
    },
    {
      path: "/drivers",
      element: <Drivers />,
    },
    {
      path: "/AboutUs/*",
      element: <AboutUsRoutes />,
    },
  ]);
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsAWOD12ACi.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftAWOD12ACi } from "./SpecificationsLeftAWOD12ACi";
import { SpecificationsRightAWOD12ACi } from "./SpecificationsRightAWOD12ACi";

export function SpecificationsAWOD12ACi() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="AWOD-12ACi" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftAWOD12ACi />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightAWOD12ACi />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalRightPOEGSH1602M200() {
  const L2Management = [
    {
      title: "Funciones de administración L2/L2+",
      detail: "Gestión de Puertos: Habilitar / Deshabilitar puerto, Ajuste de velocidad, dúplex",
    },
    {
      detail: "MTU, Control de flujo, Comprobación de la información del puerto",
    },
  ];
  const PortMirroring = [
    {
      title: "Duplicación de puertos: ",
      detail:
        "Admite la duplicación de puertos laterales",
    },
    {
      detail:
        "Límite de velocidad del puerto: Administración del ancho de banda de entrada/salida por puertos",
    },
  ];
  const PortIsolation = [
    {
      title: "Aislamiento de puertos: ",
      detail:
        "Admite el aislamiento del puerto de enlace descendente ",
    },
    {
      detail:
        "puede comunicarse con el puerto de enlace ascendente",
    },
  ];
  const StormSuppression = [
    {
      title: "Supresión de tormentas:",
      detail: "Soporta unidifusión desconocida, multidifusión, multidifusión desconocida",
    },
    {
      detail: "Supresión de tormentas de tipo broadcast",
    },
    {
      detail: "Supresión de tormentas basada en la regulación del ancho de banda y el filtrado de tormentas",
    },
  ];
  const LinkAggregation = [
    {
      title: "Agregación de enlaces:",
      detail:
        "Admite agregación manual estática",
    },
    {
      detail: "Soporte de agregación dinámica LACP",
    },
    {
      detail: "Soporte basado en IP, MAC, modos mixtos de equilibrio de carga",
    },
    {
      detail: "Soporta un máximo de 8 grupos de agregación (8 puertos por grupo de agregación)",
    },
  ];
  const VLAN = [
    {
      title: "VLAN:",
      detail: "Soporta VLAN 4K",
    },
    {
      detail: "Acceso, Maletero, Híbrido",
    },
    {
      detail: "Admite VLAN 802.1Q BASADA EN PUERTOS, VLAN MAC, VLAN IP",
    },
    {
      detail: "Admite el registro de VLAN dinámica GVRP genérica",
    },
    {
      detail: "VLAN de voz",
    },
  ];
  const MacAddress = [
    {
      title: "Dirección MAC:",
      detail:
        "Soporte estático, dinámico, filtrado",
    },
    {
      detail: "Límite de aprendizaje de direcciones MAC",
    },
    {
      detail: "Ajuste dinámico del tiempo de envejecimiento",
    },
  ];
  const SpanningTree = [
    {
      title: "Árbol de expansión:",
      detail: "Soporta el protocolo STP 802.1d Spanning Tree",
    },
    {
      detail: "Soporta el protocolo RSTP 802.1w Spanning Tree",
    },
    {
      detail: "Soporta el protocolo MSTP 802.1w Spanning Tree",
    },
    {
      detail: "Admite instancias múltiples, admite interfaz agregada",
    },
    {
      detail: "Soporta protección BPDU",
    },
  ];
  const Multicast = [
    {
      title: "Multidifusión:",
      detail: "Soporta  IGMP v1/v2/v3 Snooping",
    },
    {
      detail: "Soporta MLD v1/v2 Snooping",
    },
    {
      detail: "Soporta Layer 2 multicast fast leave mechanism, querier",
    },
    {
      detail: "Soporta Layer 2 IPv4 static multicast",
    },
    {
      detail: "Soporta Layer 2 IPv6 static multicast",
    },
    {
      detail: "Soporta IGMP v1/v2/v3 Layer 3 multicast",
    },
  ];
  const Layer3Switching = [
    {
      title: "DDM:",
      detail: "Soporta SFP DDM ",
    },
    {
      title: "Conmutación de capa 3:",
      detail: "Enrutamiento estático IPv4",
    },
    {
      detail: "Enrutamiento estático IPv6",
    },
    {
      detail: "Servidor DHCP",
    },
  ];
  return (
    <Container>
      <ul>
        {L2Management.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortMirroring.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortIsolation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StormSuppression.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LinkAggregation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {MacAddress.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SpanningTree.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Multicast.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Layer3Switching.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./Technicalspecifications.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftXGS4804 } from "./SpecificationsLeftXGS4804";
import { SpecificationsRightXGS4804 } from "./SpecificationsRightXGS4804";

export function Technicalspecifications() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3POE-XGS4804" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftXGS4804 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightXGS4804 />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2POEGSH800120BT.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH800120BT from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH800120BT/GSH800120BT.jpg";
import descriptionSwitchL2POEGSH800120BT from "../../../../../../../../assets/imagesproduct/SwitchL2/POEGSH800120BT/descriptionSwitchL2POEGSH800120BT.jpg";

export function TitleSectionL2POEGSH800120BT() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch 8 puertos Gigabit POE, 8 Puertos Gigabit incl 8x30W PoE (puerto 1~2 60W)" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="POE-GSH800-120BT" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH800120BT}
            alt="Switch 8 puertos Gigabit POE, 8 Puertos Gigabit incl 8x30W PoE"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Características generales" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch 802.3af/at/bt PoE+ Gigabit, con Puerto #1~2 Hi PoE 60W" />
          <p>
            El switch Gigabit AirLive POE-GSH800-120-BT es compatible con
            802.3af(15W)/802.3at(30W)/802.3bt(60W). Soporta 8 puertos PoE. El
            total de potencia PoE es de 120W. Los puertos 1 y 2 admiten PoE de
            alta potencia de hasta 60 W que satisfacen equipos de alta potencia
            necesarios, como PC delgada, LED PoE, IPCAM de alta potencia, etc.
          </p>
          &nbsp;
          <SubTitleProduct content="Switch de 8 puertos Gigabit PoE+ con VLAN" />
          <p>
            El switch AirLive POE-GSH800-120-BT es un switch PoE no administrado
            plug and play con VLAN y extensión de cableado de 250 metros, que
            puede configurar fácilmente con su interruptor DIP. Cuando el modo
            VLAN o el modo Extender están habilitados, la función PoE Watchdog
            también estará activa.
          </p>
        </Col>
        <Col xs={8} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2POEGSH800120BT}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

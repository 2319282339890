import { Container, Row, Col } from "react-bootstrap";
import styles from "../SupportPrincipal/SupportPrincipal.module.scss";
import supports from "../../../../../assets/images/supports.jpg";
import synchrony from "../../../../../assets/images/synchrony.jpg";
import { HomeSubtitle } from "../../../../HomeSubtitle";

export function SupportPrincipal() {
  return (
    <Container fluid className={styles.banner}>
      <Container className="py-4">
        <Row>
          <Col xs={12} className="order-1 mb-4">
            <HomeSubtitle title="Soporte" />
          </Col>
          <Col xs={12} md={6} className="order-3 order-md-2">
            <p>
              <strong>Global Access WLL C.A.</strong> proveemos la atención y
              soporte para todas las soluciones y plataformas que suministramos,
              con los niveles de servicio acordados con nuestros clientes.
            </p>
            &nbsp;
            <p>
              La clave para mantener las plataformas de servicio activas en
              forma óptima y al menor costo, es contar con la atención y el
              soporte de mantenimiento a tiempo. Contáctanos para conocer sus
              requerimientos y necesidades y le asesoramos sobre cómo optimizar
              el uso de sus redes, migraciones, actualizaciones tecnológicas,
              implementación de nuevos servicios, contratos de servicio de
              mantenimiento, etc.{" "}
            </p>
            &nbsp;
            <p>
              Todas las soluciones y productos suministrados, están cubiertas
              por la garantía del fabricante y nuestra atención y servicio como
              distribuidor autorizado.{" "}
            </p>
            &nbsp;
            <img src={supports} className={styles.img_support} alt="supports" />
            &nbsp;
            <img
              src={synchrony}
              className={styles.img_support}
              alt="synchrony"
            />
          </Col>
          <Col xs={12} md={6} className="order-2 order-md-3 mb-3 mb-md-0">
            <Col className={styles.description}>
              <Col xs={12} className="mb-4">
                <HomeSubtitle title="Estas son las ventajas que podemos lograr con nuestra atención y soporte de redes que pueden mejorar su negocio:" />
              </Col>
              <Col xs={12} className="mb-3">
                <p>
                  <div className={styles.subTitle}>
                    Aumento de la productividad
                  </div>{" "}
                  Una infraestructura de red fiable y bien mantenida es
                  fundamental. Permite a los usuarios acceder a lo que necesitan
                  de manera más efectiva, aumentando la productividad.
                </p>
                &nbsp;
                <p>
                  <div className={styles.subTitle}> Mejor ciberseguridad</div>{" "}
                  Los ciberataques y las filtraciones de datos pueden tener un
                  impacto devastador en una empresa. Los servicios pueden
                  proteger los activos digitales con medidas de seguridad como
                  firewalls y detección de amenazas.
                </p>
                &nbsp;
                <p>
                  <div className={styles.subTitle}>
                    Reducción del tiempo de inactividad
                  </div>{" "}
                  La gestión proactiva puede identificar y resolver problemas
                  antes de que causen problemas. Eso puede reducir el tiempo de
                  inactividad y mantener el negocio funcionando sin problemas.
                </p>
                &nbsp;
                <p>
                  <div className={styles.subTitle}>
                    Ahorro de gastos operativos
                  </div>{" "}
                  La gestión de la red puede ayudar a minimizar los costes
                  operativos y la pérdida de productividad.
                </p>
                &nbsp;
                <p>
                  <div className={styles.subTitle}>
                    Mayor satisfacción del usuario
                  </div>{" "}
                  Una red rápida y confiable conduce a una mayor felicidad para
                  todos los que la usan.
                </p>
                &nbsp;
                <p>
                  <div className={styles.subTitle}>Escalabilidad</div> podemos
                  ayudar a las empresas a escalar su red con crecimiento. Eso
                  puede implicar agregar nuevas ubicaciones, usuarios y
                  dispositivos.
                </p>
                &nbsp;
                <p>
                  <div className={styles.subTitle}>Cumplimiento</div> Los
                  proveedores de servicios pueden garantizar que la TI de una
                  organización cumpla con los estándares de cumplimiento
                  normativo.
                </p>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import { LinkItem, Sidebar } from "../../../../Sidebar";

export function SidebarAboutUs() {
    const links: LinkItem[] = [
      {
        name: "Empresa",
        path: "/AboutUs",
      },
    ];
  
    return (
        <Sidebar title="Nosotros" links={links} />
    );
  }
  
import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsPOEGSH1602M200() {
    const Feature = [
        {
          detail: "Todos los puertos ofrecen alta velocidad Gigabit",
        },
        {
          detail: "Dos (2) ranuras SFP que aceptan transceptores de fibra para extender la red Ethernet, además de 2 ranuras RJ45 adicionales",
        },
        {
          detail: "Potencia PoE alta de 200 W, garantiza la máxima potencia del dispositivo conectado ",
        },
        {
          detail: "Detecte y ofrezca energía automáticamente para los DP conectados",
        },
        {
          detail: "Soporta administración inteligente L2 + SNMP y WEB, que incluye DHCP Snooping, VLAN, IGMP, QoS, árbol de expansión, enlace MAC y control de ancho de banda",
        },
        {
          detail: "Prioridad de VLAN de vigilancia y compatibilidad con VLAN de voz para transmisión de alta prioridad",
        },
        {
          detail: "Compatibilidad con la gestión de PoE ",
        },
        {
          detail: "Admite el guardián PoE para detectar y reiniciar automáticamente los PD bloqueados",
        },
        {
          detail: "Borrar la visualización de estatuas, incluido el tráfico, la CPU, la memoria, el consumo de POE y el estado por puerto",
        },
        {
          detail: "Interfaz de usuario web para una fácil administración; CLI y Command Script para configuración avanzada; SNMP se utiliza para la gestión de herramientas de red populares",
        },
    ];
    const NotableSpecifications = [
        {
            detail: "16 puertos de 30W PoE 10/100/1000" 
        },
        {
            detail: "2 puertos de 1000 SFP Enlace ascendente " 
        },
        {
            detail: "2 puertos de 1000 RJ45 Enlace ascendente" 
        },
        {
            detail: "Cumple con 802.3at/802.3af" 
        },
        {
            detail: "Cada puerto hasta 30 vatios; Potencia PoE 200W" 
        },
        {
            detail: "Protección ESD contra sobretensiones de iluminación: General 4KV, Diferencial 2KV, ESD 8KV aire, contacto 6KV " 
        },
        {
            detail: "L2+ SNMP v1/2/3, trampa" 
        },
        {
            detail: "Gestión de PoE, Perro guardián de PoE" 
        },
        {
            detail: "Autenticación: 802.1x, AAA, TACACS+" 
        },
        {
            detail: "SNMP, WEB, CLI, SSH, Telnet, RMON" 
        },
        {
            detail: "División VLAN (Vigilancia, Voz, Conferencia MED, Datos)" 
        },
        {
            detail: "DHCP Snooping evita la instalación de routers no autorizados" 
        },
        {
            detail: "Soporta Ethernet de bajo consumo 802.3az" 
        },
        {
            detail: "Diseño sin ventilador" 
        },
    ];
  return (
    <Container>
        <Row>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Características" />
            </Col>
            <Col md={6} className="mb-3">
                <HomeSubtitle title="Especificaciones resaltantes" />
            </Col>
        </Row>
        <Row>
            <Col md={5} className="mb-5">
                <ul>
                    {Feature.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
            <Col md={5} className="mb-5">
                <ul>
                    {NotableSpecifications.map((item) => (
                        <li>
                            <p>{item.detail}</p>
                        </li>
                    ))}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2Live16GTMini.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L16GTMini from "../../../../../../../../assets/imagesproduct/SwitchL2/Live16GTMini/L16GTMini.jpg";
import descriptionSwitchL2Live16GTMini from "../../../../../../../../assets/imagesproduct/SwitchL2/Live16GTMini/descriptionSwitchL2Live16GTMini.jpg";

export function TitleSectionL2Live16GTMini() {
  const Feature = [
    {
      detail: "16 puertos de alta velocidad Gigabit",
    },
    {
      detail: "Alarma de bucle",
    },
    {
      detail: "Prioridad de datos de QoS (puerto 1~8)",
    },
    {
      detail: "Modo VLAN: Seguridad mejorada",
    },
    {
      detail: "Modo extendido: hasta 250 metros",
    },
    {
      detail: "Ajuste sencillo mediante interruptor DIP",
    },
    {
      detail: "Protección contra sobretensiones; Protección ESD",
    },
  ];
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch SOHO de 16 puertos Gigabit, VLAN, QoS, Plug and Play" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Live-16GT-Mini" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L16GTMini}
            alt="Switch SOHO de 16 puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Más que un switch Plug-and-Play de 16 puertos, es un switch VLAN, QoS y conexión UTP de larga distancia soportada" />
          <p>
            El 16GTMini es un switch Gigabit no administrado, plug-and-play, con
            un precio muy asequible. Además, proporciona funciones adicionales y
            útiles, como la seguridad de VLAN y la extensión de cable, que puede
            configurar fácilmente mediante su interruptor DIP. También soporta
            prioridad de datos QoS y reporte de alarma de bucle. Es fácil de
            utilizar y mantener con su diseño sin ventilador y su pequeño
            tamaño, y se puede usar ampliamente en el hogar, la oficina SOHO o
            como conmutador de cliente para PYMES.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <SubTitleProduct content="Características" />
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2Live16GTMini}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

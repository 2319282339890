import { useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./POEGSH822120.module.scss";
import { SidebarProducts } from "../../../SidebarProducts";
import { TitleSectionL2POEGSH822120 } from "./TitleSectionL2POEGSH822120";
import { CharacteristicsPOEGSH822120 } from "./CharacteristicsPOEGSH822120";
import { EfficientPoETechnology } from "../EfficientPoETechnology";
import { FeatureProductPOEGSH822120 } from "./FeatureProductPOEGSH822120";
import PortsPoe from "../../../../../../../assets/imagesproduct/SwitchL2/PortsPoe.jpg";
import FiberOpticPOE from "../../../../../../../assets/imagesproduct/SwitchL2/FiberOpticPOE.jpg";

export function POEGSH822120() {
  useEffect(() => {
    window.scrollTo({top: 0, left: 0});
  }, []);
  return (
    <div className={styles.container}>
        <SidebarProducts />
        <Container>
           <TitleSectionL2POEGSH822120 />
           <CharacteristicsPOEGSH822120 />
           <EfficientPoETechnology content={"POE-GSH822-120"} image={PortsPoe} image2={FiberOpticPOE} numberports={"1 - 8"} numberports2={"7 y 8"} TextPoeWatchdog={". PoE Watchdog también estará habilitado."}/>
           <FeatureProductPOEGSH822120 />
        </Container>
    </div>
  );
}

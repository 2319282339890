import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductISFPLX125G20KMSMFBIDI.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftISFPLXGMSMFBIDI } from "./SpecificationsLeftISFPLXGMSMFBIDI/SpecificationsLeftISFPLXGMSMFBIDI";
import { SpecificationsRightISFPLXGMSMFBIDI } from "./SpecificationsRightISFPLXGMSMFBIDI/SpecificationsRightISFPLXGMSMFBIDI";

export function FeatureProductTransceiverISFPLXGMSMFBIDI() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive ISFP-LX-1.25G-20KM-SMF-BIDI A&B" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftISFPLXGMSMFBIDI />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightISFPLXGMSMFBIDI />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsXponONU1GE.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftXponONU1GE } from "./SpecificationsLeftXponONU1GE";
import { SpecificationsRightXponONU1GE } from "./SpecificationsRightXponONU1GE";

export function SpecificationsXponONU1GE() {
  return (
    <Container>
      <Row xs={12}>
        <Col className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col className="mb-3">
          <HomeSubtitle title="XPON 2.5GE XPON ONU" />
        </Col>
      </Row>
      <Row xs={12}>
        <Col className={styles.LeftList}>
            <SpecificationsLeftXponONU1GE />
        </Col>
        <Col className={styles.RightList}>
            <SpecificationsRightXponONU1GE />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

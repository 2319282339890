import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsLeftXponONU1GE() {
  const PONPort = [
    {
      title: "Interfaces del dispositivo Puerto PON:",
      detail: "1x (EPON PX20+ y GPON Clase B+)",
    },
  ];
  const Connector = [
    {
      title: "Conector:",
      detail: "modo único SC, SC/UPC",
    },
  ];
  const OpticalPowerTX = [
    {
      title: "TX potencia óptica:",
      detail: "0 ~ + 4dBm",
    },
  ];
  const XraySensitivity = [
    {
      title: "Sensibilidad RX:",
      detail: "-27dBm",
    },
  ];
  const OpticalPowerOverload = [
    {
      title: "Sobrecarga de potencia óptica:",
      detail: "-3dBm (EPON) o - 8dBm (GPON)",
    },
  ];
  const Wavelength = [
    {
      title: "Longitud de onda:",
      detail: "TX 1310nm, RX 1490nm",
    },
  ];
  const TransmissionDistance = [
    {
      title: "Distancia de transmisión:",
      detail: "20KM",
    },
  ];
  const LANPort = [
    {
      title: "Puerto LAN:",
      detail: "1x 1G RJ-45",
    },
  ];
  const Feeding = [
    {
      title: "Alimentación:",
      detail: "12V, adaptador de alimentación externo de 0.5A",
    },
  ];
  const LED = [
    {
      title: "LED:",
      detail: "REG, LINK/ACT, SYS",
    },
  ];
  const FunctionalCharacteristics = [
    {
      title: "Características funcionales:",
    },
    {
      title: "XPON:",
      detail: "modo dual, acceso automático a EPON/GPON OLT",
    },
    {
      title: "Modo de enlace ascendente:",
      detail: "modo de puente y enrutamiento",
    },
    {
      title: "Protección anormal:",
      detail: "Detección de Rogue ONU, Hardware Dying Gasp",
    },
    {
      title: "Firewall:",
      detail: "DDOS, filtrado basado en ACL/MAC/URL",
    },
  ];
  const FirmwareFeatures = [
    {
      title: "Características del firmware:",
    },
    {
      title: "Básico:",
      detail: "Soporte MPCP discover&register",
    },
    {
      detail: "Soporte de autenticación Mac / Loid / Mac + Loid",
    },
    {
      detail: "Soporte Triple Churning",
    },
    {
      detail: "Soporte de ancho de banda DBA",
    },
    {
      detail:
        "Soporte de detección automática, configuración automática, y actualización automática del firmware",
    },
    {
      detail: "Soporte SN / PSW / Loid / Loid + Psw",
    },
    {
      detail: "Autenticación",
    },
  ];
  const LAN = [
    {
      title: "LAN:",
      detail: "Limitación de la velocidad del puerto de soporte",
    },
    {
      detail: "Soporte de detección de bucle",
    },
    {
      detail: "Soporte de control de flujo",
    },
    {
      detail: "Soporte de control de tormentas",
    },
  ];
  const VLAN = [
    {
      title: "VLAN:",
      detail: "Soporte de modo de etiqueta VLAN",
    },
    {
      detail: "Soporte VLAN modo transparente",
    },
    {
      detail: "Soporte VLAN modo troncal (máximo 8 vlans)",
    },
    {
      detail: "Soporte VLAN 1: 1 modo de traducción (≤8 vlans)",
    },
    {
      detail: "Detección automática de VLAN",
    },
  ];
  const Alarm = [
    {
      title: "Alarma:",
      detail: "Apoyo Dying Gasp",
    },
    {
      detail: "Soporte Port Loop Detect",
    },
    {
      detail: "Soporte Eth Port Loss",
    },
  ];
  return (
    <Container>
      <ul>
        {PONPort.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Connector.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OpticalPowerTX.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {XraySensitivity.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OpticalPowerOverload.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Wavelength.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {TransmissionDistance.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LANPort.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Feeding.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LED.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {FunctionalCharacteristics.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {FirmwareFeatures.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Alarm.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import L3POE from "../../../../../../../../assets/imagesproduct/SwitchL3/switchL3POE600/L3POE.jpg";
import descriptionSwitch from "../../../../../../../../assets/imagesproduct/SwitchL3/switchL3POE600/descriptionSwitch.jpg";

export function TitleSection() {
  return (
    <Container>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Switch L3 850W Administrable de 48 puertos Gigabit PoE+ con 4 puertos 10G SFP" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <SubTitleProduct content="L3POE-XGS4804-850 BT" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={L3POE}
            alt="Switch L3 850W Administrable de 48 puertos Gigabit PoE+ con 4 puertos 10G SFP"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Descripción" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Funcionalidad capa 3" />
          <p>
            El L3POE-XGS4804 es un switch capa 3 que ofrece hardware de alto
            rendimiento con alimentación PoE 802.3bt de 90W en los puertos 1~4 y
            30W en los puertos restantes, hasta un total de 780 W PoE más la
            potencia de uso de intrínseca del equipo. Soporta enrutamiento
            IPV4/IPV6 estático y enrutamiento dinámico OSPF y RIP mediante el
            intercambio de información de enrutamiento con otros switches y
            enrutadores de capa 3. Con la serie L3POE-XGS4804, los clientes
            podrían lograr fácilmente una ruta basada en políticas (PBR), que es
            importante cuando necesitan un switch para cambiar de aplicación y
            un tiempo de recuperación de red corto.
          </p>
          &nbsp;
          <p>
            La potencia de enrutamiento del L3POE-XGS4804 le permite realizar
            funcionalidades de capa 3, inspeccionar los paquetes entrantes y
            tomar decisiones de enrutamiento dinámico OPF, así como rutas
            estáticas IPV4/IPB6 con una latencia de red mucho más baja que un
            enrutador típico, ya que los paquetes no tienen que realizar pasos
            adicionales a través de un enrutador.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitch}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import styles from "./AdvancedAdministrationU618AX.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import CloudControl from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveinWallU618AX/CloudControl.jpg";
import LiveTrafficStatusMonitoring from "../../../../../../../../assets/imagesproduct/AircloudProducts/AirLiveinWallU618AX/LiveTrafficStatusMonitoring.jpg";

export function AdvancedAdministrationU618AX() {
  return (
    <Container>
      <Row>
        <Col className="mb-3 mt-5">
          <HomeSubtitle title="Administración avanzada " />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Operación flexible: administración central, administración en la nube, AP independiente" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive InWall-18AX puede operar como AP FAT o AP FIT. Es decir,
            puede funcionar como un punto de acceso independiente o puerta de
            enlace para el hogar o WLAN de oficina, así como a gran escala para
            grandes empresas u organizaciones, como Hotel, el InWall-U618AX
            controlado por medio de un Controlador de APs AirLive o AirCloud
            gestionado centralizadamente y en la nube.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <HomeSubtitle title="Gestión central y en la nube 24/7 365 para puntos de acceso ilimitados a través de AirCloud " />
          <Col className="mt-3">
            <SubTitleProduct content="Cloud Control:" />
          </Col>
          <p>
            el AirLive InWall-U168AX se puede gestionar a través de AirCloud una
            vez que se ha añadido a la nube. Esto proporciona control remoto
            desde cualquier ubicación, lo que lo hace ventajoso para grandes
            redes de puntos de acceso, ya que el acceso local no es necesario y
            el soporte se puede proporcionar desde una oficina remota. Además,
            se pueden asignar múltiples niveles de control en la nube, que van
            desde los derechos de administrador hasta los derechos de
            espectador. Los puntos de acceso se pueden agregar o eliminar
            fácilmente en cualquier momento.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={CloudControl}
            alt="Cloud Control"
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Gestión centralizada mediante el Controlador AirLive WLAN-128GM y WLAM-64GM" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Fit mode:" />
          <p>
            es una configuración para el punto de acceso InWall-U168AX
            que permite que sea controlado por un controlador de acceso AirLive
            WLAN. El controlador WLAN administra todas las configuraciones
            inalámbricas, cifrado y otras funciones, sirviendo como el centro de
            control central para el punto de acceso InWall-U168AX. Este modo de
            configuración es ideal para despliegue de gran cantidad de APs para
            grandes empresas donde se deben controlar varios puntos de acceso a
            la vez. Mediante el uso del modo Fit y un controlador de acceso
            WLAN, puede administrar todos los puntos de acceso conectados en una
            sola ubicación; El tiempo de configuración y la complejidad se
            pueden reducir considerablemente.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Funciona como AP independiente para la oficina, hogar, o condominios" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Fat mode:" />
          <p>
            El punto de acceso InWall-U168AX funciona como un
            dispositivo independiente con todas las funciones inalámbricas y de
            cifrado controladas dentro del propio AP. Este modo es más adecuado
            para configuraciones pequeñas como residenciales u oficinas con
            menos puntos de acceso. El modo Fat admite dos modos de operación:
            modo AP y modo AP Gateway. En el modo AP Gateway, el InWall-U168AX
            funciona como una puerta de enlace y utiliza su puerto WAN que se
            conecta a un módem / enrutador a través de PPPoE, DHCP o IP
            estática. En el modo AP, el InWall-U168AX se puede conectar a una
            red existente y trabajar sin las funciones del puerto WAN.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Monitoreo de estado y tráfico en vivo" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-5">
          <p>
            El InWall-U168AX permite moitorear el uso de Internet inalámbrico
            mediante un gráfico de actividad en vivo, puede obtener información
            en tiempo real sobre los parámetros de red para tomar decisiones
            oportunas sobre la administración de sus recursos de Internet.
          </p>
        </Col>
        <Col xs={12} md={6} className="mb-5">
          <img
            className={styles.image}
            src={LiveTrafficStatusMonitoring}
            alt="Live Traffic and Status Monitoring"
          />
        </Col>
      </Row>
    </Container>
  );
}

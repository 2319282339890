import { Col, Container, Row } from "react-bootstrap";
import styles from "./TechnicalspecificationsXGS2404400.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftXGS2404400 } from "./SpecificationsLeftXGS2404400";
import { SpecificationsRightXGS } from "./SpecificationsRightXGS/SpecificationsRightXGS";

export function TechnicalspecificationsXGS2404400() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive L3POE-XGS2404-400 BT" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftXGS2404400 />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightXGS />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

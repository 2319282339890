import { Container, Row, Col } from "react-bootstrap";
import styles from "./SmartHomeSection.module.scss";

export function SmartHomeSection() {
  return (
    <Container>
      <Row className="mb-3">
        <Col xs={12} className="my-3">
          <p className={styles.text}>
            Todas estas soluciones pueden ser provistas bajo esquemas de
            suministro acordados con nuestros clientes, incluyendo el alquiler
            de equipos con o sin servicio incluido, con contratos de servicio a
            convenir entre las partes.
          </p>
        </Col>
        <Col xs={12}>
          <p className={styles.text}>
            Adicionalmente, en conjunto con empresas de nuestro grupo, ofrecemos
            soluciones de desarrollo e implementación de aplicaciones con las
            más avanzadas herramientas y frameworks de desarrollo, incluyendo
            sitios web, landing page, APIs, chatbots, entre otras.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

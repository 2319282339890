import { Container, Row, Col } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import styles from "./RmaSection.module.scss";

export function RmaSection() {
  return (
    <Container>
      <Row className={styles.description}>
        <Col xs={12} className="mb-4">
          <HomeSubtitle title="RMA" />
        </Col>
        <Col xs={12} className="mb-4">
          <p>
            Los contratos firmados entre AirLive Technology y los distribuidores
            autorizados en cada país, establecen los acuerdos respecto a
            garantía y RMA.
          </p>
          &nbsp;
          <p>
            Todo cliente final debe canalizar la solicitud de RMA a través de su
            distribuidor o canal de ventas más cercano, a través del cual
            adquirió el producto, quien realizará las pruebas técnicas
            pertinentes para verificar la operatividad del producto, causas de
            la avería y la aplicabilidad de RMA.
          </p>
          &nbsp;
          <p>
            Toda solicitud de RMA debe ser realizada por el distribuidor o canal
            de ventas, a AirLive vía Correo electrónico dirigida al gerente de
            desarrollo de negocio en la respectiva región, con el formulario de
            solicitud de RMA vigente emitido por AirLive.
          </p>
          &nbsp;
          <p>
            El contrato entre AirLive Technology y su distribuidores en cada
            país puede incluir la opción de devolución de un porcentaje ofrecido
            por AirLive para que el distribuidor local se haga cargo del
            reemplazo del productos sin tener que enviar el producto averiado a
            fábrica y no asuma gastos de envío a fábrica.
          </p>
          &nbsp;
          <p>
            <span className={styles.subTitle}>Contáctenos</span> Si tiene alguna
            pregunta, interés en alguno de nuestros productos AirLive, o desea
            ser parte de nuestra red de distribuidores o canales de ventas en
            Latinoamérica, contáctenos vía correo electrónico:
            AirLiveLat@gmail.com.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import Logo_GlobalAccess from "../../assets/images/Logo-GlobalAccess.svg";
import logo_GlobalAccess_2 from "../../assets/images/Logo-GlobalAccess-2.svg";
// import ic_twitter_x from "../../assets/images/ic_twitter_x.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  // faFacebook,
  // faLinkedin,
  // faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <Container fluid className={styles.footer}>
      <Container>
        <Row>
          <Col xs={12} md className="d-flex justify-content-center">
            <img
              src={Logo_GlobalAccess}
              className={styles.Logo_GlobalAccess}
              alt="Logo_GlobalAccess"
            />
          </Col>
          <Col xs={12} md>
            <h2 className={styles.title}>Global Access map</h2>
            <Navbar>
              <ul className={styles.list_link}>
                <li>
                  <Nav.Link href="/" className={styles.link_custom}>
                    Inicio
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Products/WirelessNetworksAirLiveCloud" className={styles.link_custom}>
                    Productos
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Solutions" className={styles.link_custom}>
                    Soluciones
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/AboutUs" className={styles.link_custom}>
                    Nosotros
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/Drivers" className={styles.link_custom}>
                    Soporte
                  </Nav.Link>
                </li>
              </ul>
            </Navbar>
          </Col>
          <Col xs={12} md>
            <h2 className={styles.title}>Social</h2>
            <div className={styles.social_group}>
              <a
                aria-label="Chat on WhatsApp"
                href="https://wa.me/584143058045"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} className={styles.icon} />
              </a>
              {/* TODO:se agregaran el resto de redes luego de verificar.
              <a
                href="https://www.linkedin.com/in/airlive-technology-corp-89071725a/recent-activity/all/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />
              </a>
              <a
                href="https://www.instagram.com/airlivelat/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className={styles.icon} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100087758243229"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className={styles.icon} />
              </a>

              <a
                href="https://twitter.com/AirtLiveLatam"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ic_twitter_x}
                  className={styles.social_logo}
                  alt="ic_twitter_x"
                />
              </a> */}
            </div>
            <img
              src={logo_GlobalAccess_2}
              className={styles.Logo_GlobalAccess_2}
              alt="logo_GlobalAccess_2"
            />
            <div className={styles.info_contacts}>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
                Ventas@globalaccesswllc.com
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className={styles.copyRight}>
        <Row>
          <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center">
            <span>Copyright © 2024 </span>
            <span>Global Access. </span>
            <span>Todos los derechos reservados.</span>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

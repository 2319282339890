import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../SubTitleProduct";

export function SpecificationsRightLive8GTII() {
  const PowerSupply = [
    {
      title: "Fuente de alimentación:",
      detail: "Fuente de alimentación: DC5V1A (interfaz DC2.1)",
    },
  ];
  const EnvironmentalOperatingConditions = [
    {
      title: "Condiciones ambientales:",
      detail: "Temperatura de funcionamiento: -10 °C ~ + 50 °C",
    },
    {
      detail: "Temperatura de almacenamiento: -20 °C ~ + 70 °C",
    },
    {
      detail: "Humedad de trabajo: 10% ~ 90%, sin condensación",
    },
    {
      detail: "Humedad de almacenamiento: 5% ~ 95%, sin condensación",
    },
  ];
  const StandardSwitchPackaging = [
    {
      title: "Empaque estándar del Switch:",
      detail: "Tamaño del producto: 14.5 x 6.0 x 2.15 cm(L * W * H)",
    },
    {
      detail: "Tamaño del paquete: 14.6 x 8.8 x 5.0 cm(L * W * H)",
    },
    {
      detail: "Peso del producto/peso del paquete: NO: 0,111 kg; G.W: 0.166kg",
    },
    {
      detail: "Contenido del paquete: 1 x switch, 1 x cable de alimentación.",
    },
  ];
  const StandardCarton = [
    {
      title: "Caja de cartón estándar:",
      detail: "Cantidad: 100 piezas / 1 caja cartón",
    },
    {
      detail: "Dimensiones: 53 x 47,5 x 31,5 cm(L*W*H)",
    },
    {
      detail: "Peso de la caja: 17.6Kgs",
    },
  ];
  const OrderingInformation = [
    {
      title: "Información para pedidos:",
      detail: "Modelo: Live-8GT II: 8 conmutadores RJ45 de 10/100/1000 Mbps",
    },
    {
      detail: "Nombre: Switch Gigabit de 8 puertos, Plug and Play",
    },
  ];
  return (
    <Container>
      <ul>
        {PowerSupply.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {EnvironmentalOperatingConditions.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardSwitchPackaging.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StandardCarton.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {OrderingInformation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftIGM642POE125G() {
  const L3ManagementExtension = [
    {
      title: "L3 (conmutación de capa 3) y función de enrutador:",
      detail: "Configuración de la interfaz: Soporte de interfaz VLAN virtual",
    },
    {
      detail:
        "ARP: Comprobación de soporte ARP",
    },
    {
      detail: "Función de enrutador: Router estático",
    },
    {
      detail: "Función extendida",
    },
    {
      detail: "ACL: Basado en el MAC de origen, MAC de destino, tipo de protocolo",
    },
    {
      detail: "IP de origen, IP de destino, Número de puerto L4, soporte de gestión de tiempo de rango de tiempo",
    },
  ];
  const QoS = [
    {
      title: "QoS:",
      detail: "Basado en la clasificación 802.1p (CoS)",
    },
    {
      detail: "Basado en la clasificación DSCP",
    },
    {
      detail:
        "Clasificación basada en IP de origen, IP de destino y, clasificación del número de puerto",
    },
    {
      detail: "Soporte SP, estrategia de programación WRR y DRR",
    },
    {
      detail: "Límite de caudal de soporte CAR",
    },
  ];
  const DiscoveryLLDP = [
    {
      title: "Descubrimiento de LLDP: ",
      detail: "Soporta el protocolo de descubrimiento LLDP",
    },
  ];
  const UserSettings = [
    {
      title: "Configuración del usuario: ",
      detail: "AGREGAR/Eliminar usuarios",
    },
    {
      detail:
        "Acceso de usuario: Inicio de sesión de usuario, operación, estado, eventos",
    },
  ];
  const AntiAttack = [
    {
      title: "Seguridad portuaria:",
      detail: "Morir Gasp, trampa SNMP",
    },
    {
      detail: "Prevenir ataques: Defensa DOS, Compatibilidad con la protección de la CPU, limitada a la velocidad de mensajes de la CPU de envío",
    },
    {
      detail: "Enlace ARP (enlace IP, MAC, PORT)",
    },
  ];
  const SystemAdministration = [
    {
      title: "Administración del sistema:",
      detail:
        "Restablecimiento del dispositivo, guardado/restauración de la configuración, administración de actualizaciones, configuración de fecha y hora, etc.",
    },
    {
      detail: "CLI: Soporte de gestión de línea de comandos de puerto serie",
    },
    {
      detail: "Telnet: Soporte de gestión de línea de comandos en serie",
    },
    {
      detail: "SSH: Soporte de administración remota SSHv1 / 2",
    },
    {
      detail: "SNMP: Soporte V1/V2/V3",
    },
    {
      detail: "WEB: Soporte de configuración de capa 2",
    },
    {
      detail: "PoE: Soporte de alimentación PoE",
    },
  ];
  return (
    <Container>
      <ul>
        {L3ManagementExtension.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {QoS.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DiscoveryLLDP.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {UserSettings.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {AntiAttack.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <SubTitleProduct content="Puerto PoE: 48" />
      <ul>
        {SystemAdministration.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsU618AX.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftU618AX } from "./SpecificationsLeftU618AX";
import { SpecificationsRightU618AX } from "./SpecificationsRightU618AX";

export function SpecificationsU618AX() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="inWall-U618AX AX1800 Access Point" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftU618AX />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightU618AX />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

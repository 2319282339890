import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionISFPLX125G20KM.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import ISFPLX125G20KM from "../../../../../../../../assets/imagesproduct/Accessories/TransceiverISFPLX125G20KM/ISFPLX125G20KM.jpg";

export function TitleSectionTransceiverISFPLX125G20KM() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Transceptor nivel Industrial 1000Base-LX MiniGBIC Hasta 20KM, Conectable en caliente" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="ISFP-LX-1.25G-20KM" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={ISFPLX125G20KM}
            alt="Transceptor nivel Industrial 1000Base-LX MiniGBIC"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El AirLive ISFP-LX-1.25G-20KM es un transceptor de fibra SFP
            industrial monomodo de bajo consumo, alto rendimiento y rentable
            para aplicaciones de comunicación de datos ópticas en serie de hasta
            1,25 Gbps y 20 KM en entornos industriales. El módulo de baja
            fluctuación y alta sensibilidad está diseñado para fibra dual
            monomodo y funciona a una longitud de onda nominal de 1310nm, la
            temperatura de funcionamiento más alta de -40 °C ~ + 85 °C hace que
            el módulo sea ideal para áreas industriales y de mayor temperatura,
            el módulo es conectable en caliente.
          </p>
          &nbsp;
          <p>
            El transceptor incorpora funciones de control TX Disabled, TX-Fault
            y monitor RX_LOS. Es un transceptor de conector LC dúplex diseñado
            para su uso en aplicaciones Gigabit Ethernet y para proporcionar un
            enlace compatible con IEEE802.3z y un enlace compatible con la
            seguridad láser de clase I. Las aplicaciones adecuadas son tales
            como redes de área de almacenamiento de alta velocidad, conexión
            cruzada de clústeres de computadoras, tuberías de datos de alta
            velocidad personalizadas y más.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

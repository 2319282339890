import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SpecificationsPOEGSH1602M200 } from "./SpecificationsPOEGSH1602M200";
import { TechnicalPOEGSH1602M200 } from "./TechnicalPOEGSH1602M200";

export function FeatureProductPOEGSH1602M200() {
  return (
    <Container>
        <Row>
            <Col>
                <Tabs
                defaultActiveKey="Technicalspecifications"
                id="fill-tab-example"
                className="mb-3"
                >
                    <Tab eventKey="Technicalspecifications" title="Especificaciones">
                        <TechnicalPOEGSH1602M200 />
                    </Tab>
                    <Tab eventKey="Specifications" title="Especificaciones">
                        <SpecificationsPOEGSH1602M200 />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    </Container>
  );
}

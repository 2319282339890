import { Container, Row, Col } from "react-bootstrap";
import styles from "./FunctionsPlatformSection.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import CloudMonitor from "../../../../../../../../assets/imagesproduct/AirCloud/CloudMonitor.jpg";

export function FunctionsPlatformSection() {
  const functionsAirCloudPlatform = [
    {
      title: "Free Cloud:",
      detail:
        "Puedes vincular un número indefinido de dispositivos inalámbrico.",
    },
    {
      title: "Gestión centralizada:",
      detail:
        "AirCloud proporciona una plataforma centralizada para gestionar y supervisar múltiples puntos de acceso inalámbrico desde una única ubicación, simplificando la gestión de la red y reduciendo la necesidad de personal de TI especializado.",
    },
    {
      title: "Configuración automatizada:",
      detail:
        "El software ofrece la configuración automatizada de múltiples puntos de acceso inalámbricos, lo que reduce el tiempo y el esfuerzo necesarios para configurar e implementar nuevos dispositivos.",
    },
    {
      title: "Monitoreo de red:",
      detail:
        "AirCloud proporciona monitoreo en tiempo real del rendimiento de la red, incluido el análisis del tráfico y el estado del dispositivo, lo que permite a los administradores identificar y solucionar problemas rápidamente.",
    },
    {
      title: "Acceso de invitados:",
      detail:
        "El software ofrece un portal de acceso de invitados que se puede personalizar con el logotipo y los mensajes de la empresa, proporcionando una experiencia profesional y segura para los huéspedes que acceden a la red.",
    },
    {
      title: "Funciones de seguridad:",
      detail:
        "AirCloud ofrece una amplia gama de funciones de seguridad, incluidos protocolos de cifrado avanzados, protección con cortafuegos y detección de puntos de acceso no autorizados.",
    },
  ];
  const NotableSpecifications = [
    {
      detail: "Reside completamente dentro de la nube",
    },
    {
      detail: "Portal cautivo en la nube",
    },
    {
      detail: "Permite Importar y exportar usuarios de autenticación",
    },
    {
      detail:
        "Creación de plantillas de dispositivo e implementación en la nube",
    },
    {
      detail:
        "Admite una implementación eficiente y reduce el costo general de implementación, operación y mantenimiento",
    },
    {
      detail:
        "Gestión centralizada para un controlador WLAN AirLive ilimitado, puntos de acceso de techo, punto de acceso Inwall y CPE para exteriores, más productos por venir.",
    },
    {
      detail: "Disponibilidad de AirCloud para Android para una gestión cómoda",
    },
    {
      detail:
        "El panel de control fácil de usar facilita ver el estado de su red en tiempo real y verificar el uso de la red y la distribución del tráfico.",
    },
  ];
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Funciones determinantes de la plataforma AirCloud" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Característica resaltantes" />
        </Col>
      </Row>
      <Row>
        <Col  xs={12} md={6} className={styles.LeftList}>
          <ul className={styles.listItem}>
            {functionsAirCloudPlatform.map((item) => (
              <li>
                <SubTitleProduct content={item.title} />
                <p className="mb-3">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
          <ul className={styles.listItem}>
            {NotableSpecifications.map((item) => (
              <li>
                <p className="mb-5">{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Monitor de estado en tiempo real y notificación de alarma mediante un Cloud Dash Board" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-5">
          <p>
            El panel de acceso basado en la nube puede proporcionar monitoreo en
            tiempo real del tráfico de dispositivos, lo que ayuda a los clientes
            finales a solucionar problemas de congestión de la red. La función
            de notificación de alarma puede advertir a los clientes finales
            cuando un dispositivo experimenta una carga pesada de CPU o memoria
            o conflictos DHCP, lo que les permite tomar medidas rápidas y
            minimizar el riesgo de tiempo de inactividad prolongado o pérdida de
            datos. También puede ayudar a los clientes finales a tomar
            decisiones notificadas sobre el rendimiento y la estabilidad de sus
            sistemas de red.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img className={styles.image} src={CloudMonitor} alt="AirCloud Monitor" />
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";

export function CharacteristicsISFPSR10G300M() {
  const Feature = [
    {
      detail: "Transceptor SFP+ de fibra de 10 Gbps Mini GBIC",
    },
    {
      detail: "Fibra SFP+ multimodo",
    },
    {
      detail: "Interfaz de conector LC dúplex",
    },
    {
      detail: "Longitud de onda 850nm VCSEL láser y fotodetector PIN",
    },
    {
      detail: "Alta velocidad de 10 Gbps",
    },
    {
      detail: "Distancia hasta 300M",
    },
    {
      detail: "Bajo consumo de energía < 1.0W",
    },
    {
      detail: "Capacidad conectable en caliente",
    },
    {
      detail: "EMI muy baja",
    },
    {
      detail: "Excelente protección contra ESD",
    },
    {
      detail: "IEEE802.3ae",
    },
    {
      detail: "Temperatura de funcionamiento: -40 °C ~ +85 °C",
    },
  ];
  const NotableSpecifications = [
    {
      detail: "Infraestructura de canal de fibra",
    },
    {
      detail: "Interfaz óptica STM-16",
    },
    {
      detail: "Conmutadores y enrutadores ATM",
    },
    {
      detail: "Infraestructura de conmutadores SDH/SONET",
    },
    {
      detail: "Ejemplos de entorno de aplicación: Bancos, Aeropuertos, Hoteles",
    },
    {
      detail: "Autopista, Industria petroquímica, Metro, Fábrica",
    },
  ];
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Características" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Aplicación" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className="mb-5">
          <ul>
            {Feature.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col md={5} className="mb-5">
          <ul>
            {NotableSpecifications.map((item) => (
              <li>
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

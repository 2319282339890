import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsSNMPGSH802M.module.scss";
import { HomeSubtitle } from "../../../../../../../../HomeSubtitle";
import { SpecificationsLeftSNMPGSH802M } from "./SpecificationsLeftSNMPGSH802M/SpecificationsLeftSNMPGSH802M";
import { SpecificationsRightSNMPGSH802M } from "./SpecificationsRightSNMPGSH802M/SpecificationsRightSNMPGSH802M";

export function SpecificationsSNMPGSH802M() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive SNMP-GSH802M" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftSNMPGSH802M />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightSNMPGSH802M />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

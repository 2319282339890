import { Container, Row, Col } from "react-bootstrap";
import styles from "./TitleSectionL2SNMPGSH802M.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import GSH802M from "../../../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH802M/GSH802M.jpg";
import descriptionSwitchL2SNMPGSH802M from "../../../../../../../../assets/imagesproduct/SwitchL2/SNMPGSH802M/descriptionSwitchL2SNMPGSH802M.jpg";

export function TitleSectionL2SNMPGSH802M() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <HomeSubtitle title="Switch Gigabit L2+ Administrable 10 Puertos Gigabit incluyendo 2 puertos" />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="SNMP-GSH802M" />
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={GSH802M}
            alt="Switch Gigabit L2+ Administrable 10 Puertos"
          />
        </Col>
        <Col xs={12} className="mb-3">
          <HomeSubtitle title="Funcionalidades" />
        </Col>
        <Col xs={12} className="mb-5">
          <SubTitleProduct content="Switch administrable SNMP con política VLAN avanzada para conferencias de video | Vigilancia | Voz | Datos" />
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          <p>
            El AirLive SNMP-GSH802 es un switch Gigabit de 10 puertos gestionado
            L2+ que incluye 8 puertos RJ-45 y 2 puertos SFP Gigabit. Admite una
            función integral de administración SNMP, como IGMP EAPS Ring
            Network, VLAN Division, agregación de puertos, duplicación de
            puertos QoS, control de ancho de banda. Proporcionar la función de
            política de división de VLAN para establecer una mayor prioridad
            para voz o datos que eviten la pérdida o los retrasos del paquete.
            Además, todos los puertos funcionan a alta velocidad Gigabit; la
            función de autoaprendizaje de direcciones MAC garantiza una
            transmisión de datos sin errores; el modo de almacenamiento y
            reenvío evita que los paquetes dañados se inunden en la red; la
            función de control de flujo puede evitar el impacto de una gran
            cantidad de datos instantáneos en la red; el puerto admite la
            función de volteo automático MDI / MDIX, la transmisión de velocidad
            de línea sin bloqueo de todos los puertos.
          </p>
        </Col>
        <Col md={6} className="mb-5">
          <img
            className={styles.image}
            src={descriptionSwitchL2SNMPGSH802M}
            alt="Description Switch"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function SpecificationsLeftPOEGSH1602M200() {
  const L2ManagementExtension = [
    {
      title: "Función de extensión de gestión L2:",
      detail: "ACL (Lista de control de acceso): Basado en MAC de origen",
    },
    {
      detail:
        " MAC de destino, tipo de protocolo, IP de origen, IP de destino, puerto L4",
    },
  ];
  const QoS = [
    {
      title: "QoS:",
      detail: "Basado en la clasificación 802.1p (CoS)",
    },
    {
      detail: "Basado en la clasificación DSCP",
    },
    {
      detail: "Clasificación basada en IP de origen, IP de destino y el número de puerto",
    },
    {
      detail: "Apoyar la estrategia de programación de SP, WRR",
    },
    {
      detail: "Límite de caudal de soporte CAR",
    },
  ];
  const DiscoveryLLDP = [
    {
      title: "Descubrimiento de LLDP: ",
      detail:
        "Admite el protocolo de detección de LLDP, LLDP con extensiones LLDP-MED (IPv4 ACL, IPv6ACL, MAC ACL)",
    },
  ];
  const UserSettings = [
    {
      title: "Configuración del usuario: ",
      detail: "AGREGAR/Eliminar usuarios",
    },
    {
      detail: "Registro: Inicio de sesión de usuario, operación, estado, eventos",
    },
  ];
  const AntiAttack = [
    {
      title: "Antiataque (Seguridad):",
      detail: "Defensa de DOS (basada en puertos) ",
    },
    {
      detail: "Admite la protección de la CPU y limita la velocidad de envío de paquetes de CPU",
    },
    {
      detail: "Enlace ARP (enlace IP, MAC, PORT)",
    },
  ];
  const IPv6 = [
    {
      title: "IPv6:",
      detail: "Soporta IPv6 Ping, IPv6 Traceroute, IPv6 Telnet",
    },
    {
      detail: "Soporta IPv6 SSH",
    },
    {
      detail: "Soporta IPv6 HTTP, IPv6 HTTPS",
    },
  ];
  const CertificationAuthentication = [
    {
      title: "Certificación | Autenticación:",
      detail: "Admite autenticación de puerto 802.1x",
    },
    {
      detail: "Compatibilidad con la autenticación AAA",
    },
    {
      detail: "Soporta autenticación TACACS+",
    },
  ];
  const NetworkDiagnostics = [
    {
      title: "Diagnóstico de red:",
      detail: "Soporte Ping, Telnet, Trace",
    },
  ];
  const SystemManagement = [
    {
      title: "Gestión del sistema:",
      detail: "Restablecimiento de dispositivos, guardado/restauración de la configuración, gestión de actualizaciones, configuración de la hora, etc.",
    },
  ];
  return (
    <Container>
      <ul>
        {L2ManagementExtension.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {QoS.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {DiscoveryLLDP.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {UserSettings.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {AntiAttack.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {IPv6.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {CertificationAuthentication.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {NetworkDiagnostics.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SystemManagement.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./FeatureProductLive16GTMini.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftLive16GTMini } from "./SpecificationsLeftLive16GTMini";
import { SpecificationsRightLive16GTMini } from "./SpecificationsRightLive16GTMini/SpecificationsRightLive16GTMini";

export function FeatureProductLive16GTMini() {
  return (
    <Container>
      <Row>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col md={6} className="mb-3">
          <HomeSubtitle title="AirLive Live-16GT Mini" />
        </Col>
      </Row>
      <Row>
        <Col md={5} className={styles.LeftList}>
          <SpecificationsLeftLive16GTMini />
        </Col>
        <Col md={5} className={styles.RightList}>
          <SpecificationsRightLive16GTMini />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={styles.minTitle}>
          * Estas especificaciones pueden cambiar sin previo aviso
        </Col>
      </Row>
    </Container>
  );
}

import { Col, Container, Row } from "react-bootstrap";
import styles from "./SpecificationsWLAN128GM.module.scss";
import { HomeSubtitle } from "../../../../../../../HomeSubtitle";
import { SpecificationsLeftWLAN128GM } from "./SpecificationsLeftWLAN128GM";
import { SpecificationsRightWLAN128GM } from "./SpecificationsRightWLAN128GM";

export function SpecificationsWLAN128GM() {
  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="Modelo" />
        </Col>
        <Col xs={12} md={6} className="mb-3">
          <HomeSubtitle title="WLAN-128GM  Enterprise Gateway & Wireless Controller" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className={styles.LeftList}>
            <SpecificationsLeftWLAN128GM />
        </Col>
        <Col xs={12} md={5} className={styles.RightList}>
            <SpecificationsRightWLAN128GM />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.minTitle}>
            * Estas especificaciones pueden cambiar sin previo aviso
          </div>
        </Col>
      </Row>
    </Container>
  );
}

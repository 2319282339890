import { Container, Row, Col } from "react-bootstrap";
import styles from "./AppFTTXGPONOLT121.module.scss";
import { SubTitleProduct } from "../../../../SubTitleProduct";
import OLT121AppFTTX from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT121/OLT121AppFTTX.jpg";
import OLT121TriplayServices from "../../../../../../../../assets/imagesproduct/AirLiveGPON/GPONOLT121/OLT121TriplayServices.jpg";

export function AppFTTXGPONOLT121() {
  return (
    <Container>
      <Row>
        <Col className="mb-3">
          <SubTitleProduct content="Aplicaciones FTTX" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El equipo GPON OLT-121 posibilita la implementación de la
            infraestructura de red de extremo a extremo para redes FTTX, desde
            el lado central CO hasta las instalaciones de los clientes finales;
            representa una excelente solución para operadores regionales,
            operadores independientes, municipios, operadores de sistemas
            múltiples (MSO), prestadores de servicios públicos y
            desarrolladores, en la provisión de servicios vía fibra óptica FTTX.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={OLT121AppFTTX}
            alt="Aplicaciones FTTX"
          />
        </Col>
        <Col className="mb-3">
          <SubTitleProduct content="Cobertura de alta velocidad y larga distancia en servicios Triple Play" />
        </Col>
        <Col xs={12} className="mb-5">
          <p>
            El GPON OLT-121 es una solución robusta con cobertura de alta
            velocidad y larga distancia en aplicaciones de servicio “Triple
            Play” como HDTV, IPTV, voz sobre IP (VoIP) y multimedia. Admite
            anchos de banda descendentes de hasta 2,5 Gbps y anchos de banda
            ascendentes de 1,25 Gbps, lo que permite una transmisión de datos
            rápida y fiable. Con un rango de cobertura de hasta 20 km entre los
            nodos de los equipos, el OLT-121 es una tecnología de acceso
            rentable que ofrece flexibilidad para el despliegue de la red.
            Además de confiable , es escalable, garantizando la migración a
            otras OLT de mayor capacidad en forma rápida y eficiente para
            administradores de red y usuarios finales.
          </p>
        </Col>
        <Col xs={12} className="mb-5">
          <img
            className={styles.image}
            src={OLT121TriplayServices}
            alt="long distance coverage in Triple Play services"
          />
        </Col>
      </Row>
    </Container>
  );
}

import { Container } from "react-bootstrap";
import { SubTitleProduct } from "../../../../../../SubTitleProduct";

export function TechnicalRightSNMPGSH2404M() {
  const L2Management = [
    {
      title: "Función de Gestión L2",
      detail: "Gestión de Puertos: Habilitar / Deshabilitar puerto, Ajuste de velocidad, dúplex",
    },
    {
      detail: "MTU, Control de flujo, Comprobación de información portuaria",
    },
  ];
  const PortMirroring = [
    {
      title: "Duplicación de puertos: ",
      detail:
        "Admite la duplicación de puertos laterales",
    },
    {
      detail:
        "Límite de velocidad del puerto: Administración del ancho de banda de entrada/salida, basada en puertos",
    },
  ];
  const PortIsolation = [
    {
      title: "Aislamiento de puertos: ",
      detail:
        "Admite el aislamiento del puerto de enlace descendente ",
    },
    {
      detail:
        "puede comunicarse con el puerto de enlace ascendente",
    },
  ];
  const StormSuppression = [
    {
      title: "Supresión de tormentas:",
      detail: "Soporta unidifusión desconocida, multidifusión, multidifusión desconocida",
    },
    {
      detail: "Supresión de tormentas de tipo broadcast",
    },
    {
      detail: "Supresión de tormentas basada en la regulación del ancho de banda y el filtrado de tormentas",
    },
  ];
  const LinkAggregation = [
    {
      title: "Agregación de enlaces:",
      detail:
        "Admite agregación manual estática",
    },
    {
      detail: "Soporte de agregación dinámica LACP",
    },
  ];
  const VLAN = [
    {
      title: "VLAN:",
      detail: "Acceso, Troncal, Híbrido",
    },
    {
      detail: "Admite la división VLAN basada en el puerto, el protocolo y la dirección MAC, el túnel VLAN.",
    },
    {
      detail: "Soporte de registro de VLAN dinámica GVRP genérica",
    },
    {
      detail: "Soporte de voz VLAN",
    },
  ];
  const MacAddress = [
    {
      title: "Dirección MAC:",
      detail:
        "Soporte de adición estática, eliminación",
    },
    {
      detail: "Límite de aprendizaje de direcciones MAC",
    },
    {
      detail: "Ajuste dinámico del tiempo de envejecimiento",
    },
  ];
  const SpanningTree = [
    {
      title: "Árbol de expansión:",
      detail: "Compatibilidad con el protocolo STP 802.1d Spanning Tree",
    },
    {
      detail: "Soporta el protocolo RSTP 802.1w Spanning Tree",
    },
    {
      detail: "Soporta el protocolo MSTP 802.1w Spanning Tree",
    },
  ];
  const Multicast = [
    {
      title: "Multidifusión:",
      detail: "Soporte de adición estática, eliminación",
    },
    {
      detail: "Soporte IGMP-Snooping",
    },
    {
      detail: "Soporte MLD-Snooping",
    },
    {
      detail: "Soporte v1/2/3 monitor multicast dinámico, DDM: Soporte SFP / SFP + DDM",
    },
  ];
  return (
    <Container>
      <ul>
        {L2Management.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortMirroring.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {PortIsolation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {StormSuppression.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {LinkAggregation.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {VLAN.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {MacAddress.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {SpanningTree.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
      <ul>
        {Multicast.map((item) => (
          <>
            <SubTitleProduct content={item.title} />
            <li>
              <p>{item.detail}</p>
            </li>
          </>
        ))}
      </ul>
    </Container>
  );
}

import { useContext, useState } from "react";
import { ButtonGroup, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import LocaleContext from "../../LocaleContext";
import styles from "./Navigation.module.scss";

export function Navigation() {
  const [show, setShow] = useState(false);
  const showDropdown = (e: any) => {
    setShow(!show);
  };
  const hideDropdown = (e: any) => {
    setShow(false);
  };
  const { t } = useTranslation();
  const { locale } = useContext(LocaleContext);

  function changeLocale(lang: string) {
    if (locale !== lang) {
      i18n.changeLanguage(lang);
    }
  }

  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="/logo-GlobalAccess.png"
            width="auto"
            className={styles.img}
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="http://aircloud.airlive.com/cloudnetlot/frontend/home/index.html#/login?redirect=%24%7Bto.path%7D" className={styles.NavLinkCustom}>AirCloud</Nav.Link>
            <Nav.Link href="/" className={styles.NavLinkCustom}>Inicio</Nav.Link>
            <NavDropdown
              className={styles.NavLinkCustom}
              title="Productos"
              id="collasible-nav-dropdown"
              as={ButtonGroup}
            >
              <NavDropdown.Item
                href="/Products/WirelessNetworksAirLiveCloud"
                className={styles.dropdownitem}
              >
                Redes Inalámbricas- AirLive Cloud
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/Products/Layer3ManagedSwitches"
                className={styles.dropdownitem}
              >
                Switches Administrables capa 3
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/Products/Layer2ManagedSwitches"
                className={styles.dropdownitem}
              >
                Switches Multigibabit Administrables Capa 2
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/Products/IndustrialSwitches"
                className={styles.dropdownitem}
              >
                Switches Industriales
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/Products/TelecomXGPONCloud"
                className={styles.dropdownitem}
              >
                Telecom XGPON Cloud
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/Products/FiberAdapters"
                className={styles.dropdownitem}
              >
                Adaptadores y Accesorios
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/Solutions" className={styles.NavLinkCustom}>Soluciones</Nav.Link>
            <Nav.Link href="/Drivers" className={styles.NavLinkCustom}>Soporte</Nav.Link>
            <Nav.Link href="/AboutUs" className={styles.NavLinkCustom}>Nosotros</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

